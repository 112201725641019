export const planFeatures = {
  // 'plan_features.lite.monthly.title': 'AI Kredileri 150 / ay',
  // 'plan_features.lite.monthly.1': 'PDF / PPT Dışa Aktarma',
  // 'plan_features.lite.monthly.2': 'Paylaşım Bağlantısı',
  // 'plan_features.lite.monthly.3': 'Slayt & Konuşmacı Notları',
  // 'plan_features.lite.monthly.4': 'İpuçları & QA Oluşturma',
  // 'plan_features.lite.annual.title': 'AI Kredileri 150 / ay',
  // 'plan_features.lite.annual.1': 'PDF / PPT Dışa Aktarma',
  // 'plan_features.lite.annual.2': 'Paylaşım Bağlantısı',
  // 'plan_features.lite.annual.3': 'Slayt & Konuşmacı Notları',
  // 'plan_features.lite.annual.4': 'İpuçları & QA Oluşturma',
  'plan_features.pro.monthly.title': 'AI Kredileri 750 / ay',
  // 'plan_features.pro.monthly.1': 'Tüm LITE AI Özellikleri +',
  'plan_features.pro.monthly.1': 'PDF / PPT Dışa Aktarma',
  'plan_features.pro.monthly.2': 'Paylaşım Bağlantısı',
  'plan_features.pro.monthly.3': 'Slayt & Konuşmacı Notları',
  'plan_features.pro.monthly.4': 'İpuçları & QA Oluşturma',
  'plan_features.pro.monthly.5': 'Sunum Analitikleri',
  'plan_features.pro.monthly.6': 'Form Yanıtlarının E-posta Bildirimleri',
  'plan_features.pro.annual.title': 'AI Kredileri 750 / ay',
  // 'plan_features.pro.annual.1': 'Tüm LITE AI Özellikleri +',
  'plan_features.pro.annual.1': 'PDF / PPT Dışa Aktarma',
  'plan_features.pro.annual.2': 'Paylaşım Bağlantısı',
  'plan_features.pro.annual.3': 'Slayt & Konuşmacı Notları',
  'plan_features.pro.annual.4': 'İpuçları & QA Oluşturma',
  'plan_features.pro.annual.5': 'Sunum Analitikleri',
  'plan_features.pro.annual.6': 'Form Yanıtlarının E-posta Bildirimleri',
  'plan_features.business.monthly.title': 'AI Kredileri 1000 / ay',
  'plan_features.business.monthly.1': 'Tüm PRO AI Özellikleri +',
  'plan_features.business.monthly.2': 'Marka Özellikleri',
  'plan_features.business.monthly.3': 'Gelişmiş Form Yanıtı',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Özel Alan Adı Bağlantısı (CNAME)',
  'plan_features.business.monthly.6': 'Ekip ve Organizasyon',
  'plan_features.business.annual.title': 'AI Kredileri 1000 / ay',
  'plan_features.business.annual.1': 'Tüm PRO AI Özellikler +',
  'plan_features.business.annual.2': 'Marka Özellikleri',
  'plan_features.business.annual.3': 'Gelişmiş Form Yanıtı',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Özel Alan Adı Bağlantısı (CNAME)',
  'plan_features.business.annual.6': 'Ekipler ve Organizasyon',
}
