export const planFeatures = {
  // 'plan_features.lite.monthly.title': 'AI Credits 150 / Monat',
  // 'plan_features.lite.monthly.1': 'PDF / PPT Export',
  // 'plan_features.lite.monthly.2': 'Link teilen',
  // 'plan_features.lite.monthly.3': 'Folien & Sprechernotizen',
  // 'plan_features.lite.monthly.4': 'Tipps & QA-Generierung',
  // 'plan_features.lite.annual.title': 'AI Credits 150 / Monat',
  // 'plan_features.lite.annual.1': 'PDF / PPT Export',
  // 'plan_features.lite.annual.2': 'Link teilen',
  // 'plan_features.lite.annual.3': 'Folien & Sprechernotizen',
  // 'plan_features.lite.annual.4': 'Tipps & QA-Generierung',
  'plan_features.pro.monthly.title': 'AI-Guthaben 750 / Monat',
  // 'plan_features.pro.monthly.1': 'Alle LITE AI-Funktionen +',
  'plan_features.pro.monthly.1': 'PDF / PPT Export',
  'plan_features.pro.monthly.2': 'Link teilen',
  'plan_features.pro.monthly.3': 'Folien & Sprechernotizen',
  'plan_features.pro.monthly.4': 'Tipps & QA-Generierung',
  'plan_features.pro.monthly.5': 'Präsentationsanalyse',
  'plan_features.pro.monthly.6':
    'E-Mail-Benachrichtigungen zu Formularantworten',
  'plan_features.pro.annual.title': 'AI-Guthaben 750 / Monat',
  // 'plan_features.pro.annual.1': 'Alle LITE AI-Funktionen +',
  'plan_features.pro.annual.1': 'PDF / PPT Export',
  'plan_features.pro.annual.2': 'Link teilen',
  'plan_features.pro.annual.3': 'Folien & Sprechernotizen',
  'plan_features.pro.annual.4': 'Tipps & QA-Generierung',
  'plan_features.pro.annual.5': 'Präsentationsanalyse',
  'plan_features.pro.annual.6':
    'E-Mail-Benachrichtigungen zu Formularantworten',
  'plan_features.business.monthly.title': 'AI-Guthaben 1000 / Monat',
  'plan_features.business.monthly.1': 'Alle PRO AI-Funktionen +',
  'plan_features.business.monthly.2': 'Markenfunktionen',
  'plan_features.business.monthly.3': 'Erweiterte Formularantwort',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Benutzerdefinierte Domänenverbindung',
  'plan_features.business.monthly.6': 'Team & Organisation',
  'plan_features.business.annual.title': 'AI-Credits 1000 / Monat',
  'plan_features.business.annual.1': 'Alle Pro AI-Funktionen +',
  'plan_features.business.annual.2': 'Markenfunktionen',
  'plan_features.business.annual.3': 'Erweiterte Formularantwort',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Benutzerdefinierte Domänenverbindung',
  'plan_features.business.annual.6': 'Teams & Organisation',
}
