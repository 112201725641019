import { COMPONENT_DIST } from '../types'
import { ComponentSchema } from '@/types/api/responseObjects'

export const distributeComponents = (
  components: {
    index: number
    component: ComponentSchema
  }[],
  distribute?: COMPONENT_DIST,
) => {
  if (!distribute || components.length <= 2) {
    return components
  }

  const sortedComponents = [...components].sort((a, b) => {
    if (distribute === COMPONENT_DIST.HORIZONTAL) {
      return (a.component.positions.x || 0) - (b.component.positions.x || 0)
    } else {
      return (a.component.positions.y || 0) - (b.component.positions.y || 0)
    }
  })

  const firstComponent = sortedComponents[0]
  const lastComponent = sortedComponents[sortedComponents.length - 1]

  const totalWidth =
    distribute === COMPONENT_DIST.HORIZONTAL
      ? (lastComponent.component.positions.x || 0) +
        (lastComponent.component.positions.width || 0) -
        (firstComponent.component.positions.x || 0)
      : (lastComponent.component.positions.y || 0) +
        (lastComponent.component.positions.height || 0) -
        (firstComponent.component.positions.y || 0)

  const totalSpacing =
    totalWidth -
    sortedComponents.reduce((acc, item) => {
      return (
        acc +
        (distribute === COMPONENT_DIST.HORIZONTAL
          ? item.component.positions.width || 0
          : item.component.positions.height || 0)
      )
    }, 0)

  const numGaps = sortedComponents.length - 1
  const spacing = numGaps > 0 ? totalSpacing / numGaps : 0

  let offset = 0
  const distributedComponents = sortedComponents.map((item) => {
    const newPosition =
      distribute === COMPONENT_DIST.HORIZONTAL
        ? (firstComponent.component.positions.x || 0) + offset
        : (firstComponent.component.positions.y || 0) + offset

    offset +=
      (distribute === COMPONENT_DIST.HORIZONTAL
        ? item.component.positions.width || 0
        : item.component.positions.height || 0) + spacing

    return {
      index: item.index,
      component: {
        ...item.component,
        positions: {
          ...item.component.positions,
          [distribute === COMPONENT_DIST.HORIZONTAL ? 'x' : 'y']: newPosition,
        },
      },
    }
  })

  return distributedComponents
}
