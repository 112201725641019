import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMediaItem } from '@/pages/deck-page-v3/components/image-grid-loader/types'
import {
  getDeckV3StyleResponse,
  GetDeckV3ThemeDetails,
} from '@/types/api/responseObjects'

export type MediaItemTypes = 'icons' | 'images' | 'videos' | 'gifs'
export type ContentBlockTypes =
  | 'timelines'
  | 'diagrams'
  | 'image_blocks'
  | 'lists'
  | 'icon_blocks'
  | 'galleries'
type ElementsType = Record<ContentBlockTypes, GetDeckV3ThemeDetails | undefined>

export type GeneralV3SliceType = {
  selectedMediaTab?: MediaItemTypes
  selectedContentBlockTab?: ContentBlockTypes
  medias: {
    [key in MediaItemTypes]: IMediaItem[]
  }
  showAddSlide?: 'index' | 'end' | 'none'
  selectedStyle?: getDeckV3StyleResponse['data']
  elements: ElementsType
}

const initialState: GeneralV3SliceType = {
  selectedMediaTab: undefined,
  selectedContentBlockTab: undefined,
  medias: {
    icons: [],
    images: [],
    videos: [],
    gifs: [],
  },
  showAddSlide: undefined,
  elements: {
    diagrams: undefined,
    galleries: undefined,
    icon_blocks: undefined,
    image_blocks: undefined,
    lists: undefined,
    timelines: undefined,
  },
}

export const generalV3Slice = createSlice({
  name: 'generalV3',
  initialState,
  reducers: {
    setSelectedMediaTab: (
      state,
      action: PayloadAction<GeneralV3SliceType['selectedMediaTab']>,
    ) => {
      state.selectedMediaTab = action.payload
    },
    setSelectedContentBlockTab: (
      state,
      action: PayloadAction<GeneralV3SliceType['selectedContentBlockTab']>,
    ) => {
      state.selectedContentBlockTab = action.payload
    },
    setMedia: (
      state,
      action: PayloadAction<{ type: MediaItemTypes; data: IMediaItem[] }>,
    ) => {
      state.medias[action.payload.type] = action.payload.data
    },
    showAddSlideDrawer: (
      state,
      action: PayloadAction<GeneralV3SliceType['showAddSlide']>,
    ) => {
      state.showAddSlide = action.payload
    },
    setSelectedStyle: (
      state,
      action: PayloadAction<GeneralV3SliceType['selectedStyle'] | undefined>,
    ) => {
      state.selectedStyle = action.payload
    },
    setElements: (state, action: PayloadAction<ElementsType>) => {
      state.elements = action.payload
    },
  },
})

export const {
  setSelectedMediaTab,
  setSelectedContentBlockTab,
  setMedia,
  showAddSlideDrawer,
  setSelectedStyle,
  setElements,
} = generalV3Slice.actions

export default generalV3Slice.reducer
