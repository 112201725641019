import { DateTimeServices, RequestServices } from '@/services'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { childrenType } from '@/types'

interface IAuthContext {
  token: string
  mfaToken: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  setMFAToken: React.Dispatch<React.SetStateAction<string>>
  clearToken: (runFinalCheck?: boolean) => void
  clearMFAToken: () => void
}

const defaultContext: IAuthContext = {
  token: '',
  mfaToken: '',
  setToken: () => {},
  setMFAToken: () => {},
  clearToken: () => {},
  clearMFAToken: () => {},
}

const AuthContext = createContext<IAuthContext>(defaultContext)

const AuthProvider: React.FC<{ children: childrenType }> = React.memo(
  ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || '')
    const [mfaToken, setMFAToken] = useState('')

    const updateToken = useCallback(
      (token: string) => {
        localStorage.setItem('token', token)
        if (!localStorage.getItem('tokenCreatedAt')) {
          localStorage.setItem('tokenCreatedAt', DateTimeServices.now())
        }
        RequestServices.setAuthHeader(token)
      },
      [token],
    )

    const clearToken = (runFinalCheck = false) => {
      if (runFinalCheck) {
        if (token) {
          return
        }
      }

      localStorage.removeItem('token')
      localStorage.removeItem('tokenCreatedAt')
      localStorage.removeItem('adminToken')
      localStorage.removeItem('persist:workspace')
      setToken('')
      setMFAToken('')
      RequestServices.deleteAuthHeader()
    }

    const clearMFAToken = () => {
      setMFAToken('')
    }

    useEffect(() => {
      token && updateToken(token)
    }, [token])

    const contextValue = useMemo(
      () => ({
        token,
        mfaToken,
        setToken,
        setMFAToken,
        clearToken,
        clearMFAToken,
      }),
      [token, mfaToken],
    )

    return (
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
    )
  },
)

export const useAuthProvider = () => {
  return useContext(AuthContext)
}

export default AuthProvider
