import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactElement,
  ReactNode,
} from 'react'
import { compare } from 'compare-versions'

interface CacheStatusState {
  loading: boolean
  isLatestVersion: boolean
}

interface CacheBusterContextType {
  checkCacheStatus: () => Promise<void>
}

interface CacheBusterProps {
  children: ReactNode
  currentVersion: string
  isEnabled: boolean
  isVerboseMode?: boolean
  loadingComponent?: ReactElement | null
  metaFileDirectory?: string | null
  reloadOnDowngrade?: boolean
  onCacheClear?: (callback: () => Promise<void>) => void
}

const CacheBusterContext = createContext<CacheBusterContextType>({
  checkCacheStatus: async () => {},
})

function CacheBuster({
  children = null,
  currentVersion,
  isEnabled = false,
  isVerboseMode = false,
  loadingComponent = null,
  metaFileDirectory = null,
  reloadOnDowngrade = false,
  onCacheClear,
}: CacheBusterProps): ReactElement | null | ReactNode {
  const [cacheStatus, setCacheStatus] = useState<CacheStatusState>({
    loading: true,
    isLatestVersion: false,
  })

  const log = useCallback(
    (message: string | Error, isError?: boolean): void => {
      isVerboseMode && (isError ? console.error(message) : console.log(message))
    },
    [isVerboseMode],
  )

  useEffect(() => {
    isEnabled ? checkCacheStatus() : log('React Cache Buster is disabled.')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, log])

  const getMetaFileDirectory = useCallback((): string => {
    return !metaFileDirectory || metaFileDirectory === '.'
      ? ''
      : metaFileDirectory
  }, [metaFileDirectory])

  const isThereNewVersion = useCallback(
    (metaVersion: string, currentVersion: string): boolean => {
      if (reloadOnDowngrade) {
        return !compare(metaVersion, currentVersion, '=')
      }
      return compare(metaVersion, currentVersion, '>')
    },
    [reloadOnDowngrade],
  )

  const checkCacheStatus = useCallback(async (): Promise<void> => {
    try {
      const res = await fetch(
        `${getMetaFileDirectory()}/meta.json?nonce=${Date.now()}`,
      )
      const { version: metaVersion } = await res.json()

      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion)
      if (shouldForceRefresh) {
        log(`There is a new version (v${metaVersion}). Should force refresh.`)
        setCacheStatus({
          loading: false,
          isLatestVersion: false,
        })
      } else {
        log('There is no new version. No cache refresh needed.')
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        })
      }
    } catch (error) {
      log('An error occurred while checking cache status.', true)
      log(error as Error, true)

      //Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
      !isVerboseMode &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        })
    }
  }, [
    currentVersion,
    getMetaFileDirectory,
    isThereNewVersion,
    isVerboseMode,
    log,
  ])

  const refreshCacheAndReload = async (): Promise<void> => {
    try {
      if (window?.caches) {
        const { caches } = window
        const cacheNames = await caches.keys()
        const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n))

        await Promise.all(cacheDeletionPromises)

        log('The cache has been deleted.')
        // Note: The forceReload parameter is deprecated but kept for Firefox compatibility
        window.location.reload()
      }
    } catch (error) {
      log('An error occurred while deleting the cache.', true)
      log(error as Error, true)
    }
  }

  if (!isEnabled) {
    return children as ReactElement
  } else {
    if (cacheStatus.loading) {
      return loadingComponent
    }

    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      if (onCacheClear) {
        onCacheClear(refreshCacheAndReload)
      } else {
        refreshCacheAndReload()
      }
      return null
    }

    return React.createElement(
      CacheBusterContext.Provider,
      {
        value: { checkCacheStatus },
      },
      children,
    )
  }
}

const useCacheBuster = (): CacheBusterContextType => {
  const context = useContext(CacheBusterContext)
  if (context === undefined || context === null) {
    throw new Error(
      'useCacheBuster must be used within a CacheBuster component.',
    )
  }
  return context
}

export { CacheBuster, useCacheBuster }
