import { IApiEnums } from '@/i18n/types'
import {
  AICreditSpendReasonNames,
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
  UserLanguages,
} from '@/types/api/enums'

export const apiEnums: IApiEnums = {
  [`enum.planNames.${PlanNames.LITE}`]: 'lite',
  [`enum.planNames.${PlanNames.PRO}`]: 'pro',
  [`enum.planNames.${PlanNames.BUSINESS}`]: 'business',
  [`enum.planNames.${PlanNames.ENTERPRISE}`]: 'enterprise',
  [`enum.planPeriods.${PlanPeriods.MONTHLY}`]: 'mensual',
  [`enum.planPeriods.${PlanPeriods.ANNUAL}`]: 'anual',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_DECK}`]:
    'Crear presentación IA',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_PDF_DECK}`]:
    'Crear presentación PDF',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_ICON}`]:
    'Sugerencia de icono',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_IMAGE}`]:
    'Sugerencia de imagen',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_LIST_ITEM}`]:
    'Sugerencia de elemento de lista',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_TIPS}`]:
    'Crear consejos',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_QUESTIONS_ANSWERS}`]:
    'Generar preguntas y respuestas',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SINGLE_SLIDE_NOTES}`]:
    'Crear notas de diapositiva individual',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_MULTIPLE_SLIDE_NOTES}`]:
    'Crear notas de múltiples diapositivas',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.AI_TEXT_EDIT}`]:
    'Edición de texto IA',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.TRANSLATE_DECK}`]:
    'Traducir Deck',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SLIDE}`]:
    'Generar diapositiva',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED}`]:
    'Acción de pago requerida',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_PENDING}`]:
    'Pago pendiente',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_FAILED}`]:
    'Cancelado',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_SUCCESS}`]:
    'Activo',
  [`enum.language.${UserLanguages.EN_US}`]: 'Inglés',
  [`enum.language.${UserLanguages.TR_TR}`]: 'Turco',
}
