import { PlanNames } from '@/types/api/enums'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IWorkspace {
  id?: number
  filtering?: 'personal' | 'organization'
  paymentFailed: boolean
  isOwner: boolean
  isWorkspaceReady: boolean
  workspacePlan?: PlanNames
}

const initialState: IWorkspace = {
  id: undefined,
  filtering: 'personal',
  paymentFailed: false,
  isOwner: false,
  isWorkspaceReady: false,
  workspacePlan: undefined,
}

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaceId: (state, action: PayloadAction<IWorkspace['id']>) => {
      state.id = action.payload
    },
    setFiltering: (state, action: PayloadAction<IWorkspace['filtering']>) => {
      state.filtering = action.payload
    },
    setPaymentFailed: (
      state,
      action: PayloadAction<IWorkspace['paymentFailed']>,
    ) => {
      state.paymentFailed = action.payload
    },
    setIsOwner: (state, action: PayloadAction<IWorkspace['isOwner']>) => {
      state.isOwner = action.payload
    },
    setIsWorkspaceReady: (
      state,
      action: PayloadAction<IWorkspace['isWorkspaceReady']>,
    ) => {
      state.isWorkspaceReady = action.payload
    },
    setWorkspaceClearState: (state) => {
      state.id = initialState.id
      state.filtering = initialState.filtering
      state.paymentFailed = initialState.paymentFailed
      state.isOwner = initialState.isOwner
      state.isWorkspaceReady = initialState.isWorkspaceReady
    },
    setWorkspacePlan: (
      state,
      action: PayloadAction<IWorkspace['workspacePlan']>,
    ) => {
      state.workspacePlan = action.payload
    },
  },
})

export const {
  setWorkspaceId,
  setFiltering,
  setPaymentFailed,
  setIsOwner,
  setIsWorkspaceReady,
  setWorkspaceClearState,
  setWorkspacePlan,
} = workspaceSlice.actions

export default workspaceSlice.reducer
