import { ErrorDefinitions } from '@/types/api/ErrorDefinitions'

type IErrorDefinitions = {
  [key in ErrorDefinitions]?: string
}

export const errors: IErrorDefinitions = {
  [ErrorDefinitions.VALIDATION_EMAIL_MALFORMED]: 'Geçersiz email adresi',
  [ErrorDefinitions.VALIDATION_PASSWORD_LENGTH]:
    'Şifre en az 10 karakter olmalıdır.',
  [ErrorDefinitions.AICREDITREASONDEFAULTVALUE_NOT_FOUND]:
    'AI Kredi değeri bulunamadı',
  [ErrorDefinitions.API_ERROR]: 'API Hatası',
  [ErrorDefinitions.DATE_ERROR]: 'Geçersiz tarih formatı',
  [ErrorDefinitions.INCORRECT_PROVIDER]:
    'Lütfen bu hesap için google girişini kullanın',
  [ErrorDefinitions.KEY_NOT_FOUND]: 'Anahtar bulunamadı',
  [ErrorDefinitions.NO_ORG_ACCESS]: 'Organizasyon erişimi yok',
  [ErrorDefinitions.NO_TEAM_ACCESS]: 'Takım erişimi yok',
  [ErrorDefinitions.PLAN_NOT_FOUND]: 'Plan bulunamadı',
  [ErrorDefinitions.ACTIVE_PAYMENT_IN_PROGRESS]: 'Aktif Ödeme İşlemde',
  [ErrorDefinitions.PLAN_IS_NOT_AVAILABLE]: 'Seçilen plan mevcut değil',
  [ErrorDefinitions.PLAN_PRODUCT_NOT_FOUND]: 'Plan ürünü bulunamadı',
  [ErrorDefinitions.PRICE_NOT_FOUND]: 'Fiyat bulunamadı',
  [ErrorDefinitions.USER_NOT_FOUND]: 'Kullanıcı bulunamadı',
  [ErrorDefinitions.USER_PLAN_NOT_FOUND]: 'Kullanıcı planı bulunamadı',
  [ErrorDefinitions.USER_EXIST]: 'E-posta adresi zaten mevcut',
  [ErrorDefinitions.WRONG_PASSWORD]: 'Hatalı şifre',
  [ErrorDefinitions.USER_NOT_VERIFIED]: 'Kullanıcı doğrulanmadı',
  [ErrorDefinitions.REFERRED_USER_EXIST]:
    'Yönlendirilen kullanıcı zaten mevcut',
  [ErrorDefinitions.CAN_NOT_PURCHASE]: 'Satın alma işlemi yapılamıyor',
  [ErrorDefinitions.CAN_NOT_UPGRADE]: 'Yükseltme yapılamıyor',
  [ErrorDefinitions.SUBSCRIPTION_NOT_FOUND]: 'Abonelik bulunamadı',
  [ErrorDefinitions.SUBSCRIPTION_ITEM_NOT_FOUND]: 'Abonelik öğesi bulunamadı',
  [ErrorDefinitions.SUBSCRIPTION_CHANGE_NOT_FOUND]:
    'Abonelik değişikliği bulunamadı',
  [ErrorDefinitions.SUBSCRIPTION_IS_NOT_ACTIVE]: 'Abonelik etkin değil',
  [ErrorDefinitions.UNKNOWN_EVENT_TYPE]: 'Bilinmeyen etkinlik türü',
  [ErrorDefinitions.TEAM_NOT_FOUND]: 'Takım bulunamadı',
  [ErrorDefinitions.ORG_NOT_FOUND]: 'Organizasyon bulunamadı',
  [ErrorDefinitions.ACCESS_NOT_FOUND]: 'Erişim bulunamadı',
  [ErrorDefinitions.USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION]:
    'Kullanıcı zaten bu organizasyonda mevcut',
  [ErrorDefinitions.USERS_ALREADY_EXISTS_IN_THIS_TEAM]:
    'Kullanıcılar zaten bu takımda mevcut',
  [ErrorDefinitions.INVITATION_ALREADY_EXISTS]: 'Davet zaten mevcut',
  [ErrorDefinitions.INVITATION_NOT_FOUND]: 'Davet bulunamadı',
  [ErrorDefinitions.ORGANIZATION_NOT_FOUND]: 'Organizasyon bulunamadı',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_DOWNGRADE]:
    'Organizasyon koltuk boyutu düşürme hatası',
  [ErrorDefinitions.AI_CREDIT_REASON_NOT_DEFINED]:
    'AI Kredi Sebep Tanımlı Değil',
  [ErrorDefinitions.INSUFFICIENT_AI_CREDIT]: 'Yetersiz AI kredi',
  [ErrorDefinitions.WEBHOOK_EXISTS]: 'Web kancası zaten mevcut',
  [ErrorDefinitions.WEBHOOK_NOT_EXISTS]: 'Web kancası mevcut değil',
  [ErrorDefinitions.WEBHOOK_NO_CHANGE]: 'Web kancası değişikliği yok',
  [ErrorDefinitions.PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND]:
    'Şifre sıfırlama isteği süresi doldu veya bulunamadı',
  [ErrorDefinitions.TEAM_IS_NOT_IN_THIS_ORGANIZATION]:
    'Takım bu organizasyonda değil',
  [ErrorDefinitions.USERS_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Bu organizasyonda kullanıcı bulunamadı',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_TEAM]:
    'Bu takımda kullanıcı bulunamadı',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Bu organizasyonda kullanıcı bulunamadı',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_USER]:
    'Kendi kullanıcınızı güncelleyemezsiniz',
  [ErrorDefinitions.MISSING_QUESTION_TYPES]: 'Eksik soru tipleri',
  [ErrorDefinitions.USER_ALREADY_CLASSIFIED]:
    'Kullanıcı zaten sınıflandırılmış',
  [ErrorDefinitions.WRONG_VERIFICATION_CODE]:
    'Girilen kod yanlış. Lütfen e-postanızı kontrol edin.',
  [ErrorDefinitions.USER_ALREADY_VERIFIED]: 'Kullanıcı zaten doğrulanmış',
  [ErrorDefinitions.TARGET_USER_IS_OWNER]: 'Hedef kullanıcı sahibi',
  [ErrorDefinitions.CANNOT_REMOVE_SELF_USER]:
    'Kendi kullanıcınızı silemezsiniz',
  [ErrorDefinitions.CANNOT_REMOVE_ADMIN_USER]:
    'Yönetici kullanıcıyı silemezsiniz',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_REACHED]:
    'Organizasyon koltuk boyutu sınırına ulaşıldı',
  [ErrorDefinitions.THEME_NOT_FOUND]: 'Tema bulunamadı',
  [ErrorDefinitions.SLIDELAYOUT_EXIST]: 'Slayt düzeni zaten mevcut',
  [ErrorDefinitions.SLIDELAYOUT_NOT_FOUND]: 'Slayt düzeni bulunamadı',
  [ErrorDefinitions.THEMECOLOR_NOT_FOUND]: 'Tema rengi bulunamadı',
  [ErrorDefinitions.THEMEFONT_NOT_FOUND]: 'Tema yazı tipi bulunamadı',
  [ErrorDefinitions.COMPONENT_NOT_FOUND]: 'Bileşen bulunamadı',
  [ErrorDefinitions.STRIPE_CUSTOMER_NOT_FOUND]: 'Stripe müşterisi bulunamadı',
  [ErrorDefinitions.ASSET_NOT_FOUND]: 'Varlık bulunamadı',
  [ErrorDefinitions.FOLDER_NOT_FOUND]: 'Klasör bulunamadı',
  [ErrorDefinitions.DECK_NOT_FOUND]: 'Sunum bulunamadı',
  [ErrorDefinitions.DECK_ALREADY_SHARED]: 'Sunum zaten paylaşılmış',
  [ErrorDefinitions.DECKSHARE_NOT_FOUND]: 'Sunum paylaşımı bulunamadı',
  [ErrorDefinitions.CUSTOMDOMAIN_NOT_FOUND]: 'Özel domain bulunamadı',
  [ErrorDefinitions.SHORTCODE_ALREADY_EXISTS]: 'Kısa kod zaten mevcut',
  [ErrorDefinitions.CUSTOMDOMAIN_ALREADY_EXISTS]: 'Özel domain zaten mevcut',
  [ErrorDefinitions.CUSTOMDOMAINSLUG_ALREADY_EXISTS]:
    'Özel domain slug zaten mevcut',
  [ErrorDefinitions.SLIDEDATA_NOT_FOUND]: 'Slayt datası bulunamadı',
  [ErrorDefinitions.DECKDATA_NOT_FOUND]: 'Sunum datası bulunamadı',
  [ErrorDefinitions.OPENAI_MAXRETRY_REACHED]: 'OpenAI azami limit aşıldı',
  [ErrorDefinitions.THEMECATEGORY_NOT_FOUND]: 'Tema categorisi bulunamadı',
  [ErrorDefinitions.MINROLE_ERROR]: 'Yetki hatası',
  [ErrorDefinitions.SLIDE_NOT_FOUND]: 'Slayt bulunamadı',
  [ErrorDefinitions.INVALID_OFFSET]: 'Offset hatalı',
  [ErrorDefinitions.FORM_QUESTION_NOT_FOUND]: 'Form sorusu bulunamadı',
  [ErrorDefinitions.UNKNOWN_FORMQUESTION_TYPE]: 'Bilinmeyen Form sorusu tipi',
  [ErrorDefinitions.MODERATION_ERROR]: 'Moderasyon hatası',
  [ErrorDefinitions.DECKPROMPT_NOT_FOUND]: 'Sunum sufleleri bulunamadı',
  [ErrorDefinitions.DECKSTATE_IS_NOT_FINAL]: 'Sunum henüz hazır değil',
  [ErrorDefinitions.THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY]:
    'Tema rengi ve tema fontu aynı tema kategorisinde olmalıdır',
  [ErrorDefinitions.CONTENT_TOO_LONG]: 'İçerik çok uzun',
  [ErrorDefinitions.DECK_EXPORT_IN_PROGRESS]: 'Sunum aktarımı başladı',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_PERMISSION]:
    'Kendi yetkinizi değiştiremezsiniz',
  [ErrorDefinitions.CANNOT_UPDATE_OWNERS_PERMISSION]:
    'Organizasyon sahibinin yetkisini değiştiremezsiniz',
  [ErrorDefinitions.USER_DECK_NOT_FOUND]: 'Kullanıcının sunumları bulunamadı',
  [ErrorDefinitions.PASSWORD_RESET_REQUIRED]:
    'Şifrenizi değiştirmeniz gerekmektedir',
  [ErrorDefinitions.ALREADY_REFERRED]: 'Bu kullanici daha once davet edilmiş',
  [ErrorDefinitions.CAPTCHA_NOT_CORRECT]:
    'Captcha doğrulamasında bir hata oluştu',
  [ErrorDefinitions.CANCEL_PROMO_ALREADY_USED]:
    'Promosyon daha önce kullanıldı',
  [ErrorDefinitions.SUBSCRIPTION_ID_NOT_FOUND]: 'Abonelik ID bulunamadı',
  [ErrorDefinitions.MFA_INVALID_TOKEN]:
    'Kod geçersiz veya süresi dolmuş. Lütfen tekrar deneyiniz.',
}
