import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFileUpload } from '@/pages/deckto-gpt/types'

export enum AI_CREATE_DECK_STATUS {
  LOADING,
  ERROR,
  SUCCESS,
}

const initialState: {
  aiFlowId: string
  selectedPromptId: number
  uploadedFiles: IFileUpload[]
  selectedOutline: string[]
  selectedTheme: string
  aiCreatedDeckId: number | null
  aiCreateDeckStatus?: AI_CREATE_DECK_STATUS | null
} = {
  aiFlowId: '',
  selectedPromptId: 0,
  selectedOutline: [],
  selectedTheme: '',
  aiCreatedDeckId: null,
  uploadedFiles: [],
  aiCreateDeckStatus: null,
}

export const aiFlowSlice = createSlice({
  name: 'aiFlow',
  initialState,
  reducers: {
    setAiFlowId: (state, action: PayloadAction<string>) => {
      state.aiFlowId = action.payload
    },
    setPromptId: (state, action: PayloadAction<number>) => {
      state.selectedPromptId = action.payload
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.selectedTheme = action.payload
    },
    updateSelectedOutline: (state, action: PayloadAction<string[]>) => {
      state.selectedOutline = action.payload
    },
    setAiCreateDeckStatus: (
      state,
      action: PayloadAction<AI_CREATE_DECK_STATUS | null>,
    ) => {
      state.aiCreateDeckStatus = action.payload
    },
    setUploadedFiles: (state, action: PayloadAction<IFileUpload[]>) => {
      state.uploadedFiles = action.payload
    },
  },
})

export const {
  setAiFlowId,
  updateSelectedOutline,
  setPromptId,
  setTheme,
  setAiCreateDeckStatus,
  setUploadedFiles,
} = aiFlowSlice.actions

export default aiFlowSlice.reducer
