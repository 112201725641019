import React from 'react'
// import reportWebVitals from './reportWebVitals'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'
import * as Sentry from '@sentry/react'
import { isProductionEnv, isStagingEnv, isTestEnv } from './helpers/env'

Sentry.init({
  dsn: 'https://83a98076a60cc6da892cf9098044f9b5@o4507922789302272.ingest.us.sentry.io/4509029383733248',
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: import.meta.env.VITE_ENV_NAME,
  tracesSampleRate: 1.0,
  enabled: isTestEnv || isStagingEnv || isProductionEnv,
  ignoreTransactions: ['/thumbnail-client'],
})

import ReactDOM from 'react-dom/client'
import { withLDProvider, basicLogger } from 'launchdarkly-react-client-sdk'
import App from './App'
import './i18n/config'
import { flags } from './config'

const LDProvider = withLDProvider({
  clientSideID: import.meta.env.VITE_LD_SDK_KEY,
  context: {
    kind: 'user',
    key: 'USER_ANNMYS',
    name: 'User Anonymous',
    email: 'user_annmys@decktopus.com',
  },
  user: {
    key: 'USER_ANNMYS',
    name: 'User Anonymous',
  },
  options: {
    bootstrap: 'localStorage',
    logger: basicLogger({ level: isProductionEnv ? 'none' : 'info' }),
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags,
})(App)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<LDProvider />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
