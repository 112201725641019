import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router'
import * as Sentry from '@sentry/react'
import { isCustomDomain } from '@/helpers/isCustomDomain'
import {
  DECK_PAGE_MODE,
  IDeckPageLayout,
} from '@/layouts/deck-page-layout/types'
import { IPreviewPage } from '@/pages/deck-page/types'
import { useEffect } from 'react'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

// Lazy load pages – note the mapping of named exports to default exports.
const DashboardPage = () =>
  import('@/pages/dashboard-page').then((module) => ({
    Component: module.DashboardPage,
  }))

const LoginPage = () =>
  import('@/pages/login-page').then((module) => ({
    Component: module.LoginPage,
  }))
const MFAPage = () =>
  import('@/pages/mfa-page').then((module) => ({
    Component: module.MFAPage,
  }))
const LogoutPage = () =>
  import('@/pages/logout-page').then((module) => ({
    Component: module.LogoutPage,
  }))

const ResetPasswordPage = () =>
  import('@/pages/reset-password-page').then((module) => ({
    Component: module.ResetPasswordPage,
  }))

const SignUpPage = () =>
  import('@/pages/sign-up-page').then((module) => ({
    Component: module.SignUpPage,
  }))
const OrganizationPage = () =>
  import('@/pages/organization-page').then((module) => ({
    Component: module.OrganizationPage,
  }))

const TemplatesPage = () =>
  import('@/pages/templates-page').then((module) => ({
    Component: module.TemplatesPage,
  }))

const OrganizationMembers = () =>
  import('@/pages/organization-page/components/OrganizationMembers').then(
    (module) => ({ Component: module.OrganizationMembers }),
  )

const OrganizationDetails = () =>
  import('@/pages/organization-page/components/OrganizationDetails').then(
    (module) => ({ Component: module.OrganizationDetails }),
  )

const EditPage = () =>
  import('@/pages/deck-page').then((module) => ({
    Component: module.EditPage,
  }))

const ScratchPage = () =>
  import('@/pages/scratch-page').then((module) => ({
    Component: module.ScratchPage,
  }))

const NotSupportedPage = () =>
  import('@/pages/not-supported-page').then((module) => ({
    Component: module.NotSupportedPage,
  }))

const UserClassifyPage = () =>
  import('@/pages/user-classify-page').then((module) => ({
    Component: module.UserClassifyPage,
  }))

const PaymentPage = () =>
  import('@/pages/payment-page').then((module) => ({
    Component: module.PaymentPage,
  }))

const AdminPage = () =>
  import('@/pages/admin-page').then((module) => ({
    Component: module.AdminPage,
  }))

const EditPageV3 = () =>
  import('@/pages/deck-page-v3').then((module) => ({
    Component: module.EditPageV3,
  }))

const V3TemplatesPage = () =>
  import('@/pages/v3-templates-page').then((module) => ({
    Component: module.V3TemplatesPage,
  }))

const DecktoGPT = () =>
  import('@/pages/deckto-gpt/DecktoGPT').then((module) => ({
    Component: module.default,
  }))

const DecktoGPTLoading = () =>
  import('@/pages/deckto-gpt/DecktoGPTLoading').then((module) => ({
    Component: module.default,
  }))

const ThemeBuilder = () =>
  import('@/pages/theme-builder').then((module) => ({
    Component: module.ThemeBuilder,
  }))

const StyleBuilder = () =>
  import('@/pages/style-builder').then((module) => ({
    Component: module.StyleBuilder,
  }))

const PreviewVersionWrapper = (props: IPreviewPage = {}) =>
  import('@/pages/deck-page/PreviewVersionWrapper').then((module) => ({
    Component: (componentProps: any) => (
      <module.PreviewVersionWrapper {...props} {...componentProps} />
    ),
  }))

const ExpiredPageLayout = () =>
  import('@/layouts/expired-page-layout').then((module) => ({
    Component: module.ExpiredPageLayout,
  }))

const ThumbnailClientPage = () =>
  import('@/pages/thumbnail-client-page').then((module) => ({
    Component: module.ThumbnailClientPage,
  }))

const ProtectedPageLayout = () =>
  import('@/layouts/protected-page-layout').then((module) => ({
    Component: module.ProtectedPageLayout,
  }))
const MainLayout = () =>
  import('@/layouts/main-layout').then((module) => ({
    Component: module.MainLayout,
  }))
const InitPageLayout = () =>
  import('@/layouts/init-page-layout').then((module) => ({
    Component: module.InitPageLayout,
  }))
const DeckPageLayout = (props: IDeckPageLayout = {}) =>
  import('@/layouts/deck-page-layout').then((module) => ({
    Component: (componentProps: any) => (
      <module.DeckPageLayout {...props} {...componentProps} />
    ),
  }))
const UnprotectedPageLayout = () =>
  import('@/layouts/unprotected-page-layout').then((module) => ({
    Component: module.UnprotectedPageLayout,
  }))

const DeckPageLayoutV3 = () =>
  import('@/layouts/deck-page-layout/DeckPageLayoutV3').then((module) => ({
    Component: module.DeckPageLayoutV3,
  }))

const StyleBuilderPageLayout = () =>
  import('@/layouts/deck-page-layout/StyleBuilderPageLayout').then(
    (module) => ({
      Component: module.StyleBuilderPageLayout,
    }),
  )
const ThemeBuilderPageLayout = () =>
  import('@/layouts/deck-page-layout/ThemeBuilderPageLayout').then(
    (module) => ({
      Component: module.ThemeBuilderPageLayout,
    }),
  )

const urlParams = new URLSearchParams(window.location.search)

const aiParam = urlParams.get('ai') || null
const planParam = urlParams.get('plan') || null

const paymentParam = urlParams.get('payment') || null
const payloadParam = urlParams.get('payload') || null

const templateParam = urlParams.get('templateDeckId') || null
const templateV3Param = urlParams.get('templateId') || null

let paymentParams: string

if (aiParam) {
  localStorage.setItem('landingPrompt', aiParam)
}
if (planParam) {
  localStorage.setItem('plan', planParam)
}
if (templateParam) {
  localStorage.setItem('templateDeckId', templateParam)
}

if (templateV3Param) {
  localStorage.setItem('templateId', templateV3Param)
}

if (paymentParam) {
  paymentParams = '?payment=' + paymentParam
  if (payloadParam) {
    paymentParams += '&payload=' + payloadParam
  }
}

function ErrorBoundary() {
  const error = useRouteError() as Error

  useEffect(() => {
    if (
      !error.message.includes('Failed to fetch dynamically imported module')
    ) {
      Sentry.captureException(error)
    }
  }, [error])

  if (error.message.includes('Failed to fetch dynamically imported module')) {
    console.error('Error fetching module1111121qq:', error)
    window.location.reload()
    return null
  }
  return (
    <div>
      <h1>Uh oh, something went terribly wrong 😩</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
      <button onClick={() => (window.location.href = '/')}>
        Click here to reload the app
      </button>
    </div>
  )
}

function HydrateFallback() {
  return <></>
}

const Routes = () => {
  // Define public routes accessible to all users
  const routesForPublic: RouteObject[] = [
    {
      path: '/',
      lazy: UnprotectedPageLayout,
      HydrateFallback,
      ErrorBoundary,
      children: [
        {
          path: '/',
          element: (
            <Navigate to={`/dashboard${paymentParams ? paymentParams : ''}`} />
          ),
        },
        {
          path: '/sign-up',
          lazy: SignUpPage,
        },
        {
          path: '/login',
          lazy: LoginPage,
        },
        {
          path: '/mfa',
          lazy: MFAPage,
        },
        {
          path: '/reset',
          lazy: ResetPasswordPage,
        },
        {
          path: '/*',
          element: <Navigate to="/dashboard" />,
        },
        {
          path: '/share',
          lazy: () =>
            DeckPageLayout({
              mode: DECK_PAGE_MODE.PREVIEW,
            }),
          children: [
            {
              path: '/share/:id/:slide?',
              lazy: PreviewVersionWrapper,
            },
          ],
        },
        {
          path: '/expired-page',
          lazy: ExpiredPageLayout,
        },
        {
          path: '/payment',
          lazy: PaymentPage,
        },
        {
          path: '/thumbnail-client',
          lazy: ThumbnailClientPage,
        },
      ],
    },
  ]

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly: RouteObject[] = [
    {
      path: '/',
      lazy: ProtectedPageLayout,
      ErrorBoundary,
      HydrateFallback,
      children: [
        {
          path: '/',
          lazy: MainLayout,
          children: [
            {
              path: '/dashboard',
              lazy: DashboardPage,
            },
            {
              path: '/logout',
              lazy: LogoutPage,
            },
            {
              path: '/organization',
              lazy: OrganizationPage,
              children: [
                {
                  path: '/organization',
                  lazy: OrganizationMembers,
                },
                {
                  path: '/organization/details',
                  lazy: OrganizationDetails,
                },
              ],
            },
            {
              path: '/admin',
              lazy: AdminPage,
            },
          ],
        },
        {
          path: '/user-classify',
          lazy: UserClassifyPage,
        },
        {
          path: '/init',
          lazy: InitPageLayout,
          children: [
            {
              path: '/init/template',
              lazy: TemplatesPage,
            },
            {
              path: '/init/template/v3',
              lazy: V3TemplatesPage,
            },
            {
              path: '/init/scratch',
              lazy: ScratchPage,
            },
            {
              path: '/init/scratch/:category',
              lazy: ScratchPage,
            },
          ],
        },
        {
          path: '/init/ai',
          lazy: DecktoGPT,
        },
        {
          path: '/init/ai/loading',
          lazy: DecktoGPTLoading,
        },
        {
          path: '/deck',
          lazy: DeckPageLayout,
          children: [
            {
              path: '/deck/:id/:slide?',
              lazy: EditPage,
            },
          ],
        },
        {
          path: '/deck-v3',
          lazy: DeckPageLayoutV3,
          children: [
            {
              path: '/deck-v3/:id/:slide?',
              lazy: EditPageV3,
            },
          ],
        },
        {
          path: '/style-builder',
          lazy: StyleBuilderPageLayout,
          children: [
            {
              path: '/style-builder/:id?',
              lazy: StyleBuilder,
            },
          ],
        },
        {
          path: '/theme-builder',
          lazy: ThemeBuilderPageLayout,
          children: [
            {
              path: '/theme-builder/:id',
              lazy: ThemeBuilder,
            },
          ],
        },
        {
          path: '/not-supported-page',
          lazy: NotSupportedPage,
        },
        {
          path: '/expired-page',
          lazy: ExpiredPageLayout,
        },
        {
          path: '/preview',
          lazy: () => DeckPageLayout({ mode: DECK_PAGE_MODE.PREVIEW }),
          children: [
            {
              path: '/preview/:id/:slide?',
              lazy: PreviewVersionWrapper,
            },
          ],
        },
        {
          path: '/export',
          lazy: () => DeckPageLayout({ mode: DECK_PAGE_MODE.EXPORT }),
          children: [
            {
              path: '/export/:id/:slide?',
              lazy: () =>
                PreviewVersionWrapper({
                  mode: DECK_PAGE_MODE.EXPORT,
                }),
            },
          ],
        },
      ],
    },
  ]

  // Define routes accessible only to authenticated users
  const routesForCustomDomains: RouteObject[] = [
    {
      path: '/',
      ErrorBoundary,
      HydrateFallback,
      // TODO Use 404 Page not found layout here when its ready
      element: <Navigate to={'/expired-page'} />,
    },
    {
      path: '/expired-page',
      ErrorBoundary,
      HydrateFallback,
      // TODO Use 404 Page not found layout here when its ready
      lazy: ExpiredPageLayout,
    },
    {
      path: '/:id',
      ErrorBoundary,
      element: (
        <Navigate to={`/share/${window.location.pathname.split('/')[1]}`} />
      ),
    },
    {
      path: '/share/:id',
      ErrorBoundary,
      HydrateFallback,
      lazy: () => DeckPageLayout({ mode: DECK_PAGE_MODE.PREVIEW }),
      children: [
        {
          path: '/share/:id/:slide?',
          lazy: PreviewVersionWrapper,
        },
      ],
    },
  ]

  const hasCustomDomain = isCustomDomain()

  // Combine and conditionally include routes based on authentication status
  const router = sentryCreateBrowserRouter([
    ...(hasCustomDomain ? [] : routesForPublic),
    ...(hasCustomDomain ? [] : routesForAuthenticatedOnly),
    ...(hasCustomDomain ? routesForCustomDomains : []),
  ])

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />
}

export default Routes
