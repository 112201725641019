import { COLOR, theme } from '@/theme'
import { ColorMapSchema } from '@/types/api/requestObjects'

export interface ITextToColor {
  text: string
  colors?: COLOR[]
}

const defaultColorsList = [
  theme.colors.avatar[1],
  theme.colors.avatar[2],
  theme.colors.avatar[3],
  theme.colors.avatar[4],
  theme.colors.avatar[5],
  theme.colors.avatar[6],
  theme.colors.avatar[7],
]

export const textToColor = ({
  text,
  colors = defaultColorsList,
}: ITextToColor) => {
  const codeTotal = text.split('').reduce((a, char) => {
    return a * 3 + char.charCodeAt(0)
  }, 0)
  return colors[codeTotal % colors.length]
}

export const isContainingCssVariable = ({ text }: { text: string }) => {
  return text.includes('var(--')
}

export const cssVariableToVariable = ({ text }: { text: string }) => {
  return text.substring(text.lastIndexOf('var(--') + 6, text.lastIndexOf(')'))
}

export const colorVariableToCssVariable = ({ value }: { value?: string }) => {
  if (!value) return
  if (isContainingCssVariable({ text: value }) || value.includes('#')) {
    return value
  }
  return 'var(--' + value + ')'
}

export const colorMapSchemaHandler = ({
  values,
  key,
  swapped,
}: {
  values: ColorMapSchema | (ColorMapSchema | null)[]
  key: string
  swapped?: boolean
}) => {
  if (key === 'decorations' || key === 'charts') {
    if (!values) return
    return (values as ColorMapSchema[]).map((value, index) => {
      if (!value) return
      if (!value[swapped ? 'swap' : 'default']) return
      if (
        value[swapped ? 'swap' : 'default'].colors[0].includes('#') ||
        value[swapped ? 'swap' : 'default'].colors[0].includes('var')
      ) {
        return `--${key + (index + 1)}: ${
          value[swapped ? 'swap' : 'default'].colors[0]
        };`
      }
      return `--${key + (index + 1)}: var(--${
        value[swapped ? 'swap' : 'default'].colors[0]
      });`
    })
  } else {
    if (
      (values as ColorMapSchema)[
        swapped ? 'swap' : 'default'
      ].colors[0].includes('#') ||
      (values as ColorMapSchema)[
        swapped ? 'swap' : 'default'
      ].colors[0].includes('var')
    ) {
      if (key === 'label') {
        return `--labelText: ${
          (values as ColorMapSchema)[swapped ? 'swap' : 'default'].colors[0]
        };`
      }
      return `--${key}: ${
        (values as ColorMapSchema)[swapped ? 'swap' : 'default'].colors[0]
      };`
    }
    if (key === 'label') {
      return `--labelText: var(--${
        (values as ColorMapSchema)[swapped ? 'swap' : 'default'].colors[0]
      });`
    }
    return `--${key}: var(--${
      (values as ColorMapSchema)[swapped ? 'swap' : 'default'].colors[0]
    });`
  }
}
