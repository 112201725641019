import { DeleteComponentSchema } from '@/types/api/requestObjects'
import {
  EDIT_ACTION_COUNTER,
  EDIT_ACTION_TYPE,
  IEditInitialState,
  IHistoryItem,
} from '../types'
import { ComponentSchema } from '@/types/api/responseObjects'

export enum UNDO_REDO_ACTION {
  UNDO = 'undo',
  REDO = 'redo',
}

export const undoRedoAction =
  (type: UNDO_REDO_ACTION) => (state: IEditInitialState) => {
    const { past, future } = state.undoHistory

    if (
      (type === UNDO_REDO_ACTION.UNDO && past.length > 0) ||
      (type === UNDO_REDO_ACTION.REDO && future.length > 0)
    ) {
      let lastItem: IHistoryItem | undefined
      if (type === UNDO_REDO_ACTION.UNDO) {
        lastItem = past.pop()
      } else {
        lastItem = future.pop()
      }

      if (lastItem) {
        const slideDataId = state.activeDeck.data?.deckData?.data.slides?.find(
          ({ slideId }) => slideId === lastItem!.oldValue.id,
        )?.slideDataId

        if (slideDataId) {
          switch (lastItem.action) {
            case EDIT_ACTION_TYPE.COMPONENT_UPDATE:
              const updatedComponents = lastItem.oldValue
                .components as ComponentSchema[]

              updatedComponents.forEach((comp) => {
                const clientComponent = state.activeSlideData.data
                  ?.find(({ id }) => id === slideDataId)
                  ?.slideDataComponents.find(({ component }) => {
                    if (component?.id && comp?.id) {
                      return component.id === comp.id
                    }

                    if (component?.tempId && comp?.tempId) {
                      return component.tempId === comp.tempId
                    }

                    return false
                  })?.component

                if (clientComponent) {
                  clientComponent.data = comp.data
                  clientComponent.positions = comp.positions
                  clientComponent.style = comp.style
                }
              })

              break
            case EDIT_ACTION_TYPE.COMPONENT_CREATE:
              lastItem.oldValue.components?.forEach((targetComp) => {
                const deleteComponent = targetComp as DeleteComponentSchema
                const targetComponentIndex = state.activeSlideData.data
                  ?.find(({ id }) => id === slideDataId)
                  ?.slideDataComponents.findIndex(({ component }) => {
                    if (component?.id && deleteComponent?.id) {
                      return component.id === deleteComponent.id
                    }

                    if (component?.tempId && deleteComponent?.tempId) {
                      return component.tempId === deleteComponent.tempId
                    }

                    return false
                  })

                if (
                  targetComponentIndex !== undefined &&
                  targetComponentIndex > -1
                ) {
                  state.activeSlideData.data
                    ?.find(({ id }) => id === slideDataId)
                    ?.slideDataComponents.splice(targetComponentIndex, 1)
                }
              })

              break
            case EDIT_ACTION_TYPE.COMPONENT_DELETE:
              lastItem.oldValue.components?.forEach((targetComp) => {
                state.activeSlideData.data
                  ?.find(({ id }) => id === slideDataId)
                  ?.slideDataComponents.push({
                    component: targetComp as ComponentSchema,
                  })
              })
              break
            default:
              break
          }
        }

        state.toBeSaved.data.push(lastItem.oldValue)

        const swappedItem = {
          oldValue: lastItem.newValue,
          newValue: lastItem.oldValue,
          action: EDIT_ACTION_COUNTER[lastItem.action],
        }

        if (type === UNDO_REDO_ACTION.UNDO) {
          future.push(swappedItem)
        } else {
          past.push(swappedItem)
        }
      }
    }
  }
