import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlanNames, PlanPeriods } from '@/types/api/enums'
import {
  getPlansResponse,
  postPlanPurchaseResponse,
} from '@/types/api/responseObjects'

interface IPlanSliceState {
  possiblePlans: getPlansResponse['data']['plans'] | null
  purchaseInformation: postPlanPurchaseResponse['data'] | null
  isPaymentConfirmationModalOpen: boolean
  planName: PlanNames | null
  planPeriod: PlanPeriods | null
  seatSize: number
}

const initialState: IPlanSliceState = {
  possiblePlans: null,
  purchaseInformation: null,
  isPaymentConfirmationModalOpen: false,
  planName: null,
  planPeriod: null,
  seatSize: 0,
}

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPossiblePlans: (
      state,
      action: PayloadAction<getPlansResponse['data']['plans']>,
    ) => {
      state.possiblePlans = action.payload
    },
    setPurchaseInformation: (
      state,
      action: PayloadAction<postPlanPurchaseResponse['data'] | null>,
    ) => {
      state.purchaseInformation = action.payload
    },
    setPaymentConfirmationModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isPaymentConfirmationModalOpen = action.payload
    },
    setPlanDetails: (
      state,
      action: PayloadAction<{
        planName: PlanNames
        planPeriod: PlanPeriods
        seatSize: number
      }>,
    ) => {
      state.planName = action.payload.planName
      state.planPeriod = action.payload.planPeriod
      state.seatSize = action.payload.seatSize
    },
  },
})

export const {
  setPossiblePlans,
  setPurchaseInformation,
  setPaymentConfirmationModalOpen,
  setPlanDetails,
} = planSlice.actions

export default planSlice.reducer
