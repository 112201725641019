import { COMPONENT_DIST } from '../types'
import { ComponentSchema } from '@/types/api/responseObjects'

// Helper function to calculate the bounding box of components
const calculateBoundingBox = (
  components: {
    index: number
    component: ComponentSchema
  }[],
  distribute: COMPONENT_DIST,
) => {
  const positions = components.map((item) => item.component.positions)

  const minPosition = Math.min(
    ...positions.map((pos) =>
      distribute === COMPONENT_DIST.HORIZONTAL ? pos.x || 0 : pos.y || 0,
    ),
  )

  const maxPosition = Math.max(
    ...positions.map((pos) =>
      distribute === COMPONENT_DIST.HORIZONTAL
        ? (pos.x || 0) + (pos.width || 0)
        : (pos.y || 0) + (pos.height || 0),
    ),
  )

  return {
    minPosition,
    maxPosition,
    boundingBoxSize: maxPosition - minPosition,
  }
}

export const distributeComponents = (
  components: {
    index: number
    component: ComponentSchema
  }[],
  distribute?: COMPONENT_DIST,
) => {
  if (!distribute || components.length <= 2) {
    return components
  }

  const sortedComponents = [...components].sort((a, b) => {
    if (distribute === COMPONENT_DIST.HORIZONTAL) {
      return (a.component.positions.x || 0) - (b.component.positions.x || 0)
    } else {
      return (a.component.positions.y || 0) - (b.component.positions.y || 0)
    }
  })

  const firstComponent = sortedComponents[0]
  const lastComponent = sortedComponents[sortedComponents.length - 1]

  const totalWidthOrHeight =
    distribute === COMPONENT_DIST.HORIZONTAL
      ? (lastComponent.component.positions.x || 0) +
        (lastComponent.component.positions.width || 0) -
        (firstComponent.component.positions.x || 0)
      : (lastComponent.component.positions.y || 0) +
        (lastComponent.component.positions.height || 0) -
        (firstComponent.component.positions.y || 0)

  const totalSpacing =
    totalWidthOrHeight -
    sortedComponents.reduce((acc, item) => {
      return (
        acc +
        (distribute === COMPONENT_DIST.HORIZONTAL
          ? item.component.positions.width || 0
          : item.component.positions.height || 0)
      )
    }, 0)

  const numGaps = sortedComponents.length - 1
  const spacing = numGaps > 0 ? totalSpacing / numGaps : 0

  // Get the original bounding box before distribution
  const originalBoundingBox = calculateBoundingBox(sortedComponents, distribute)

  // Check if any component's width (for horizontal) or height (for vertical) equals the bounding box size
  const hasFullSizeComponent = sortedComponents.some((item) => {
    return distribute === COMPONENT_DIST.HORIZONTAL
      ? Math.ceil(item.component.positions.width || 0) >=
          originalBoundingBox.boundingBoxSize
      : Math.ceil(item.component.positions.height || 0) >=
          originalBoundingBox.boundingBoxSize
  })

  if (hasFullSizeComponent) {
    // If a component covers the entire bounding box (width or height), return without changes
    return components
  }

  let offset = 0
  const distributedComponents = sortedComponents.map((item) => {
    const newPosition =
      distribute === COMPONENT_DIST.HORIZONTAL
        ? (firstComponent.component.positions.x || 0) + offset
        : (firstComponent.component.positions.y || 0) + offset

    offset +=
      (distribute === COMPONENT_DIST.HORIZONTAL
        ? item.component.positions.width || 0
        : item.component.positions.height || 0) + spacing

    return {
      index: item.index,
      component: {
        ...item.component,
        positions: {
          ...item.component.positions,
          [distribute === COMPONENT_DIST.HORIZONTAL ? 'x' : 'y']: newPosition,
        },
      },
    }
  })

  // Get the bounding box after distribution
  const newBoundingBox = calculateBoundingBox(distributedComponents, distribute)

  // Check if the bounding box size has changed, indicating an issue
  if (newBoundingBox.boundingBoxSize !== originalBoundingBox.boundingBoxSize) {
    // Disable distribution if bounding box changes
    return components
  }

  return distributedComponents
}
