import { ErrorDefinitions } from '@/types/api/ErrorDefinitions'

type IErrorDefinitions = {
  [key in ErrorDefinitions]?: string
}

export const errors: IErrorDefinitions = {
  [ErrorDefinitions.VALIDATION_EMAIL_MALFORMED]: 'Email is not valid',
  [ErrorDefinitions.VALIDATION_PASSWORD_LENGTH]:
    'Password must be at least 10 characters',
  [ErrorDefinitions.AICREDITREASONDEFAULTVALUE_NOT_FOUND]:
    'AI Credit Reason Default Value not found',
  [ErrorDefinitions.API_ERROR]: 'API Error',
  [ErrorDefinitions.DATE_ERROR]: 'Invalid date format',
  [ErrorDefinitions.INCORRECT_PROVIDER]:
    'Please use google login for this account',
  [ErrorDefinitions.KEY_NOT_FOUND]: 'Key not found',
  [ErrorDefinitions.NO_ORG_ACCESS]: 'No organization access',
  [ErrorDefinitions.NO_TEAM_ACCESS]: 'No team access',
  [ErrorDefinitions.PLAN_NOT_FOUND]: 'Plan not found',
  [ErrorDefinitions.ACTIVE_PAYMENT_IN_PROGRESS]: 'Active Payment in Progress',
  [ErrorDefinitions.PLAN_IS_NOT_AVAILABLE]: 'Plan is not available',
  [ErrorDefinitions.PLAN_PRODUCT_NOT_FOUND]: 'Plan product not found',
  [ErrorDefinitions.PRICE_NOT_FOUND]: 'Price not found',
  [ErrorDefinitions.USER_NOT_FOUND]: 'User not found',
  [ErrorDefinitions.USER_PLAN_NOT_FOUND]: 'User plan not found',
  [ErrorDefinitions.USER_EXIST]: 'The email address has already exist',
  [ErrorDefinitions.WRONG_PASSWORD]: 'Wrong password',
  [ErrorDefinitions.USER_NOT_VERIFIED]: 'User not verified',
  [ErrorDefinitions.REFERRED_USER_EXIST]: 'Referred user exists',
  [ErrorDefinitions.CAN_NOT_PURCHASE]: 'Cannot purchase',
  [ErrorDefinitions.CAN_NOT_UPGRADE]: 'Cannot upgrade',
  [ErrorDefinitions.SUBSCRIPTION_NOT_FOUND]: 'Subscription not found',
  [ErrorDefinitions.SUBSCRIPTION_ITEM_NOT_FOUND]: 'Subscription item not found',
  [ErrorDefinitions.SUBSCRIPTION_CHANGE_NOT_FOUND]:
    'Subscription change not found',
  [ErrorDefinitions.SUBSCRIPTION_IS_NOT_ACTIVE]: 'Subscription is not active',
  [ErrorDefinitions.UNKNOWN_EVENT_TYPE]: 'Unknown event type',
  [ErrorDefinitions.TEAM_NOT_FOUND]: 'Team not found',
  [ErrorDefinitions.ORG_NOT_FOUND]: 'Organization not found',
  [ErrorDefinitions.ACCESS_NOT_FOUND]: 'Access not found',
  [ErrorDefinitions.USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION]:
    'User already exists in this organization',
  [ErrorDefinitions.USERS_ALREADY_EXISTS_IN_THIS_TEAM]:
    'Users already exist in this team',
  [ErrorDefinitions.INVITATION_ALREADY_EXISTS]: 'Invitation already exists',
  [ErrorDefinitions.INVITATION_NOT_FOUND]: 'Invitation not found',
  [ErrorDefinitions.ORGANIZATION_NOT_FOUND]: 'Organization not found',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_DOWNGRADE]:
    'Organization seat size downgrade',
  [ErrorDefinitions.AI_CREDIT_REASON_NOT_DEFINED]:
    'AI Credit Reason not defined',
  [ErrorDefinitions.INSUFFICIENT_AI_CREDIT]: 'Insufficient AI credit',
  [ErrorDefinitions.WEBHOOK_EXISTS]: 'Webhook already exists',
  [ErrorDefinitions.WEBHOOK_NOT_EXISTS]: 'Webhook not exists',
  [ErrorDefinitions.WEBHOOK_NO_CHANGE]: 'Webhook no change',
  [ErrorDefinitions.PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND]:
    'Password reset request expired or not found',
  [ErrorDefinitions.TEAM_IS_NOT_IN_THIS_ORGANIZATION]:
    'Team is not in this organization',
  [ErrorDefinitions.USERS_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Users not found in this organization',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_TEAM]: 'User not found in this team',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'User not found in this organization',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_USER]: 'Cannot update self user',
  [ErrorDefinitions.MISSING_QUESTION_TYPES]: 'Missing question types',
  [ErrorDefinitions.USER_ALREADY_CLASSIFIED]: 'User already classified',
  [ErrorDefinitions.WRONG_VERIFICATION_CODE]:
    'The entered code is incorrect. Please check your email.',
  [ErrorDefinitions.USER_ALREADY_VERIFIED]: 'User already verified',
  [ErrorDefinitions.TARGET_USER_IS_OWNER]: 'Target user is the owner',
  [ErrorDefinitions.CANNOT_REMOVE_SELF_USER]: 'Cannot remove self user',
  [ErrorDefinitions.CANNOT_REMOVE_ADMIN_USER]: 'Cannot remove admin user',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_REACHED]:
    'Organization seat size reached',
  [ErrorDefinitions.THEME_NOT_FOUND]: 'Theme not found',
  [ErrorDefinitions.SLIDELAYOUT_EXIST]: 'Slide layout already exists',
  [ErrorDefinitions.SLIDELAYOUT_NOT_FOUND]: 'Slide layout not found',
  [ErrorDefinitions.THEMECOLOR_NOT_FOUND]: 'Theme color not found',
  [ErrorDefinitions.THEMEFONT_NOT_FOUND]: 'Theme font not found',
  [ErrorDefinitions.COMPONENT_NOT_FOUND]: 'Component not found',
  [ErrorDefinitions.STRIPE_CUSTOMER_NOT_FOUND]: 'Stripe customer not found',
  [ErrorDefinitions.ASSET_NOT_FOUND]: 'Asset not found',
  [ErrorDefinitions.FOLDER_NOT_FOUND]: 'Folder not found',
  [ErrorDefinitions.DECK_NOT_FOUND]: 'Deck not found',
  [ErrorDefinitions.DECK_ALREADY_SHARED]: 'Deck already shared',
  [ErrorDefinitions.DECKSHARE_NOT_FOUND]: 'Deck share not found',
  [ErrorDefinitions.CUSTOMDOMAIN_NOT_FOUND]: 'Custom domain not found',
  [ErrorDefinitions.SHORTCODE_ALREADY_EXISTS]: 'Shortcode already exists',
  [ErrorDefinitions.CUSTOMDOMAIN_ALREADY_EXISTS]:
    'Custom domain already exists',
  [ErrorDefinitions.CUSTOMDOMAINSLUG_ALREADY_EXISTS]:
    'Custom domain slug already exists',
  [ErrorDefinitions.SLIDEDATA_NOT_FOUND]: 'Slide data not found',
  [ErrorDefinitions.DECKDATA_NOT_FOUND]: 'Deck data not found',
  [ErrorDefinitions.OPENAI_MAXRETRY_REACHED]:
    'OpenAI maximum retry limit reached',
  [ErrorDefinitions.THEMECATEGORY_NOT_FOUND]: 'Theme category not found',
  [ErrorDefinitions.MINROLE_ERROR]: 'Minrole authorization error',
  [ErrorDefinitions.SLIDE_NOT_FOUND]: 'Slide not found',
  [ErrorDefinitions.INVALID_OFFSET]: 'Invalid offset',
  [ErrorDefinitions.FORM_QUESTION_NOT_FOUND]: 'Form question not found',
  [ErrorDefinitions.UNKNOWN_FORMQUESTION_TYPE]: 'Unknown form question type',
  [ErrorDefinitions.MODERATION_ERROR]: 'Moderation error',
  [ErrorDefinitions.DECKPROMPT_NOT_FOUND]: 'Deck prompt not found',
  [ErrorDefinitions.DECKSTATE_IS_NOT_FINAL]: 'Deck state is not final',
  [ErrorDefinitions.THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY]:
    'Theme color and theme font should be in the same category',
  [ErrorDefinitions.CONTENT_TOO_LONG]: 'Content is too long',
  [ErrorDefinitions.DECK_EXPORT_IN_PROGRESS]: 'Deck export in progress',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_PERMISSION]:
    'Cannot update self permission',
  [ErrorDefinitions.CANNOT_UPDATE_OWNERS_PERMISSION]:
    'Cannot update organization owners permission',
  [ErrorDefinitions.USER_DECK_NOT_FOUND]: 'User deck not found',
  [ErrorDefinitions.PASSWORD_RESET_REQUIRED]:
    'Password reset is required, please change your password',
  [ErrorDefinitions.ALREADY_REFERRED]: 'This user has already referred',
  [ErrorDefinitions.CAPTCHA_NOT_CORRECT]: 'Could not validate Captcha',
  [ErrorDefinitions.CANCEL_PROMO_ALREADY_USED]:
    'Promotion has already been used',
  [ErrorDefinitions.SUBSCRIPTION_ID_NOT_FOUND]: 'Subscription ID not found',
  [ErrorDefinitions.MFA_INVALID_TOKEN]:
    'The token is invalid or expired. Please try again.',
}
