import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getAICreditSpendSummarizedResponse,
  getAICreditsTotalResponse,
  getOrganizationDetailsResponse,
  getUsersInOrganizationResponse,
} from '@/types/api/responseObjects'

interface IOrgSliceState {
  aicredits: {
    total: getAICreditsTotalResponse['data']['totalCredits'] | null
    summary: getAICreditSpendSummarizedResponse['data'] | null
  }
  isOrgMenuOpen: boolean
  details: getOrganizationDetailsResponse['data']['organization'] | null
  users: getUsersInOrganizationResponse['data']['organizationUsers'] | null
  pendingInvitedUsers:
    | getUsersInOrganizationResponse['data']['pendingInvitedUsers']
    | null
  seatSize: getOrganizationDetailsResponse['data']['meta']['seatSize']
  userRoleInOrg: null
}

const initialState: IOrgSliceState = {
  aicredits: {
    total: null,
    summary: null,
  },
  isOrgMenuOpen: true,
  details: null,
  users: null,
  pendingInvitedUsers: null,
  seatSize: 0,
  userRoleInOrg: null,
}

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setOrgAICredits: (
      state,
      action: PayloadAction<getAICreditsTotalResponse['data']['totalCredits']>,
    ) => {
      state.aicredits.total = action.payload
    },
    setOrgAICreditsSummary: (
      state,
      action: PayloadAction<getAICreditSpendSummarizedResponse['data']>,
    ) => {
      state.aicredits.summary = action.payload
    },
    setIsOrgMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isOrgMenuOpen = action.payload
    },
    setOrgDetails: (
      state,
      action: PayloadAction<getOrganizationDetailsResponse['data']>,
    ) => {
      state.details = action.payload.organization
      state.seatSize = action.payload.meta.seatSize
    },
    setOrgUsers: (
      state,
      action: PayloadAction<getUsersInOrganizationResponse['data']>,
    ) => {
      state.users = action.payload.organizationUsers
    },
    setOrgPendingUsers: (
      state,
      action: PayloadAction<getUsersInOrganizationResponse['data']>,
    ) => {
      state.pendingInvitedUsers = action.payload.pendingInvitedUsers
    },
  },
})

export const {
  setOrgAICredits,
  setOrgAICreditsSummary,
  setIsOrgMenuOpen,
  setOrgDetails,
  setOrgUsers,
  setOrgPendingUsers,
} = orgSlice.actions

export default orgSlice.reducer
