import { valueOf } from '@/types'
import { colors } from './colors'

export const gradient = {
  DEFAULT: `linear-gradient(50deg, ${colors.primary.DEFAULT} 0%, ${colors.pink.DEFAULT} 100%)`,
  DEG_180: `linear-gradient(180deg, ${colors.primary.DEFAULT} 0%, ${colors.pink.DEFAULT} 100%)`,
  V_10: `linear-gradient(50deg, ${colors.primary[10]} 0%, ${colors.pink[10]} 100%)`,
  V_80: `linear-gradient(50deg, ${colors.primary[80]} 0%, ${colors.pink[80]} 100%)`,

  OVERLAY: `linear-gradient(0deg, #18001C 0%, rgba(31,8,43,0.4) 90%)`,

  PURPLE: `linear-gradient(270deg, #8843FB 0%, ${colors.primary.DEFAULT} 100%)`,

  PURPLE_BORDER: `linear-gradient(white, white),
  linear-gradient(to right, ${colors.primary.DEFAULT}, #8843fb)`,

  RED_BORDER: `linear-gradient(white, white),
  linear-gradient(to right, ${colors.error.DEFAULT}, ${colors.error.DEFAULT})`,

  HOVER_BLUE_PURPLE_BORDER: `linear-gradient(#95A0FF, #95A0FF),
  linear-gradient(to right, ${colors.primary.DEFAULT}, #8843fb);`,

  SOFT: `linear-gradient(214deg, ${colors.primary.DEFAULT} 0%, #C74FFF 90%)`,

  CARD: ` linear-gradient(
		0,
		${colors.overlay.cardSecondary}FA,
		${colors.overlay.card}CF
	)`,

  VOICE: ` linear-gradient(
		0,
		${colors.background[90]} 0%,
		${colors.white.DEFAULT} 100%
	)`,
}

export type IGradient = valueOf<typeof gradient, string>
