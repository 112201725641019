import { TranslateLanguages } from '@/types/api/enums'

export const AVAILABLE_LANGUAGES = ['en', 'tr', 'de', 'pt', 'es', 'fr']
export type DecktopusLanguages = 'tr' | 'en' | 'de' | 'pt' | 'es' | 'fr'

export const LANGUAGE_NAMES: {
  [key in DecktopusLanguages]: string
} = {
  en: 'English',
  tr: 'Türkçe',
  de: 'Deutsch',
  pt: 'Português',
  es: 'Español',
  fr: 'Français',
}

export const TRANSLATE_LANGUAGE_NAMES = {
  [TranslateLanguages.AM]: 'አማርኛ',
  [TranslateLanguages.AR]: 'العربية',
  [TranslateLanguages.AZ]: 'Azərbaycanca',
  [TranslateLanguages.BE]: 'Беларуская',
  [TranslateLanguages.BG]: 'Български',
  [TranslateLanguages.BI]: 'Bislama',
  [TranslateLanguages.BN]: 'বাংলা',
  [TranslateLanguages.BS]: 'Bosanski',
  [TranslateLanguages.CA]: 'Català',
  [TranslateLanguages.CS]: 'Čeština',
  [TranslateLanguages.DA]: 'Dansk',
  [TranslateLanguages.DE]: 'Deutsch',
  [TranslateLanguages.DV]: 'ދިވެހި',
  [TranslateLanguages.DZ]: 'རྫོང་ཁ',
  [TranslateLanguages.EL]: 'Ελληνικά',
  [TranslateLanguages.EN]: 'English',
  [TranslateLanguages.EO]: 'Esperanto',
  [TranslateLanguages.ES]: 'Español',
  [TranslateLanguages.ET]: 'Eesti',
  [TranslateLanguages.FA]: 'فارسی',
  [TranslateLanguages.FI]: 'Suomi',
  [TranslateLanguages.FO]: 'Føroyskt',
  [TranslateLanguages.FR]: 'Français',
  [TranslateLanguages.GD]: 'Gàidhlig',
  [TranslateLanguages.HE]: 'עברית',
  [TranslateLanguages.HI]: 'हिन्दी',
  [TranslateLanguages.HR]: 'Hrvatski',
  [TranslateLanguages.HU]: 'Magyar',
  [TranslateLanguages.HY]: 'Հայերեն',
  [TranslateLanguages.ID]: 'Bahasa Indonesia',
  [TranslateLanguages.IS]: 'Íslenska',
  [TranslateLanguages.IT]: 'Italiano',
  [TranslateLanguages.JA]: '日本語',
  [TranslateLanguages.KA]: 'ქართული',
  [TranslateLanguages.KK]: 'Қазақ',
  [TranslateLanguages.KM]: 'ភាសាខ្មែរ',
  [TranslateLanguages.KO]: '한국어',
  [TranslateLanguages.KY]: 'Кыргызча',
  [TranslateLanguages.LB]: 'Lëtzebuergesch',
  [TranslateLanguages.LT]: 'Lietuvių',
  [TranslateLanguages.LV]: 'Latviešu',
  [TranslateLanguages.MG]: 'Malagasy',
  [TranslateLanguages.MK]: 'Македонски',
  [TranslateLanguages.ML]: 'മലയാളം',
  [TranslateLanguages.MN]: 'Монгол',
  [TranslateLanguages.MS]: 'Bahasa Melayu',
  [TranslateLanguages.MT]: 'Malti',
  [TranslateLanguages.MY]: 'မြန်မာ',
  [TranslateLanguages.NA]: 'Dorerin Naoero',
  [TranslateLanguages.NE]: 'नेपाली',
  [TranslateLanguages.NL]: 'Nederlands',
  [TranslateLanguages.NO]: 'Norsk',
  [TranslateLanguages.PA]: 'ਪੰਜਾਬੀ',
  [TranslateLanguages.PL]: 'Polski',
  [TranslateLanguages.PT]: 'Português',
  [TranslateLanguages.RO]: 'Română',
  [TranslateLanguages.RU]: 'Русский',
  [TranslateLanguages.RW]: 'Ikinyar Rwanda',
  [TranslateLanguages.SD]: 'SiSwati',
  [TranslateLanguages.SE]: 'Seychellois Creole',
  [TranslateLanguages.SK]: 'Slovenčina',
  [TranslateLanguages.SL]: 'Slovenščina',
  [TranslateLanguages.SM]: 'Gagana Samoa',
  [TranslateLanguages.SO]: 'Soomaali',
  [TranslateLanguages.SQ]: 'Shqip',
  [TranslateLanguages.SR]: 'Српски',
  [TranslateLanguages.ST]: 'Sesotho',
  [TranslateLanguages.SV]: 'Svenska',
  [TranslateLanguages.SW]: 'Kiswahili',
  [TranslateLanguages.TA]: 'தமிழ்',
  [TranslateLanguages.TG]: 'Тоҷикӣ',
  [TranslateLanguages.TH]: 'ไทย',
  [TranslateLanguages.TI]: 'ትግርኛ',
  [TranslateLanguages.TK]: 'Türkmençe',
  [TranslateLanguages.TL]: 'Filipino',
  [TranslateLanguages.TO]: 'Lea faka-Tonga',
  [TranslateLanguages.TR]: 'Türkçe',
  [TranslateLanguages.TT]: 'Tuvaluan',
  [TranslateLanguages.UK]: 'Українська',
  [TranslateLanguages.UZ]: 'O‘zbek',
  [TranslateLanguages.VI]: 'Tiếng Việt',
  [TranslateLanguages.WO]: 'Faka Niue',
  [TranslateLanguages.XH]: 'IsiXhosa',
  [TranslateLanguages.ZH]: '中文',
}
