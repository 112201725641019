import {
  GradientLikeColorSchema,
  putUpdateDeckDataBody,
} from '@/types/api/requestObjects'
import { IEditInitialState } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'

export const slideBackgroundColor = (
  state: IEditInitialState,
  action: PayloadAction<GradientLikeColorSchema>,
) => {
  const currentSlideData = state.activeSlideData.data?.find(
    ({ id }) => id === state.activeSlideDataID,
  )
  if (currentSlideData) {
    currentSlideData.background = action.payload

    const slidesData: NonNullable<
      putUpdateDeckDataBody['deckData']['slides']
    >[0] = {
      id: state.activeSlideID,
      background: action.payload,
    }

    state.instantSave.data.push(slidesData)
  }
}
