import { putUpdateDeckDataBody } from '@/types/api/requestObjects'
import { IEditInitialState } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { SVGTypes } from '@/types/api/enums'

export const slideDecorSvg = (
  state: IEditInitialState,
  action: PayloadAction<SVGTypes>,
) => {
  const currentSlideData = state.activeSlideData.data?.find(
    ({ id }) => id === state.activeSlideDataID,
  )
  if (currentSlideData) {
    currentSlideData.svgType = action.payload

    const slidesData: NonNullable<
      putUpdateDeckDataBody['deckData']['slides']
    >[0] = {
      id: state.activeSlideID,
      svgType: action.payload,
    }

    state.instantSave.data.push(slidesData)
  }
}
