import { ListLayoutDirections, ListTypes } from '@/types/api/enums'

export const autoLayoutRules: Record<
  ListTypes,
  Record<ListLayoutDirections, Record<number, number>>
> = {
  [ListTypes.PLAIN]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    },
  },
  [ListTypes.BULLET]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    },
  },
  [ListTypes.NUMBER]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    },
  },
  [ListTypes.MEDIA]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
      5: 5,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 2,
      4: 2,
      5: 3,
      6: 3,
      7: 3,
      8: 3,
      9: 3,
      10: 3,
      11: 3,
      12: 3,
    },
  },
  [ListTypes.TIMELINE]: {
    [ListLayoutDirections.HORIZONTAL]: {},
    [ListLayoutDirections.VERTICAL]: {},
  },
}

export const autoLayoutRulesLeft: Record<
  ListTypes,
  Record<ListLayoutDirections, Record<number, number>>
> = {
  [ListTypes.PLAIN]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    },
  },
  [ListTypes.BULLET]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    },
  },
  [ListTypes.NUMBER]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    },
  },
  [ListTypes.MEDIA]: {
    [ListLayoutDirections.HORIZONTAL]: {
      1: 3,
      2: 3,
      3: 3,
      4: 4,
      5: 5,
    },
    [ListLayoutDirections.VERTICAL]: {
      1: 1,
      2: 2,
      3: 2,
      4: 2,
      5: 2,
      6: 2,
      7: 2,
      8: 2,
      9: 2,
      10: 2,
      11: 2,
      12: 2,
    },
  },
  [ListTypes.TIMELINE]: {
    [ListLayoutDirections.HORIZONTAL]: {},
    [ListLayoutDirections.VERTICAL]: {},
  },
}
