export const texts = {
  // Commun
  '': '',
  'common.decktopus': 'Decktopus',
  'common.decks': 'Présentations',
  'common.free': 'Gratuit',
  'common.description': 'Description',
  'common.name': 'Nom',
  'common.email': 'email',
  'common.full_name': 'nom complet',
  'common.role': 'Rôle',
  'common.dashboard': 'Tableau de bord',
  'common.analytics': 'Analyses',
  'common.responses': 'Réponses',
  'common.read_only': 'Lecture seule',
  'common.read_write': 'Lecture et écriture',
  'common.last': 'Dernier',
  'common.first': 'Premier',
  'common.title_asc': 'Titre (A-Z)',
  'common.title_desc': 'Titre (Z-A)',
  'common.recent': 'Modifié récemment',
  'common.oldest': 'Modifié le plus anciennement',
  'common.personal': 'Personnel',
  'common.date': 'Date',
  'common.auto': 'Auto',
  'common.rate': 'Taux',
  'common.text': 'Texte',
  'common.value': 'Valeur',
  'common.empty': 'Vide',
  'common.or': 'ou',
  'common.and': 'et',
  'common.email_address': 'Adresse e-mail',
  'common.password': 'Mot de passe',
  'common.organization': 'Organisation',
  'common.free_uppercased': 'GRATUIT',
  'common.design': 'Conception',
  'common.access': 'Accès',
  'common.required': 'Requis',
  'common.button': 'Bouton',
  'common.template_name': 'Nom du modèle',
  'common.language': 'Langue',
  'common.texts': 'Textes',

  'common.files': '{{count}} fichiers',
  'common.files_zero': '{{count}} fichier',
  'common.files_one': '{{count}} fichier',
  'common.files_other': '{{count}} fichiers',

  'common.slides': '{{count}} diapositives',
  'common.slides_zero': '{{count}} diapositive',
  'common.slides_one': '{{count}} diapositive',
  'common.slides_other': '{{count}} diapositives',

  'common.actions.change': 'Changer',
  'common.actions.close': 'Fermer',
  'common.actions.complete': 'Compléter',
  'common.actions.back': 'Retour',
  'common.actions.reset': 'Réinitialiser',
  'common.actions.delete': 'Supprimer',
  'common.actions.create': 'Créer',
  'common.actions.share': 'Partager',
  'common.actions.search': 'Rechercher',
  'common.actions.search_with_name': 'Rechercher {{name}}',
  'common.actions.check': 'Vérifier',
  'common.actions.connect': 'Connecter',
  'common.actions.export': 'Exporter',
  'common.actions.download': 'Télécharger',
  'common.actions.sort_by': 'Trier par',
  'common.actions.edit': 'Modifier',
  'common.actions.open': 'Ouvrir',
  'common.actions.save': 'Sauvegarder',
  'common.actions.cancel': 'Annuler',
  'common.actions.add': 'Ajouter',
  'common.actions.remove': 'Retirer',
  'common.actions.rename': 'Renommer',
  'common.actions.discard': 'Jeter',
  'common.actions.approve': 'Approuver',
  'common.actions.next': 'Suivant',
  'common.actions.move_to': 'Déplacer vers',
  'common.actions.play': 'Lire',
  'common.actions.duplicate': 'Dupliquer',
  'common.actions.activate': 'Activer',
  'common.actions.save_changes': 'Sauvegarder les changements',
  'common.actions.paste': 'Coller',
  'common.actions.upload': 'Téléverser',
  'common.actions.submit': 'Soumettre',
  'common.actions.listen_voice': 'Écouter la voix',
  'common.actions.record_voice': 'Enregistrer la voix',
  'common.actions.lets_start': 'Commençons',
  'common.actions.leave': 'Quitter',
  'common.actions.upload_picture': 'Téléverser une image',
  'common.actions.remove_picture': "Supprimer l'image",
  'common.actions.select': 'Sélectionner',
  'common.actions.preview': 'Aperçu',

  'common.informative.copied': 'Copié !',
  'common.informative.click_here': 'cliquez ici.',
  'common.informative.coming_soon': 'Bientôt disponible',
  'common.informative.fail': 'Échec',
  'common.informative.processing': 'En cours de traitement',
  'common.informative.thanks': 'Merci !',
  'common.informative.done': 'Terminé',
  'common.informative.payment_successful': 'Paiement réussi',
  'common.informative.your_deck_is_ready': 'Votre présentation est prête.',
  'common.informative.ai_loading_text':
    'Création de votre chef-d’œuvre, juste un moment...',
  'common.informative.ai_error_text':
    'Nous subissons une demande exceptionnellement élevée.',
  'common.informative.wait_on_dashboard': 'Attendez dans le tableau de bord',
  'common.informative.back_to_first': 'Retour au premier',
  'common.informative.ai_high_demand_text':
    "Votre présentation n'a pas pu être créée. Nous subissons une demande exceptionnellement élevée.",
  'common.informative.error_start_recording':
    'Erreur lors du démarrage de l’enregistrement :',
  'common.informative.error_stop_recording':
    'Erreur lors de l’arrêt de l’enregistrement :',
  'common.informative.error_changing_language':
    'Un problème est survenu lors du changement de langue, veuillez contacter notre service client',
  'common.informative.error_select_a_style':
    'Veuillez choisir ou créer un style avant de générer une image.',
  'common.informative.large_media_file':
    'Oups ! Votre fichier média dépasse 5 Mo. Veuillez sélectionner un fichier plus petit pour une meilleure expérience.',
  'common.informative.captcha_error':
    'Échec de la vérification Captcha, veuillez réessayer.',
  'common.informative.captcha_expired':
    'Le Captcha a expiré, veuillez réessayer',
  'common.informative.captcha_challange_expired':
    'Le défi Captcha a expiré, veuillez réessayer.',

  'common.directions.left': 'Gauche',
  'common.directions.middle': 'Milieu',
  'common.directions.right': 'Droite',
  'common.directions.up': 'Haut',
  'common.directions.center': 'Centre',
  'common.directions.down': 'Bas',

  'common.nth': '{{count}}e',
  'common.nth_one': '{{count}}er',
  'common.nth_two': '{{count}}e',
  'common.nth_three': '{{count}}e',
  'common.nth_other': '{{count}}e',

  'common.files.media': 'Média',
  'common.files.video': 'Vidéo',
  'common.files.image': 'Image',
  'common.files.gif': 'Gif',
  'common.files.icon': 'Icône',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',

  'common.roles.owner': 'Propriétaire',
  'common.roles.admin': 'Administrateur',
  'common.roles.member': 'Membre',
  'common.roles.pending': 'En attente',

  'common.plan_period.monthly': 'Mensuel',
  'common.plan_period.annually': 'Annuel',

  'common.plan_card.month': ' / mois',

  'common.seats': 'siège',
  'common.seats_zero': 'siège',
  'common.seats_one': 'siège',
  'common.seats_other': 'sièges',

  'common.deck.add_slide': 'Ajouter une diapositive',
  'common.deck.duplicate_slide': 'Dupliquer la diapositive',
  'common.deck.delete_slide': 'Supprimer la diapositive',
  'common.deck.undo': 'Annuler',

  // Page de connexion
  'login.welcome': 'Bienvenue !',
  'login.session_expired': 'Session expirée, veuillez vous reconnecter.',
  'login.continue_with_google': 'Continuer avec Google',
  'login.login': 'Se connecter',
  'login.logout': 'Se déconnecter',
  'login.signup': "S'inscrire",
  'login.dont_have_an_account': "Vous n'avez pas de compte ?",
  'login.forgot_password': 'Mot de passe oublié ?',
  'login.title1': '3 Mn+',
  'login.text1': 'Utilisateurs dans le monde',
  'login.title2': '64 %',
  'login.text2': 'Temps économisé en préparant des présentations avec l’IA',
  'login.title3': '5 Mn+',
  'login.text3': 'Présentations créées avec Decktopus',
  'login.title4': '<1 min',
  'login.text4': 'Temps de réponse moyen du service client',
  'login.incorrect_provider_jwt':
    'Il semble que vous vous soyez déjà inscrit avec un e-mail et un mot de passe. Veuillez vous connecter avec ces identifiants.',
  'login.incorrect_provider_google':
    'Il semble que vous vous soyez déjà inscrit avec un compte Google. Veuillez essayer de vous connecter avec Google.',
  'login.verify': 'Vérifier',
  'login.enter_six_digit_code':
    'Saisissez le code à 6 chiffres de votre application d’authentification pour terminer la connexion.',
  'login.auth_code': 'Code d’Authentification',
  // Page expirée
  'expired.get_started': 'Commencer',
  'expired.expire_link_title': 'Ce lien a expiré',
  'expired.expire_link_description':
    "Ce lien n'est plus valide. Veuillez contacter votre organisation pour plus d'informations.",

  // Page d'inscription
  'signup.terms': 'Conditions',
  'signup.privacy_policy': 'Politique de confidentialité',
  'signup.full_name': 'Nom complet',
  'signup.already_member': 'Déjà membre ?',
  'signup.create_account': 'Créer un compte',
  'signup_page_title': 'Créez votre compte et débloquez votre présentation',
  'signup.create_account_info':
    'Créer un compte signifie que vous acceptez nos <0>conditions</0> et notre <1>politique de confidentialité</1>',
  'signup.banner': 'Vous êtes à un clic de votre présentation',

  // Réinitialisation de mot de passe
  'reset.password_reset': 'Réinitialisation du mot de passe',
  'reset.back_to': 'Retour à',
  'reset.send_email': 'Envoyer un e-mail',
  'reset.resend': "Renvoyer l'e-mail",
  'reset.new_password': 'Nouveau mot de passe',
  'reset.password_set':
    'Votre mot de passe a été changé, veuillez vous connecter.',
  'reset.reset_password': 'Réinitialiser le mot de passe',
  'reset.reset_password_info':
    'Saisissez votre adresse e-mail, et nous vous enverrons les instructions pour réinitialiser votre mot de passe',
  'reset.password_reset_sent':
    'Votre e-mail de réinitialisation de mot de passe a été envoyé',
  'reset.change_your_password_info':
    'Cliquez sur le bouton pour réinitialiser votre mot de passe',

  // Commun aux pages d'authentification
  'auth.site_protection':
    "Ce site est protégé par hCaptcha et sa <0>politique de confidentialité</0> et ses <1>conditions d'utilisation</1> s'appliquent.",
  'auth.terms_of_service': "Conditions d'utilisation",

  // Page non prise en charge
  'not_supported': 'non pris en charge',
  'not_supported.title':
    "Malheureusement, le mode édition n'est pas encore pris en charge sur cet appareil. Pour une meilleure expérience, veuillez passer sur un ordinateur de bureau.",

  // Webhook
  'webhook.endpoint_url': 'Votre URL de point de terminaison',
  'webhook.event_form_responses': 'Événement : Réponses au formulaire',
  'webhook.webhook_delete_warning':
    'Êtes-vous sûr de vouloir supprimer définitivement “<strong>{{name}}</strong>” webhook ?',
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Créer un Webhook',
  'webhook.webhook_form_general_info':
    "Créez un webhook et recevez des notifications en temps réel lorsque l'un de vos decks reçoit une soumission de formulaire. Ajoutez votre URL de point de terminaison et nommez votre nouveau webhook.",
  'webhook.webhook_learn':
    "Pour en savoir plus sur l'utilisation des webhooks <0>cliquez ici.</0>",

  // Dashboard
  'dashboard.create.create_presentation': 'Créer une présentation',
  'dashboard.create.start_from_scratch': 'Commencer à partir de zéro',
  'dashboard.create.create_with_a_template': 'Créer avec un modèle',

  'dashboard.folder.folder': 'Dossier',
  'dashboard.shared_with_me': 'Partagé avec moi',
  'dashboard.folder.create_new_folder': 'Créer un nouveau dossier',
  'dashboard.folder.create_folder': 'Créer un dossier',
  'dashboard.folder.delete_title':
    "Êtes-vous sûr de vouloir supprimer définitivement “{{folder_name}}” et {{folder_id}} fichiers qu'il contient ?",
  'dashboard.folder.delete_text':
    'Les fichiers ne peuvent pas être récupérés une fois supprimés.',
  'dashboard.folder.folder_is_created': 'Dossier créé.',
  'dashboard.folder.folder_deleted': 'Dossier supprimé.',
  'dashboard.folder.type_folder_name': 'Tapez le nom de votre dossier ici.',
  'dashboard.folder.rename': 'Renommer le dossier',
  'dashboard.folder.renamed': 'Dossier renommé.',
  'dashboard.folder.name': 'nom du dossier',

  'dashboard.card.rename_deck': 'Renommer la présentation',
  'dashboard.card.transferring':
    "Votre présentation est en cours de transfert depuis l'ancienne version...",
  'dashboard.card.migration': 'Migration',
  'dashboard.card.type_deck_name': 'Tapez ici le nom de votre présentation.',
  'dashboard.card.deck_name': 'Nom de la présentation',
  'dashboard.card.deck_renamed': 'Présentation renommée.',
  'dashboard.card.deck_delete_text':
    'Êtes-vous sûr de vouloir supprimer “{{deckName}}” ?',
  'dashboard.card.remove_me_text':
    'Êtes-vous sûr de vouloir vous retirer de “{{deckName}}” ?',
  'dashboard.card.remove_me': 'Me retirer',
  'dashboard.card.file_not_recover':
    'Les fichiers ne peuvent pas être récupérés une fois supprimés.',
  'dashboard.card.deck_deleted': 'Présentation supprimée',
  'dashboard.card.deck_removed': 'Vous avez été retiré de la présentation',
  'dashboard.card.publish_template': 'Publier le modèle',
  'dashboard.card.unpublish_template': 'Dépublier le modèle',
  'dashboard.card.update_thumbnails': 'Mettre à jour les miniatures',
  'dashboard.update_thumbnails.success':
    'Les vignettes seront mises à jour dans une minute.',
  'dashboard.update_thumbnails.error':
    "Les vignettes n'ont pas pu être mises à jour.",
  'deck_not_created': "La présentation n'a pas pu être créée.",

  'dashboard.search.search_my_decks':
    'Rechercher mes présentations sur Decktopus',
  'dashboard.search.results_for': 'Résultats pour “{{searchParam}}” :',
  'dashboard.search.no_results_for':
    'Aucun résultat correspondant à <strong>{{ searchParam }}</strong>',
  'dashboard.search.try_another_search':
    "Essayez d'ajuster votre recherche pour trouver ce que vous cherchez",
  'dashboard.search.return_dashboard': 'ou retournez au tableau de bord',
  'dashboard.search.search_on_decktopus':
    'Rechercher des modèles sur Decktopus',

  'dashboard.publish.success': 'Votre présentation a été publiée avec succès',
  'dashboard.unpublish.success':
    'Votre présentation a été dépubliée avec succès',
  'dashboard.publish.error': "La présentation n'a pas pu être publiée",
  'dashboard.unpublish.error': "La présentation n'a pas pu être dépubliée",

  // Réponses aux formulaires
  'form_response.form_responses': 'Réponses au formulaire',
  'form_response.table.download_form': 'Télécharger le formulaire',
  'form_response.table.deleted_question': 'Question supprimée',
  'form_response.no_answers_yet': 'Pas encore de réponses.',
  'form_response.table.avarage_score': 'Score moyen',

  // Page d'édition
  'edit.component_fix.detail':
    'Des ajustements finaux sont en cours de réalisation sur votre présentation.',
  'edit.component_fix.info': 'Veuillez patienter',
  'edit.slide_menu.layout': 'mise en page',

  'edit.create_slide.no_custom_layout_text':
    "Vous n'avez pas encore créé de mise en page personnalisée.",
  'edit.create_slide.no_shared_layout_text':
    "Vous n'avez pas encore de mise en page partagée.",

  'edit.color_picker.select_color': 'Sélectionner la couleur',
  'edit.color_picker.solid': 'Solide',
  'edit.color_picker.gradient': 'Dégradé',
  'edit.color_picker.theme_colors': 'Couleurs du thème',
  'edit.color_picker.recent_colors': 'Couleurs récentes',

  'edit.media.modal.image.unsplash_library': 'Bibliothèque Unsplash',
  'edit.media.modal.image.title':
    'Sélectionnez une image dans la <0>bibliothèque Unsplash</0>',
  'edit.media.modal.title': 'Sélectionner {{type}} dans la bibliothèque',
  'edit.media.modal.load_more': 'Charger plus',
  'edit.media.modal.no_result': 'Aucun résultat',
  'edit.media.modal.initial_text':
    'Veuillez entrer un texte pour rechercher {{type}}',
  'edit.media.modal.ai.input': 'description',
  'edit.media.modal.select_media': 'Sélectionner {{ type }}',
  'edit.media.modal.search_media': 'Rechercher {{ type }}',

  'edit.list.submenu.placeholder': 'multi',
  'edit.list.layout.columns': 'Colonnes',
  'edit.list.layout.rows': 'Lignes',

  'edit.create_slide.copy_from_another_deck': "Copier d'une autre présentation",
  'edit.header.title': "Modifier le composant d'en-tête",

  'edit.elements.embeds': 'Intégrations',
  'edit.elements.interactive_elements': 'Éléments interactifs',
  'edit.elements.see_all': 'Voir tout',
  'edit.elements.see_less': 'Voir moins',
  'edit.elements.shapes': 'Formes',
  'edit.elements.tooltip_text':
    'Un seul formulaire peut être ajouté à une diapositive',
  'edit.elements.content_blocks': 'Blocs de contenu',

  'edit.canvas.blank': 'Vide',
  'edit.canvas.context_menu.bring_forward': 'Avancer',
  'edit.canvas.context_menu.send_backward': 'Reculer',
  'edit.canvas.context_menu.bring_to_front': 'Mettre au premier plan',
  'edit.canvas.context_menu.send_to_back': "Mettre à l'arrière-plan",

  'edit.canvas.context_menu.edit_with_ai': "Modifier avec l'IA",
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Résumer',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Corriger',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Réécrire',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Étendre',
  'edit.canvas.context_menu.edit_with_ai.other': 'Autre',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Générer',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Tapez votre prompt ici.',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Donnez un prompt à Decktopus pour améliorer votre contenu sélectionné',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'La description ne peut pas être inférieure à 3 caractères.',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    "Il n'y avait rien à corriger",

  'edit.canvas.paste.processing_image': "Traitement de l'image...",
  'edit.canvas.paste.image_pasted': 'Image téléchargée !',

  'edit.ai.create_qa_text':
    'Découvrez les Q&R spécialisées pour votre présentation. Les questions qui pourraient être posées pendant votre présentation et leurs réponses se trouvent dans cette section.',
  'edit.ai.create_qa_button': 'Créer des Q&R',
  'edit.ai.create_tip_text':
    "Les conseils pour les diapositives sont dans cette section pour vous fournir une meilleure expérience de présentation. Suggestions de structure sous forme d'accroche, brise-glace et histoire sont ici pour vous aider.",
  'edit.ai.create_tip_button': 'Créer des conseils',
  'edit.ai.create_tips_hover':
    "Créez une présentation avec l'IA pour utiliser cette fonctionnalité.",
  'edit.ai.translate_text':
    'Cette fonctionnalité traduit uniquement les éléments de texte. Les images et les éléments visuels ne seront pas traduits.',
  'edit.ai.translate_success': 'La présentation en {{language}} a été créée.',

  'edit.canvas_sidebar.start_show': 'Démarrer la présentation',
  'edit.canvas_sidebar.start_voice': 'Démarrer la voix',
  'edit.canvas_sidebar.swap_colors': 'Échanger les couleurs',

  'edit.design.customize_design': 'Personnaliser la conception',
  'edit.design.font_family': 'Famille de polices',
  'edit.design.default_fonts_delete':
    'Les polices par défaut ne peuvent pas être supprimées.',
  'edit.design.upload_custom_font': 'Télécharger une police personnalisée',
  'edit.design.upload_your_font': 'Téléchargez votre police ici',
  'edit.design.allowed_fonts_text':
    'Veuillez télécharger uniquement des polices au format .ttf et .otf.',
  'edit.design.custom_font_responsibility':
    "*Toute responsabilité liée à l'utilisation commerciale des polices téléchargées incombe à l'utilisateur. Decktopus n'assume aucune responsabilité pour l'utilisation commerciale.",
  'edit.design.upload_primary_font': 'Télécharger la police principale',
  'edit.design.upload_secondary_font': 'Télécharger la police secondaire',
  'edit.design.color_palette': 'Palette de couleurs',
  'edit.design.default_color_palettes':
    'Les palettes par défaut ne peuvent pas être supprimées.',

  'edit.preferences.background_color': "Couleur de l'arrière-plan",
  'edit.preferences.background_tooltip':
    "Sélectionnez un nouveau décor ci-dessous pour changer l'arrière-plan.",
  'edit.preferences.background_decor': "Décor d'arrière-plan",

  'edit.definitions.bar_chart.display_name': 'Graphique en barres',
  'edit.definitions.bar_chart.name1': 'Ingénierie',
  'edit.definitions.bar_chart.name2': 'Marketing',
  'edit.definitions.bar_chart.name3': 'Opérations',
  'edit.definitions.body.text': 'Nouveau corps',
  'edit.definitions.body.display_name': 'Ceci est votre corps',
  'edit.definitions.bullet_list.display_name': 'Liste à puces',
  'edit.definitions.bullet_list.item_title1': 'Élément 1',
  'edit.definitions.bullet_list.item_title2': 'Élément 2',
  'edit.definitions.bullet_list.item_title3': 'Élément 3',
  'edit.definitions.bullet_list.item_body1': "Description de l'élément 1",
  'edit.definitions.bullet_list.item_body2': "Description de l'élément 2",
  'edit.definitions.bullet_list.item_body3': "Description de l'élément 3",
  'edit.definitions.button.display_name': 'Bouton',
  'edit.definitions.button.text': 'Bouton',
  'edit.definitions.code.display_name': 'Code',
  'edit.definitions.device.display_name': 'Appareil',
  'edit.definitions.donut_chart.display_name': 'Graphique en anneau',
  'edit.definitions.donut_chart.label': 'Série 1',
  'edit.definitions.donut_chart.value1': 'Alpha',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Écho',
  'edit.definitions.form.display_name': 'Formulaire',
  'edit.definitions.form.title': 'Titre du formulaire',
  'edit.definitions.form.button_text': 'Soumettre',
  'edit.definitions.form.answers.text': 'Espace réservé pour le type de texte',
  'edit.definitions.form.answers.option':
    "Espace réservé pour le type d'option",
  'edit.definitions.form.answers.rate': 'Espace réservé pour le type de taux',
  'edit.definitions.form.buttons.next': 'Suivant',
  'edit.definitions.form.buttons.back': 'Retour',
  'edit.definitions.form.submit': 'Merci',
  'edit.definitions.form.gdpr_consent_message': 'Message RGPD',
  'edit.definitions.form.question1': 'Question 1',
  'edit.definitions.form.question2': 'Question 2',
  'edit.definitions.form.question3': 'Question 3',
  'edit.definitions.form.question2.description': 'Description',
  'edit.definitions.form.choice1': 'Choix 1',
  'edit.definitions.form.choice2': 'Choix 2',
  'edit.definitions.form.choice3': 'Choix 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Icône',
  'edit.definitions.icon_list.display_name': "Liste d'icônes",
  'edit.definitions.icon_list.title1': 'Élément 1',
  'edit.definitions.icon_list.body1': "Description de l'élément 1",
  'edit.definitions.icon_list.title2': 'Élément 2',
  'edit.definitions.icon_list.body2': "Description de l'élément 2",
  'edit.definitions.icon_list.title3': 'Élément 3',
  'edit.definitions.icon_list.body3': "Description de l'élément 3",
  'edit.definitions.image.display_name': 'Image',
  'edit.definitions.image_grid.display_name': "Grille d'images",
  'edit.definitions.image_grid.title1': 'Élément 1',
  'edit.definitions.image_grid.body1': "Description de l'élément 1",
  'edit.definitions.image_grid.title2': 'Élément 2',
  'edit.definitions.image_grid.body2': "Description de l'élément 2",
  'edit.definitions.image_grid.title3': 'Élément 3',
  'edit.definitions.image_grid.body3': "Description de l'élément 3",
  'edit.definitions.image_list.display_name': "Liste d'images",
  'edit.definitions.image_list.title1': 'Élément 1',
  'edit.definitions.image_list.body1': "Description de l'élément 1",
  'edit.definitions.image_list.title2': 'Élément 2',
  'edit.definitions.image_list.body2': "Description de l'élément 2",
  'edit.definitions.image_list.title3': 'Élément 3',
  'edit.definitions.image_list.body3': "Description de l'élément 3",
  'edit.definitions.jumbo.text': 'Nouveau grand texte',
  'edit.definitions.jumbo.display_name': 'Ceci est un grand texte',
  'edit.definitions.label.text': 'Nouvelle étiquette',
  'edit.definitions.label.display_name': 'Ceci est votre étiquette',
  'edit.definitions.line_chart.display_name': 'Graphique en ligne',
  'edit.definitions.line_chart.label1': 'Ligne 1',
  'edit.definitions.line_chart.label1.name1': '09 fév',
  'edit.definitions.line_chart.label1.name2': '10 fév',
  'edit.definitions.line_chart.label1.name3': '11 fév',
  'edit.definitions.line_chart.label1.name4': '12 fév',
  'edit.definitions.line_chart.label1.name5': '13 fév',
  'edit.definitions.line_chart.label2': 'Ligne 2',
  'edit.definitions.line_chart.label2.name1': '09 fév',
  'edit.definitions.line_chart.label2.name2': '10 fév',
  'edit.definitions.line_chart.label2.name3': '11 fév',
  'edit.definitions.line_chart.label2.name4': '12 fév',
  'edit.definitions.line_chart.label2.name5': '13 fév',
  'edit.definitions.links.display_name': 'Lien',
  'edit.definitions.links.text': 'Texte du bouton',
  'edit.definitions.logo.display_name': 'Logo',
  'edit.definitions.logo_list.display_name': 'Liste de logos',
  'edit.definitions.logo_list.title1': 'Logo 1',
  'edit.definitions.logo_list.body1': 'Description du logo 1',
  'edit.definitions.logo_list.title2': 'Logo 2',
  'edit.definitions.logo_list.body2': 'Description du logo 2',
  'edit.definitions.logo_list.title3': 'Logo 3',
  'edit.definitions.logo_list.body3': 'Description du logo 3',
  'edit.definitions.pie_chart.display_name': 'Graphique en secteurs',
  'edit.definitions.pie_chart.label': 'Série 1',
  'edit.definitions.pie_chart.value1': 'Alpha',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Écho',
  'edit.definitions.quote.display_name': 'Citation',
  'edit.definitions.quote.quote':
    "L'imagination est tout. Elle est l'aperçu des futures attractions de la vie.",
  'edit.definitions.quote.author': 'Albert Einstein',
  'edit.definitions.rating.display_name': 'Évaluation',
  'edit.definitions.rating.title': 'Titre du formulaire',
  'edit.definitions.rating.button_text': 'Soumettre',
  'edit.definitions.rating.placeholder.text':
    'Espace réservé pour le type de texte',
  'edit.definitions.rating.placeholder.options':
    "Espace réservé pour le type d'options",
  'edit.definitions.rating.placeholder.rate':
    'Espace réservé pour le type de taux',
  'edit.definitions.rating.next': 'Suivant',
  'edit.definitions.rating.back': 'Retour',
  'edit.definitions.rating.submit': 'Merci',
  'edit.definitions.rating.gdpr_consent': 'Message RGPD',
  'edit.definitions.rating.question_text': 'Évaluez votre expérience !',
  'edit.definitions.rating.question_description': 'Description',
  'edit.definitions.subtitle.text': 'Nouveau sous-titre',
  'edit.definitions.subtitle.display_name': 'Ceci est votre sous-titre',
  'edit.definitions.showcase.display_name': 'Vitrine',
  'edit.definitions.table.display_name': 'Tableau',
  'edit.definitions.table.header1': 'Catégories',
  'edit.definitions.table.header2': 'Valeurs 1',
  'edit.definitions.table.header3': 'Valeurs 2',
  'edit.definitions.table.header4': 'Valeurs 3',
  'edit.definitions.table.header5': 'Valeurs 4',
  'edit.definitions.table.row1.value1': 'Catégories 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Catégories 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Catégories 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Catégories 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Chronologie',
  'edit.definitions.timeline.item1': 'Élément 1',
  'edit.definitions.timeline.description1': "Description de l'élément 1",
  'edit.definitions.timeline.item2': 'Élément 2',
  'edit.definitions.timeline.description2': "Description de l'élément 2",
  'edit.definitions.timeline.item3': 'Élément 3',
  'edit.definitions.timeline.description3': "Description de l'élément 3",
  'edit.definitions.title.text': 'Nouveau titre',
  'edit.definitions.title.display_name': 'Ceci est votre titre',
  'edit.definitions.video.display_name': 'Vidéo',
  'edit.definitions.website.display_name': 'Site Web',

  'edit.background.options': "Options d'arrière-plan",

  'edit.color_mapping.swap_colors': 'Échanger les couleurs',
  'edit.color_mapping.restore': 'Restaurer les couleurs par défaut',
  'edit.color_mapping.restore_short': 'Restaurer les couleurs',
  'edit.color_mapping.texts': 'Textes',
  'edit.color_mapping.texts.item1': 'Grand texte',
  'edit.color_mapping.texts.item2': 'Titre',
  'edit.color_mapping.texts.item3': 'Sous-titre',
  'edit.color_mapping.texts.item4': 'Corps',
  'edit.color_mapping.texts.item5': 'Étiquette',
  'edit.color_mapping.background': 'Arrière-plan',
  'edit.color_mapping.quote': 'Citation',
  'edit.color_mapping.quote.item1': 'Citation',
  'edit.color_mapping.quote.item2': 'Auteur',
  'edit.color_mapping.icons': 'Icônes',
  'edit.color_mapping.icons.item1': 'Icône',
  'edit.color_mapping.icons.item2': "Remplissage de l'icône",
  'edit.color_mapping.lists': 'Listes',
  'edit.color_mapping.lists.item1': 'Puce de liste',
  'edit.color_mapping.lists.item2': 'Titre de la liste',
  'edit.color_mapping.lists.item3': 'Corps de la liste',
  'edit.color_mapping.table': 'Tableau',
  'edit.color_mapping.table.item1': 'Contenu',
  'edit.color_mapping.table.item2': 'Surlignage',
  'edit.color_mapping.buttons': 'Boutons',
  'edit.color_mapping.buttons.item1': 'Texte',
  'edit.color_mapping.buttons.item2': 'Remplissage du bouton',
  'edit.color_mapping.buttons.item3': 'Bordure',
  'edit.color_mapping.timeline': 'Chronologie',
  'edit.color_mapping.timeline.item1': 'Barre de chronologie',
  'edit.color_mapping.timeline.item2': 'Point de chronologie',
  'edit.color_mapping.form': 'Formulaire',
  'edit.color_mapping.form.item1': 'Question',
  'edit.color_mapping.form.item2': 'Texte du bouton',
  'edit.color_mapping.form.item3': 'Remplissage du bouton',
  'edit.color_mapping.form.item4': 'Icône',
  'edit.color_mapping.chart': 'Graphique',
  'edit.color_mapping.chart.items': 'Couleur',
  'edit.color_mapping.chart.background': 'Arrière-plan du graphique',
  'edit.color_mapping.text_highlight': 'Surlignage de texte',
  'edit.color_mapping.text_highlight.item1': 'Surlignage',
  'edit.color_mapping.text_highlight.item2': 'Arrière-plan du surlignage',
  'edit.color_mapping.shape': 'Forme',
  'edit.color_mapping.shape.item1': 'Remplissage de la forme',
  'edit.color_mapping.decoration': 'Décorations',
  'edit.color_mapping.decoration.items': 'Décoration',

  // Page de classification utilisateur
  'user_classify.business': 'Affaires',
  'user_classify.continue_with_decktopus': 'Continuer avec Decktopus',
  'user_classify.customer_service': 'Service client',
  'user_classify.education': 'Éducation',
  'user_classify.educator': 'Éducateur',
  'user_classify.engineering': 'Ingénierie',
  'user_classify.finance': 'Finance',
  'user_classify.friends': 'Amis',
  'user_classify.google_search': 'Recherche Google',
  'user_classify.hr': 'Ressources humaines',
  'user_classify.legal': 'Légal',
  'user_classify.management': 'Gestion',
  'user_classify.other': 'Autre',
  'user_classify.product': 'Produit',
  'user_classify.sales_marketing': 'Ventes Marketing',
  'user_classify.see_other_options': "Voir d'autres options",
  'user_classify.social_media': 'Réseaux sociaux',
  'user_classify.student': 'Étudiant',
  'user_classify.tell_us_about_yourself': 'Parlez-nous de vous',
  'user_classify.classify_question': 'Dans quel but utilisez-vous Decktopus ?',
  'user_classify.business_question': 'Quel type de travail faites-vous ?',
  'user_classify.how_to_hear_about_us':
    'Comment avez-vous entendu parler de nous ?',

  // Modal de partage
  'share_modal.embed.code': "Obtenir le code d'intégration",
  'share_modal.share_link': 'Partager le lien',
  'share_modal.get_share_link': 'Obtenir le lien de partage',
  'share_modal.make_the_share_link_public': 'Rendre le lien de partage public',
  'share_modal.select_from_list': 'Sélectionner dans la liste des utilisateurs',

  'share_modal.share_link_updated': 'Lien de partage mis à jour avec succès.',
  'share_modal.invite_people_to_collaborate':
    'Inviter des personnes à collaborer',
  'share_modal.invitations_sent': 'Invitations envoyées',
  'share_modal.organization_deck_permission': "Permission de l'organisation",
  'share_modal.valid_email_message':
    'Veuillez entrer une adresse e-mail valide et appuyer sur Entrée',
  'share_modal.add_collaborators': 'Ajouter un collaborateur',
  'share_modal.not_collaborating_message':
    'Vous ne collaborez encore avec personne.',
  'share_modal.collaborators': 'Collaborateurs',
  'share_modal.can_edit': 'Peut modifier',
  'share_modal.can_view': 'Peut voir',
  'share_modal.embed_code': "Code d'intégration",
  'share_modal.collaborated_users_updated': 'Collaborateurs mis à jour',
  'share_modal.save_complete': 'Modifications enregistrées',
  'share_modal.pdf_export': 'Exporter en PDF',
  'share_modal.pptx_export': 'Exporter en PPTX',
  'share_modal.latest_exports': 'Mes dernières exportations',

  'share_modal.custom_domain.status': 'Statut',
  'share_modal.custom_domain.host': 'Hôte',
  'share_modal.custom_domain.completed': 'COMPLÉTÉ',
  'share_modal.custom_domain.pending': 'EN ATTENTE',
  'share_modal.custom_domain.record': 'Enregistrement',
  'share_modal.custom_domain.data': 'Données',
  'share_modal.custom_domain.cname': 'CNAME',
  'share_modal.custom_domain.share_link_placeholder':
    'ex: https://document.votredomaine.com',
  'share_modal.custom_domain.domain_successfully':
    'Domaine enregistré avec succès.',
  'share_modal.custom_domain.domain_deleted': 'Domaine supprimé avec succès.',
  'share_modal.custom_domain.domain_failed':
    "Le domaine n'a pas encore été vérifié. Cela peut prendre jusqu'à 72 heures selon votre serveur de domaine",
  'share_modal.custom_domain.dns_record':
    'Pour connecter votre domaine personnalisé à votre Decktopus, vous devez mettre à jour vos enregistrements DNS selon le tableau ci-dessous.',
  'share_modal.custom_domain.add_your_subdomain':
    'Ajoutez votre sous-domaine :',
  'share_modal.custom_domain.info': 'Info',
  'share_modal.custom_domain.verified': 'Vérifié',
  'share_modal.custom_domain.unverified': 'Non vérifié',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Connectez votre domaine personnalisé',
  // Fin de la modal de partage

  // Page d'administration
  'admin.menu.user_info': 'Informations utilisateur',
  'admin.menu.themes': 'Themes',
  'admin.menu.back_to_search': 'Retour à la recherche',
  'admin.search.welcome_support_panel':
    'Bienvenue dans le <strong>panneau de support</strong>',
  'admin.search.you_can_search_by':
    'Vous pouvez rechercher par e-mail, ID de présentation ou clé de partage pour lister ce dont vous avez besoin.',
  'admin.search.share_key': 'Clé de partage',
  'admin.search.deck_id': 'ID de la présentation',
  'admin.search.user_not_found': 'Utilisateur non trouvé.',
  'admin.table.e_mail': 'E-mail',
  'admin.table.delete_user': "Supprimer l'utilisateur",
  'admin.table.created_date': 'Date de création',
  'admin.confirm.cannot_undo_delete_user':
    'Cette action est irréversible. Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
  'admin.table.actions': 'Actions',
  'admin.action_menu.login_as_user': "Se connecter en tant qu'utilisateur",
  'admin.action_menu.gift': 'Offre',
  'admin.action_menu.reset_password': 'Réinitialiser le mot de passe',
  'admin.action_menu.change_email': 'Changer l’e-mail',
  'admin.action_menu.update_seat_size': 'Mettre à jour le nombre de sièges',
  'admin.update_email.current_email': 'E-mail actuel',
  'admin.update_email.new_email': 'Nouvel e-mail',
  'admin.update_email.provider': 'Fournisseur',
  'admin.update_email.confirm': 'Confirmer',
  'admin.update_email.success': 'E-mail mis à jour avec succès.',
  'admin.reset_password.are_you_sure':
    'Êtes-vous sûr de vouloir réinitialiser le mot de passe ?',
  'admin.reset_password.link_sent':
    'Un lien de réinitialisation du mot de passe a été envoyé à l’e-mail de l’utilisateur.',
  'admin.reset_password.link_copied': 'Lien copié dans le presse-papiers',

  'admin.gift_user_success': 'Crédit offert avec succès.',
  'admin.gift_credit_amount': 'Montant du crédit',
  'admin.gift_confirm_amount': 'Confirmer le montant du crédit',
  'admin.dashboard.header.back_to_support': 'Retour au panneau de support',
  'admin.dashboard.header.logged_in_as_user':
    "Vous êtes connecté en tant qu'utilisateur.",
  // Fin de la page d'administration

  // Éléments du menu du composant tableau
  'table.add_row_above': 'Ajouter une ligne au-dessus',
  'table.add_row_below': 'Ajouter une ligne en dessous',
  'table.add_column_left': 'Ajouter une colonne à gauche',
  'table.add_column_right': 'Ajouter une colonne à droite',
  'table.delete_row': 'Supprimer la ligne',
  'table.delete_column': 'Supprimer la colonne',
  // Fin des éléments du menu du composant tableau

  // Modal de confirmation de paiement
  'payment_confirmation.title': 'Mettez à jour votre plan vers {{ planName }}',
  'payment_confirmation.subtitle':
    'Vous êtes à un pas de profiter des fonctionnalités exclusives de Decktopus',
  'payment_confirmation.plan': 'Plan :',
  'payment_confirmation.payment_method': 'Méthode de paiement :',
  'payment_confirmation.card_number': 'Numéro de carte :',
  'payment_confirmation.card_date': 'Date de la carte :',
  'payment_confirmation.total': 'Total :',
  'payment_confirmation.contact_us':
    "Si vous avez des questions ou avez besoin d'assistance, n'hésitez pas à <0>nous contacter.</0>",
  'payment_confirmation.cancel': 'Annuler le paiement',
  'payment_confirmation.confirm': 'Confirmer et payer',
  'payment_confirmation.seat': 'Siège',
  'payment_confirmation.lite': 'Lite IA',
  'payment_confirmation.pro': 'Pro IA',
  'payment_confirmation.business': 'Business IA',
  'payment_confirmation.remaining_line':
    'Temps restant sur {{ planName }} après le {{ date }}',
  'payment_confirmation.unused_line':
    'Temps non utilisé sur {{ planName }} après le {{ date }}',
  'payment_confirmation.total_tooltip':
    'Le paiement sera au prorata en fonction des jours restants dans votre cycle de facturation actuel.',
  // Fin de la modal de confirmation de paiement

  'common.history': 'Historique',
  'common.percent': '{{value}}%',
  'common.actions.skip': 'Passer',
  'common.actions.set_as_default': 'Définir par défaut',

  'common.files.pdf_standard': 'PDF Standard',
  'common.files.pdf_compressed': 'PDF Compressé',
  'common.files.ppt_standard': 'PPT Standard',
  'common.files.ppt_compressed': 'PPT Compressé',
  'main_panel.admin': 'Admin',
  'main_panel.organization': 'Organisation',
  'main_panel.profile_settings': 'Paramètres du profil',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Parrainer et gagner',
  'main_panel.subscription': 'Abonnement',
  'main_panel.onboarding': 'Intégration',
  'main_panel.logout': 'Déconnexion',
  'profile.change_account_information': 'Modifier les informations du compte',
  'profile.edit_your_name': 'Modifier votre nom',
  'profile.change_your_password': 'Changer votre mot de passe',
  'profile.update.save_changes': 'Enregistrer les modifications',
  'profile.update.image_upload_success':
    'Votre photo de profil a été modifiée.',
  'profile.update.name_change_success': 'Votre nom a été modifié.',
  'profile.update.name_min_char_error':
    'Le nom ne peut pas être inférieur à 3 caractères.',
  'profile.update.image_upload_error': "{{fileType}} n'est pas pris en charge.",
  'profile.verification_success': 'Vous avez vérifié votre compte avec succès.',
  'profile.enable_2fa': 'Activer l’authentification à deux facteurs',
  'profile.use_an_authenticator':
    'Utilisez une application d’authentification comme Google Authenticator, Microsoft Authenticator, Authy ou iPassword pour scanner ce code QR. Elle générera un code à 6 chiffres que vous devrez entrer ci-dessous.',
  'profile.keep_your_backup':
    'Gardez vos codes de sauvegarde en lieu sûr afin de pouvoir accéder à votre compte en cas de besoin.',
  'profile.enter_auth_code': 'Entrer le code d’authentification',
  'profile.generate_new_codes':
    'Générez un nouvel ensemble de codes d’authentification si vous devez remplacer les actuels.',
  'profile.get_new_backup_codes': 'Générer de nouveaux codes de sauvegarde',
  'profile.continue': 'Continuer',
  'verify.verify_your_email': 'Vérifiez votre e-mail',
  'verify.verify_your_account': 'Vérifiez votre compte',
  'verify.verify_your_email_info':
    'Cliquez sur le bouton pour vérifier votre compte.',
  'verify.verification_email_sent': 'E-mail de vérification envoyé.',
  'verify.verification_enter_code': 'Entrez votre code',
  'verify.verification_didnt_reveive_email':
    "Vous n'avez pas reçu l'e-mail ? <0>lien</0>",
  'verify.verification_click_to_resend': 'Cliquez pour renvoyer',
  'verify.verification_email_sent_info':
    'Nous avons envoyé un code de vérification à votre e-mail.',
  'validation.min_char':
    '{{name, capitalFirstLetter}} ne peut pas être inférieur à {{value}} caractères.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} ne peut pas dépasser {{value}} caractères.',
  'validation.error.required':
    '{{name, capitalFirstLetter}} ne peut pas être vide',
  'validation.error.email': 'Adresse e-mail invalide',
  'validation.error.url': 'Adresse URL invalide',
  'validation.error.video_url':
    'URL vidéo invalide. Veuillez écrire un lien YouTube ou Vimeo.',
  'validation.validation_user_exists': 'Cet utilisateur existe déjà',
  'layout.save': 'Enregistrer la mise en page',
  'layout.save_new': 'Enregistrer comme nouvelle mise en page',
  'layout.name': 'Nom de la mise en page',
  'layout.blank': 'Vide',
  'workspace.personal_hub': 'Espace personnel',
  'init.category_select_info':
    'Choisissez une catégorie de design qui correspond à votre concept de présentation',
  'init.see_templates': 'Voir les modèles',
  'init.start_from_scratch': 'Commencer de zéro',
  'init.use_this_template': 'Utiliser ce modèle',
  'init.category0': 'Tout',
  'init.category1': 'Entreprise',
  'init.category2': 'Proposition',
  'init.category3': 'Cours en ligne',
  'init.category4': 'Éducation',
  'init.category5': 'Communauté',
  'init.category6': 'Nutrition',
  'init.category7': 'Résumé exécutif',
  'init.category8': 'Étude de cas',
  'init.category9': 'Pitch de startup',
  'create_with_ai.generate_presentation': 'Générer une présentation',
  'create_with_ai.create_with_ai': "Créer avec l'IA",
  'create_with_ai.prompt_placeholder':
    'Écrivez le sujet de votre présentation ici !',
  'create_with_ai.audience_placeholder': 'Écrivez votre public',
  'create_with_ai.aim_placeholder': 'Écrivez votre objectif',
  'create_with_ai.prompt_title': 'De quoi parle votre présentation ?',
  'create_with_ai.audience_title': 'Qui est votre public ?',
  'create_with_ai.aim_title':
    'Quel est votre objectif pour cette présentation ?',
  'create_with_ai.outline_title':
    'Vous pouvez modifier le plan ou continuer sans le changer.',
  'create_with_ai.theme_title': 'Quel modèle souhaitez-vous utiliser ?',
  'create_with_ai.theme_subtitle':
    'Ne vous inquiétez pas ! Vous pouvez le changer à tout moment.',
  'create_with_ai.ai_subtitle':
    'Inspirez Deckto avec un titre. Essayez de taper le sujet de votre présentation comme : « Histoire de Rome »',
  'create_with_ai.see_examples': 'Voir des exemples',
  'create_with_ai.hide_examples': 'Masquer les exemples',
  'create_with_ai.import_pdf': 'Importez votre PDF',
  'create_with_ai.pdf_subtitle':
    'Deckto transformera votre document en une présentation avec des diapositives éditées.',
  'create_with_ai.category_title':
    'Choisissez une catégorie pour votre présentation.',
  'create_with_ai.category.option1': 'Pitch de startup',
  'create_with_ai.category.option2': 'Proposition commerciale',
  'create_with_ai.category.option3': 'Présentation de vente',
  'create_with_ai.category.option4': 'Présentation de rapport',
  'create_with_ai.category.option5': 'Éducatif',
  'create_with_ai.category.option6': 'Autre',
  'create_with_ai.strength.low':
    "Quelques mots de plus peuvent débloquer un monde d'idées plus profondes !",
  'create_with_ai.strength.medium':
    "Vous y êtes presque — ajoutez un peu plus pour révéler l'image complète !",
  'create_with_ai.strength.high':
    'Fantastique ! Plus vous fournissez de contexte, plus les résultats sont précis.',
  'create_with_ai.strength.done':
    "Génial ! N'hésitez pas à ajouter plus si vous le souhaitez !",
  'create_with_ai.strength.tooltip':
    'Des instructions détaillées mènent à des idées plus précises et pertinentes — donnez-nous plus de contexte pour obtenir les meilleurs résultats !',
  'file_import.import_file_title': '(Facultatif) Téléchargez vos documents',
  'file_import.import_file_info':
    'Téléchargez tous les documents à inclure ou à référencer dans votre présentation.',
  'file_import.use_file_format':
    'Impossible de télécharger le fichier {{fileName}}. Utilisez un fichier dans les formats suivants : docx, json, md, pdf, pptx, txt',
  'file_import.maximum_files':
    'Vous pouvez télécharger un maximum de 5 fichiers',
  'file_import.maximum':
    'Vous avez atteint la limite maximale de téléchargement de fichiers !',
  'file_import.maximum_info':
    'Veuillez en supprimer un pour en télécharger un autre.',
  'file_import.drag_and_drop': 'Glissez vos fichiers ou parcourez',
  'file_import.size_error':
    'Le fichier {{fileName}} est trop volumineux. Essayez-en un plus petit.',
  'file_import.already_uploaded':
    'Le fichier {{fileName}} est déjà téléchargé. Essayez-en un autre.',
  'file_import.file_state.ready': 'Prêt',
  'file_import.file_state.error': 'Erreur',
  'file_import.file_state.processing': 'Traitement en cours',
  'file_import.file_state.ready_info': 'Fichier téléchargé avec succès.',
  'file_import.file_state.error_info':
    "Désolé, une erreur inattendue s'est produite. Veuillez essayer de télécharger un autre fichier.",
  'file_import.file_state.processing_info':
    'Le fichier est en cours de traitement, veuillez patienter.',
  'organization.organization_profile_update.image_upload_success':
    "L'image de l'organisation a été modifiée.",
  'organization.organization_profile_update.name_and_description_success':
    "Les détails de l'organisation ont été modifiés.",
  'organization.organization_details': "Détails de l'organisation",
  'organization.remaining_seats': 'Sièges restants',
  'organization.manage_seats': 'Gérer les sièges',
  'organization.used_seats':
    '{{occupiedSeats}} sur {{totalSeats}} sièges utilisés',
  'organization.adjust_seat_number': 'Ajuster le nombre de sièges',
  'organization.current_users': 'Utilisateurs actuels ({{count}}) Coût / 12',
  'organization.added_users': 'Utilisateurs ajoutés ({{count}}) Coût / 12',
  'organization.updated_annual_total': 'Total {{period}} mis à jour Coût / 12',
  'organization.first_charge':
    'Le premier prélèvement sera calculé au prorata.',
  'organization.first_charge_tooltip':
    'Le premier prélèvement des sièges supplémentaires sera calculé en fonction du nombre de jours restants dans votre cycle de facturation actuel.',
  'organization.reset_invitation': "Renvoyer l'invitation",
  'organization.cancel_invitation': "Annuler l'invitation",
  'organization.member_settings': 'Paramètres des membres',
  'organization.add_members': 'Ajouter des membres',
  'organization.add_members_title': 'Ajouter des membres à votre organisation',
  'organization.not_enough_seats':
    "Vous n'avez pas assez de sièges pour ajouter plus de membres. Ajustez le nombre de sièges depuis la page de l'organisation pour faire de la place.",
  'organization.add_member_information':
    'Vous pouvez toujours inviter des membres dans votre organisation depuis la barre de droite dans la page « Mon Organisation ».',
  'organization.organization-details': "Détails de l'organisation",
  'organization.leave_team_title': "Quitter l'équipe",
  'organization.leave_team_button': "Quitter l'organisation",
  'organization.leave_team_dialog_text':
    'En quittant une organisation, vous ne pourrez plus accéder aux présentations collaboratives.',
  'organization.leave_team_text':
    "Si vous quittez l'équipe, vous ne pourrez plus accéder aux contenus de l'organisation et aux fonctionnalités professionnelles.",
  'organization.organization_name_label': "Nom de l'organisation",
  'organization.organization_desc_label': "Description de l'organisation",
  'organization.organization_team_logo': "Logo de l'équipe",
  'organization.delete_member': 'Supprimer le membre',
  'organization.delete_member_title':
    'Êtes-vous sûr de vouloir supprimer définitivement « {{name}} » ?',
  'organization.delete_member_text':
    "« {{name}} » ne pourra plus voir toutes les présentations et les détails de l'organisation",
  'organization.resend_info':
    "{{email}} n'a pas encore accepté votre invitation. Vous allez renvoyer l'invitation en guise de rappel.",
  'organization.organization_suspended':
    'Cette organisation est suspendue. Veuillez mettre à jour votre mode de paiement pour résoudre le problème.',
  'organization.organization_suspended_member':
    "Cette organisation est suspendue. Veuillez contacter l'administrateur de l'organisation pour obtenir de l'aide.",
  'refer.invite_friend': 'Invitez votre ami !',
  'refer.invite_friend_text':
    'Vous et votre ami recevez chacun <strong>30</strong> crédits.',
  'refer.referred': 'Nous avons envoyé un e-mail à votre ami',
  'analytics.no_views_yet': 'Pas encore de vues',
  'analytics.share_this_deck': 'Partagez cette présentation.',
  'analytics.total_views': '{{view}} vues totales',
  'analytics.total_views_one_slide':
    '{{view}} vues pour la toute première diapositive uniquement',
  'analytics.chart_description':
    'Ce graphique représente la performance de vue de vos présentations',
  'analytics.last_time_open': 'Dernière ouverture',
  'analytics.views': '{{view}} vues',
  'analytics.openedTimes': 'Ouvert {{view}} fois',
  'subscriptions.upgrade': 'Mettre à niveau',
  'subscriptions.show_more': 'Afficher plus',
  'subscriptions.show_less': 'Afficher moins',
  'subscriptions.subscription': 'Abonnement',
  'subscriptions.current_plan': 'Plan actuel',
  'subscriptions.credit_information': 'Informations sur les crédits',
  'subscriptions.ai_credits': 'Crédits IA',
  'subscriptions.choose_plan': 'Choisir un plan',
  'subscriptions.plan_renew_info': 'Votre plan se renouvelle le {{date}}',
  'subscriptions.plan_cancel_info': 'Votre plan sera annulé le {{date}}',
  'subscriptions.plan_renew_info_free':
    'Pour accéder aux fonctionnalités IA incroyables, mettez à niveau votre compte',

  'subscriptions.credit_renew_info':
    'Vos crédits seront renouvelés le {{date}}',
  'subscriptions.credit_cancel_info':
    "Vos crédits peuvent être utilisés jusqu'au {{date}}",
  'subscriptions.credit_history': 'Historique des crédits',
  'subscriptions.credit_history_info':
    "L'historique des crédits reflète les dépenses jusqu'au début de la période d'abonnement",
  'subscriptions.subscription_settings': "Paramètres d'abonnement",
  'subscriptions.change_payment_method': 'Changer le mode de paiement',
  'subscriptions.update_billing_info':
    'Mettre à jour les informations de facturation',
  'subscriptions.invoices': 'Factures',
  'subscriptions.cancel_subscription': "Annuler l'abonnement",
  'subscriptions.renew_subscription': "Renouveler l'abonnement",
  'subscriptions.cancel_promo_modal.title': '75% DE RÉDUCTION PENDANT 3 MOIS',
  'subscriptions.cancel_promo_modal.subtitle':
    "Nous avons remarqué que vous êtes sur le point d'annuler votre abonnement",
  'subscriptions.cancel_promo_modal.description':
    "En tant qu'utilisateur apprécié, nous aimerions vous offrir une réduction exclusive de <strong>{{discount}}</strong> sur vos {{period}} prochains d'abonnement si vous choisissez de rester avec Decktopus.",
  'subscriptions.cancel_promo_modal.cancel': 'Annuler mon abonnement',
  'subscriptions.cancel_promo_modal.apply': 'Appliquer la réduction',
  'subscriptions.cancel_promo_modal.success':
    'Votre réduction a été appliquée avec succès',
  'subscriptions.cancel_promo_modal.discount_monthly': '75% de réduction',
  'subscriptions.cancel_promo_modal.discount_annually': '25% de réduction',
  'subscriptions.cancel_promo_modal.period_monthly': '3 mois',
  'subscriptions.cancel_promo_modal.period_annually': 'année',
  'subscriptions.cancel_reason_dialog.title':
    "Qu'aurions-nous pu faire différemment pour améliorer votre expérience ?",
  'subscriptions.cancel_reason_dialog.subtitle': '50 caractères minimum',
  'subscriptions.cancel_reason_dialog.submit': 'Envoyer le feedback',
  'subscriptions.cancel_reason_dialog.input_placeholder':
    'Tapez votre expérience ici.',
  'subscriptions.cancel_reason_dialog.input_label': 'Feedback',
  'subscriptions.cancel_reason_dialog.footer_text':
    "Votre feedback est incroyablement précieux pour nous. Il nous aide à nous améliorer et à créer une meilleure expérience pour vous et les autres à l'avenir. Même les plus petites idées peuvent faire une grande différence. Merci de partager !",
  'subscriptions.plan.powerup_info': 'Je veux booster mon {{plan_name}}',
  'subscriptions.plan.choose_lite': 'Choisir Lite',
  'subscriptions.plan.choose_pro': 'Choisir Pro',
  'subscriptions.plan.choose_business': 'Choisir Business',
  'subscriptions.plan.discount_info': "Économisez jusqu'à 40%",
  'subscriptions.subs_state.pending': 'En attente',
  'subscriptions.subs_state.action_required': 'Action requise',
  'subscriptions.subs_state.failed': 'Échoué',
  'subscriptions.subs_state.canceled': 'Annulé',
  'subscriptions.subs_state.active': 'Actif',
  'subscriptions.plan.popular': 'Populaire',
  'subscriptions.billed_annually': ' facturé annuellement',
  'subscriptions.update_payment': 'Mettre à jour le mode de paiement',
  'subscriptions.unlimited': 'Illimité',
  'upgrade_modal.upgrade_to_pro_or_business': 'Passer à Pro ou Business',
  'upgrade_modal.upgrade_to_business': 'Passer à Business',
  'upgrade_modal.upgrade_to_business_text':
    'Améliorez vos présentations et augmentez votre ROI sur les présentations.',
  'subscriptions.plan.cancel_anytime': 'Annulez à tout moment',
  'upgrade_modal.refill_your_credits': 'Recharger vos crédits',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'Gardez votre élan. Mettez à niveau votre plan pour continuer à travailler.',
  'upgrade_modal.upgrade_modal_subtitle':
    'Utilisez Decktopus comme votre copilote IA pour les présentations.',
  'upgrade_modal.choose_plan': 'Choisir un plan',
  'edit.canvas.context_menu.send_to_background': 'Envoyer en arrière-plan',
  'edit.properties.list.submenu.title': "Propriétés du texte et de l'icône",
  'edit.properties.list.new_list.add_new': 'Ajouter nouveau',
  'edit.properties.quote.alignment': 'Alignement de la citation',
  'edit.properties.quote.placeholder': 'Citation',
  'edit.properties.quote.author': 'Auteur',
  'edit.properties.quote.size.label.text': 'Texte de la citation',
  'edit.properties.quote.size.label.author': 'Auteur',
  'edit.properties.media.image_props': "Propriétés de l'image",
  'edit.properties.media.image_opacity': 'Opacité',
  'edit.properties.media.corner_radius': 'Rayon des coins',
  'edit.properties.icon.styles': 'Styles',
  'edit.properties.icon.icon': 'Icône',
  'edit.properties.icon.background': 'Arrière-plan',
  'edit.properties.data': 'Modifier les données',
  'edit.properties.table': 'Modifier le tableau',
  'edit.properties.show_header_column': "Afficher la colonne d'en-tête",
  'edit.properties.show_header_row': "Afficher la ligne d'en-tête",
  'edit.properties.form.submenu.title': 'Propriétés du formulaire',
  'edit.properties.form.button_text': 'texte du bouton',
  'edit.properties.form.placeholder_text': "Texte de l'espace réservé",
  'edit.properties.form.question_text': 'texte de la question',
  'edit.properties.form.choice': 'choix',
  'edit.properties.form.header': 'Ajouter ici le type de formulaire',
  'edit.properties.form.header.button_text': 'Texte du bouton',
  'edit.properties.form.option_list': "Liste d'options",
  'edit.properties.form.check_list': 'Liste de vérification',
  'edit.properties.form.free_text': 'Texte libre',
  'edit.properties.form.submitting_required_tooltip':
    'Si cette option est activée, le spectateur ne peut pas voir les diapositives suivantes avant de soumettre ce formulaire',
  'edit.properties.button_props.paste_url': 'Collez une URL de site web.',
  'edit.properties.button_props.outlined': 'Contour',
  'edit.properties.chart_props.show_grid': 'Afficher les lignes de grille',
  'edit.properties.chart_props.show_legends': 'Afficher les légendes',
  'edit.properties.chart_props.show_background': "Afficher l'arrière-plan",
  'edit.properties.chart_props.settings': 'Paramètres',
  'edit.properties.chart_props.opacity': "Opacité de l'arrière-plan",
  'edit.properties.chart_props.background_color': "Couleur d'arrière-plan",
  'edit.properties.chart_title.placeholder': 'Titre du graphique',
  'edit.properties.edit_data.error': 'pas un nombre',
  'edit.properties.x_axis.show_x_axis': "Afficher l'axe X",
  'edit.properties.x_axis.x_axis': 'Axe X',
  'edit.properties.y_axis.show_y_axis': "Afficher l'axe Y",
  'edit.properties.y_axis.y_axis': 'Axe Y',
  'edit.properties.form_choice.choice': 'Choix ',
  'edit.properties.form_props.question': 'Question ',
  'edit.properties.form_props.description': 'Description',
  'edit.properties.form_props.choice_1': 'Choix 1',
  'edit.properties.form_props.choice_2': 'Choix 2',
  'edit.properties.form_props.choice_3': 'Choix 3',
  'edit.properties.form_question_option.add_choice': 'Ajouter un choix',
  'edit.properties.form_question_rate.choose_icon': "Choisir le style d'icône",
  'edit.properties.device.header': 'Lien',
  'edit.properties.device.change_device': "Changer d'appareil",
  'edit.properties.device.laptop': 'Ordinateur portable',
  'edit.properties.device.tablet': 'Tablette',
  'edit.properties.device.phone': 'Téléphone',
  'edit.properties.chart.chart_data': 'Type de graphique :',
  'edit.properties.shape.placeholder': 'Forme',
  'edit.properties.shape.distort': 'Déformer',
  'edit.properties.ai_image.create': "Créer avec l'IA",
  'edit.properties.ai_image.generate_with_ai': "Générer une image avec l'IA",
  'edit.properties.ai_image.describe': "Décrivez votre demande à l'IA",
  'edit.properties.ai_image.generate': 'Générer',
  'edit.properties.table.placeholder': 'Paramètres',
  'edit.properties.text_size.placeholder': 'Multiple',
  'edit.properties.text_size.big_text': 'Grand texte',
  'edit.properties.text_size.title': 'Titre',
  'edit.properties.text_size.subtitle': 'Sous-titre',
  'edit.properties.text_size.body': 'Corps',
  'edit.properties.text_size.label': 'Étiquette',
  'edit.properties.text_props.header': 'Texte',
  'edit.properties.text_props.reset': 'Réinitialiser',
  'edit.properties.text_props.fill': 'Remplir',
  'edit.properties.text_props.opacity': 'Opacité',
  'edit.properties.video_props.video': 'Vidéo',
  'edit.properties.video_props.paste': 'Collez un lien vidéo Youtube ou Vimeo.',
  'edit.properties.website_props.website': 'Site web',
  'edit.properties.website_props.paste': 'Collez une URL de site web.',
  'edit.properties.alignment.position': 'Position',
  'edit.properties.list.list_type': 'Type de liste',
  'edit.properties.list.list_direction': 'Direction',
  'edit.properties.list.media_position': 'Position du média',
  'edit.properties.list.layout': 'Mise en page',
  'edit.properties.list.colors': 'Couleurs',
  'edit.properties.list.font_size': 'Taille de la police',
  'edit.properties.list.size': 'Taille',
  'edit.properties.list.icon_frame': "Cadre de l'icône",
  'edit.properties.list.color.icon': 'Icône',
  'edit.properties.list.color.icon_frame': 'Cadre',
  'edit.properties.list.color.number': 'Nombre',
  'edit.properties.list.color.bullet': 'Puce',
  'edit.properties.list.font_title': 'Titre',
  'edit.properties.list.color.title': 'Titre',
  'edit.properties.list.color.body': 'Corps',
  'edit.properties.list.font_body': 'Corps',
  'edit.properties.list.font_logo': 'Logo',
  'edit.properties.form.form_properties': 'Propriétés du formulaire',
  'edit.properties.form.gdpr_message': 'Message RGPD',
  'edit.properties.form.submitting_required':
    'La soumission du formulaire est requise',
  'edit.properties.form.consent': 'Activer le consentement RGPD',
  'edit.properties.form.gdpr_tooltip':
    'Si cette option est activée, une case à cocher apparaît au-dessus du bouton de soumission.',
  'edit.properties.form.gdpr_prop_placeholder':
    'Espace réservé pour la saisie de texte libre',
  'edit.properties.form.option_list_placeholder':
    "Espace réservé pour la liste d'options",
  'edit.properties.form.rate_placeholder': "Espace réservé pour l'évaluation",
  'edit.properties.form.back_button_text': 'Texte du bouton Retour',
  'edit.properties.form.next_button_text': 'Texte du bouton Suivant',
  'edit.properties.form.submit_button_text': 'Texte du bouton Soumettre',
  'edit.properties.form.submit_message': 'Message de soumission',
  'edit.properties.font.select_option': 'Sélectionner une option',
  'edit.add_new_slide_modal.slide_layouts': 'Mises en page de diapositives',
  'edit.add_new_slide_modal.custom_layouts': 'Mises en page personnalisées',
  'edit.add_new_slide_modal.search_slide_type':
    'Recherchez un type de diapositive dans votre bibliothèque de mises en page, par exemple « Image »',
  'edit.panel.menu_tabs.slide_notes': 'Notes de diapositive',
  'edit.panel.menu_tabs.transcripts': 'Transcriptions',
  'edit.panel.menu_tabs.tips': 'Conseils',
  'edit.panel.menu_tabs.qa': 'Q&R',
  'edit.panel.menu_tabs.translate': 'Traduire',
  'edit.panel.menu_tabs.themes': 'Thèmes',
  'edit.panel.menu_tabs.style': 'Style',
  'edit.panel.menu_tabs.colors': 'Couleurs',
  'edit.panel.background': 'Arrière-plan',
  'edit.panel.elements': 'Éléments',
  'edit.panel.storage': 'Stockage',
  'edit.panel.notes': 'Notes',
  'edit.panel.ai_assistant': 'Assistant IA',
  'edit.panel.brand': 'Marque',
  'edit.panel.layers': 'Calques',
  'edit.slide_menu.warning': 'Avertissement',
  'edit.slide_menu.warning_text':
    'Vous êtes sur le point de remplacer une mise en page existante, voulez-vous continuer ?',
  'edit.slide_menu.replace': 'Remplacer',
  'edit.properties.zoom_types.fill': 'Remplir',
  'edit.properties.zoom_types.fit': 'Ajuster',
  'edit.properties.zoom_types.distort': 'Déformer',
  'edit.properties.zoom_types.crop': 'Recadrer',
  'share_modal.compressed_export_message':
    'La compression réduit la taille du fichier en combinant le texte, les images et autres composants en images optimisées pour chaque diapositive, facilitant le stockage et le partage.',
  'share_modal.include_slide_notes': 'Inclure les notes de diapositives',
  'share_modal.include_slide_notes_info_tooltip':
    'Les notes seront ajoutées sous la miniature de la page.',
  'ai_loading.text1':
    "Saviez-vous que les gens ne se souviennent que de 10% de ce qu'ils entendent après trois jours, mais ils se souviennent de 65% si une image est ajoutée ?",
  'ai_loading.text2':
    'En moyenne, les gens prennent une décision sur une présentation dans les 90 premières secondes.',
  'ai_loading.text3':
    "Les aides visuelles dans une présentation peuvent améliorer l'apprentissage jusqu'à 400%.",
  'ai_loading.text4':
    'La plus longue présentation jamais réalisée a duré 124 heures et a été donnée par Arvind Mishra en Inde en 2018.',
  'ai_loading.text5':
    "L'utilisation de couleurs à fort contraste dans vos diapositives peut augmenter la lisibilité jusqu'à 78%.",
  'ai_loading.text6':
    'Le saviez-vous ? 55% de la communication est le langage corporel, 38% est le ton de la voix, et seulement 7% sont les mots réellement prononcés.',
  'ai_loading.text7':
    "La durée d'attention moyenne d'une personne n'est que de 8 secondes - faites en sorte que vos diapositives comptent !",
  'ai_loading.text8':
    'Conseil de communication : Utilisez la narration pour rendre vos présentations plus mémorables.',
  'ai_loading.text9':
    "Les présentations avec des diapositives plus simples ont tendance à avoir plus d'impact.",
  'ai_loading.text10':
    'Intégrer des histoires dans votre présentation peut la rendre 22 fois plus mémorable.',
  'ai_loading.text11':
    "Établir un contact visuel avec votre public peut augmenter l'engagement et la confiance.",
  'ai_loading.text12':
    "Les gens ne se souviennent que de 10% de ce qu'ils entendent après trois jours, mais ce taux augmente à 65% si une image est ajoutée.",
  'ai_loading.text13':
    "Les gens décident d'une présentation dans les 90 premières secondes.",
  'ai_loading.text14':
    "Les aides visuelles peuvent améliorer l'apprentissage jusqu'à 400% !",
  'ai_loading.text15':
    'La plus longue présentation a duré 124 heures, donnée par Arvind Mishra en Inde en 2018.',
  'ai_loading.text16':
    'Les couleurs à fort contraste dans les diapositives peuvent augmenter la lisibilité de 78%.',
  'ai_loading.text17':
    '55% de la communication est le langage corporel, 38% le ton, et 7% les mots.',
  'ai_loading.text18':
    "La durée d'attention moyenne est de 8 secondes - faites en sorte que vos diapositives comptent !",
  'ai_loading.text19':
    'Conseil : Utilisez la narration pour rendre les présentations mémorables.',
  'ai_loading.text20':
    'Les diapositives plus simples ont souvent un plus grand impact.',
  'ai_loading.text21':
    'Les histoires rendent les présentations 22 fois plus mémorables.',
  'ai_loading.text22':
    "Le contact visuel augmente l'engagement et la confiance du public.",

  'save_state.saving': 'Enregistrement',
  'save_state.saved': 'Enregistré',
  'save_state.not_saved': 'Non enregistré',
  'save_state.failed': "Échec de l'enregistrement",

  // V3 related development texts
  'v3.transparency': 'Transparence',
  'v3.letter_spacing': 'Espacement des Lettres',
  'v3.line_height': 'Hauteur de Ligne',
  'v3.line_size': 'Taille de Ligne',
  'v3.stroke_size': 'Taille du Trait',
  'v3.stroke': 'Trait',
  'v3.fill': 'Remplissage',
  'v3.group': 'Grouper',
  'v3.ungroup': 'Dégrouper',
  'v3.effects': 'Effets',
  'v3.position': 'Position',
  'v3.present': 'Présenter',
  'v3.flip': 'Retourner',
  'v3.flip_horizontal': 'Retourner Horizontalement',
  'v3.flip_vertical': 'Retourner Verticalement',
  'v3.fit': 'Ajuster à la Page',
  'v3.apply_mask': 'Appliquer un Masque',
  'v3.remove_mask': 'Retirer le Masque',
  'v3.colors': 'Couleurs',
  'v3.corner_radius': 'Rayon des Coins',
  'v3.trim': 'Rogner',
  'v3.preparing': 'Préparation',
  'v3.volume': 'Volume',
  'v3.text.bigtext': 'Grand Texte',
  'v3.text.heading': 'Titre',
  'v3.text.subheading': 'Sous-titre',
  'v3.text.normaltext': 'Texte Normal',
  'v3.text.smalltext': 'Petit Texte',
  'v3.text.ai_write': 'Rédaction IA',
  'v3.media.replace': 'Remplacer',
  // v3 media uploader
  'v3.media_uploader.file_size_error':
    'La taille du fichier doit être inférieure à {{ maxSize }} Mo',
  'v3.media_uploader.file_type_error': 'Type de fichier non autorisé',
  'v3.media_uploader.allowed_extensions_error':
    'Extensions autorisées : {{ extensions }}',
  'v3.media_uploader.file_upload_error':
    'Erreur de téléchargement du fichier. Veuillez réessayer plus tard',
  'v3.media_uploader_file_limit_error':
    'La limite maximale de téléchargement est de 10 fichiers. Veuillez réessayer.',
  // v3 media uploader ends
  'v3.panel.content_blocks': 'Blocs de contenu',
  'v3.panel.timelines': 'Chronologies',
  'v3.panel.diagrams': 'Diagrammes',
  'v3.panel.image_blocks': "Blocs d'images",
  'v3.panel.lists': 'Listes',
  'v3.panel.icon_blocks': "Blocs d'icônes",
  'v3.panel.galleries': 'Galeries',
  'v3.panel.shapes_lines': 'Formes et Lignes',
  'v3.panel.notes.placeholder': 'Ajouter des notes de diapositive...',
  'v3.add_page': 'Ajouter une Page',
  'v3.delete_page': 'Supprimer la Page',
  'v3.duplicate_page': 'Dupliquer la Page',
  'v3.object_locked':
    "L'Objet est verrouillé. Déverrouillez-le pour permettre des modifications depuis la toile.",
  'v3.object_unlocked':
    "L'Objet est déverrouillé. Verrouillez-le pour empêcher des modifications depuis la toile.",
  'v3.duplicate_elements': 'Dupliquer les Éléments',
  'v3.delete_elements': 'Supprimer les Éléments',
  'v3.images': 'Images',
  'v3.icons': 'Icônes',
  'v3.videos': 'Vidéos',
  'v3.gifs': 'GIFs',
  'v3.see_all': 'Voir Tout',
  'v3.trending': 'Tendances',
  'v3.results': 'Résultats pour "{{search}}"',
  'v3.font': 'Police',
  'v3.style': 'Style',
  'v3.background': 'Arrière-plan',
  'v3.style.palette': 'Palette du Thème',
  'v3.style.theme_elements_colors': 'Couleurs des Éléments du Thème',
  'v3.style.background': "Couleur de l'Arrière-plan",
  'v3.style.icon': 'Icônes',
  'v3.style.timeline': 'Barre et Point de la Chronologie',
  'v3.style.listBullet': 'Puces de Liste',
  'v3.style.shape': 'Couleurs des Formes',
  'v3.style.line': 'Couleurs des Lignes',
  'v3.style.builder': 'Créateur de Styles',
  'v3.toolbar.width': 'Largeur (px)',
  'v3.toolbar.height': 'Hauteur (px)',
  'v3.toolbar.resize': 'Redimensionner',
  'v3.toolbar.x': 'Position X',
  'v3.toolbar.y': 'Position Y',
  'v3.toolbar.size_and_position': 'Taille et Position',
  'v3.activate': 'Activer la Nouvelle Page d’Édition',
  'v3.activated': 'Le Nouvel Éditeur est Actif',
  'v3.try_new_editor': 'Essayer le Nouvel Éditeur',
  'v3.revert_editor': 'Revenir à l’Ancien Éditeur',
  'v3.try_new_editor_info':
    "Cliquez ici pour plus d'informations détaillées sur le nouvel éditeur.",
  'v3.add_slide.search_placeholder':
    'Recherchez un type de diapositive dans notre bibliothèque de mises en page, par exemple « Image »',
  'v3.add_slide.all': 'Tout',
  'v3.add_slide.text_slides': 'Diapositives de Texte',
  'v3.add_slide.media': 'Médias',
  'v3.add_slide.lists': 'Listes',
  'v3.add_slide.devices': 'Appareils',
  'v3.add_slide.timeline': 'Chronologie',
  'v3.add_slide.icon': 'Icône',
  'v3.add_slide.quote': 'Citation',
  'v3.style.theme_fonts': 'Polices du Thème',
  'v3.font.weight100': 'Très fin',
  'v3.font.weight200': 'Extra Léger',
  'v3.font.weight300': 'Léger',
  'v3.font.weight400': 'Normal',
  'v3.font.weight500': 'Moyen',
  'v3.font.weight600': 'Demi-Gras',
  'v3.font.weight700': 'Gras',
  'v3.font.weight800': 'Extra-Gras',
  'v3.font.weight900': 'Noir',
  'v3.upload.media': 'Téléverser des Médias',
  'v3.upload.font': 'Téléverser votre Police',
  'v3.uploading': 'Téléversement...',
  'v3.font.uploaded': 'Police téléversée avec succès',
  'v3.style.name': 'Nom du Style',
  'v3.style.name_warning': 'Veuillez nommer votre style en premier',
  'v3.style.success': 'Le Style a été enregistré avec succès',
  'v3.style.error':
    'L’enregistrement du style a échoué, une erreur est survenue.',
  'v3.style.background.uploaded':
    "Image téléversée, n'oubliez pas d'enregistrer vos modifications de style.",
  'v3.style.background.label': "Images d'Arrière-plan",
  'v3.style.background.colors_label': 'Couleurs de Fond',
  'v3.style.background.info':
    "Vous pouvez téléverser une image pour l'utiliser comme arrière-plan sur votre diapositive.",
  'v3.style.background.add': 'Ajouter une image de fond',
  'v3.style.palette.tooltip':
    'Toutes les couleurs utilisées dans le thème apparaîtront ici.',
  'v3.style.elements.tooltip':
    'Vous pouvez choisir quelle couleur chaque élément utilisera ici.',
  'v3.style.default_style_error':
    'Vous ne pouvez pas supprimer le style par défaut. Changez le style par défaut et réessayez.',
  'v3.theme.layouts_all_added': 'Toutes les mises en page ont été ajoutées.',
  'v3.stack': 'Empiler',
  'v3.unstack': 'Désassembler',
  'v3.stack_props': 'Propriétés de la Pile',
  'v3.stack.gap': 'Espacement',
  'v3.stack.align_items': 'Aligner les Éléments',
  'v3.solid': 'Solid',
  'v3.gradient': 'Gradient',
  'v3.uploaded_files': 'Fichiers Téléchargés',
  'v3.ai_images': 'Images IA',
  'v3.panel.tables': 'Tableaux',
  'v3.toolbar.table_options': 'Réglages de la table',
  'v3.toolbar.table_cell_options': 'Style de cellule',
  'v3.toolbar.table_header_options': `Style d'en-tête`,
  'v3.toolbar.table_cell': 'Cellule',
  'v3.toolbar.table_header': 'En-tête',
  'v3.toolbar.top': 'Haut',
  'v3.toolbar.right': 'Droite',
  'v3.toolbar.left': 'Gauche',
  'v3.toolbar.bottom': 'Bas',
  'v3.toolbar.table_cell_bg': 'Arrière-plan de cellule',
  'v3.toolbar.table_header_bg': "Arrière-plan d'en-tête",
  'v3.toolbar.text_color': 'Couleur du texte',
  'v3.toolbar.color_style': 'Style de couleur',
  'v3.text.tabletext': 'Textes du tableau',
  'v3.toolbar.table': 'Tableau',
  'v3.toolbar.stroke_color': 'Couleur du trait',
  'v3.toolbar.table_headers': 'En-têtes',
  'v3.table.add_row': 'Ajouter une Ligne',
  'v3.table.delete_row': 'Supprimer la Ligne',
  'v3.table.duplicate_row': 'Dupliquer la Ligne',
  'v3.table.sort_asc': 'Tri Croissant',
  'v3.table.sort_desc': 'Tri Décroissant',
  'v3.table.add_column': 'Ajouter une Colonne',
  'v3.table.delete_column': 'Supprimer la Colonne',
  'v3.table.duplicate_column': 'Dupliquer la Colonne',
  'v3.table.move_up': 'Déplacer vers le Haut',
  'v3.table.move_left': 'Déplacer à Gauche',
  'v3.table.move_right': 'Déplacer à Droite',
  'v3.table.move_down': 'Déplacer vers le Bas',
  'v3.table.header_positions': 'Positions des En-têtes',
  'v3.table.border_style': 'Style de Bordure',
  'v3.style.tableHighlight': 'Arrière-plan du Tableau',
  'v3.style.tableCell': 'Arrière-plan de Cellule',
  'v3.style.tableHeader': "Arrière-plan d'En-tête",
  'v3.style.tableStroke': 'Couleur de Bordure du Tableau',
  'v3.style.advanced_settings': 'Paramètres Avancés',
  'v3.ai_assistant.tips_title': 'Conseils Intelligents pour Présentations',
  'v3.ai_assistant.tips_desc':
    "Obtenez des conseils alimentés par l'IA sur les compétences en présentation.",
  'v3.ai_assistant.qa_title': 'Créer des Q&R Engagés',
  'v3.ai_assistant.qa_desc':
    'Préparez-vous aux questions du public avec des réponses personnalisées.',
  'v3.ai_assistant.translate_title': 'Traduire ma Présentation',
  'v3.ai_assistant.translate_desc':
    "Traduisez votre présentation facilement dans n'importe quelle langue.",
  'v3.ai_assistant.generate_visual_title': "Générateur d'images IA",
  'v3.ai_assistant.generate_visual_desc':
    'Créez des visuels époustouflants à partir de simples entrées textuelles.',
  'v3.ai_assistant.ai_slide_title': 'Créateur de diapositives IA',
  'v3.ai_assistant.ai_slide_desc':
    'Générez des diapositives soignées instantanément à partir de vos textes et fichiers.',
  'v3.ai_assistant.aim': 'Quel est votre objectif ?',
  'v3.ai_assistant.audience': 'Quel est votre public cible ?',
  'v3.ai_assistant.describe_your_request': 'Décrivez votre demande à l’IA',
  'v3.ai_assistant.whats_on_your_mind': "Qu'est-ce qui vous préoccupe ?",
  'v3.decktogpt.welcome_name': 'Bienvenue, ',
  'v3.decktogpt.what_do_you_want_to_create': 'Que voulez-vous créer?',
  'v3.decktogpt.show_examples': 'Afficher les exemples',
  'v3.decktogpt.hide_examples': 'Masquer les exemples',
  'v3.decktogpt.drop_files_here':
    'Déposez des fichiers ici pour les ajouter à la présentation',
  'v3.decktogpt.drop_files_here_desc':
    'Max 5 fichiers par chat/présentation de 5 Mo chacun',
  'v3.decktogpt.i_want_to_create': 'Je veux créer une présentation sur...',
  'v3.decktogpt.presentation_outline': 'Plan de Présentation',
  'v3.decktogpt.craft_the_perfect':
    'Créez facilement le plan parfait de votre présentation',
  'v3.decktogpt.modify_outline': 'Modifier le Plan',
  'v3.decktogpt.modify': 'Modifier',
  'v3.decktogpt.regenerate': 'Régénérer',
  'v3.decktogpt.theme_previews': 'Aperçus des thèmes',
  'v3.decktogpt.which_theme': 'Quel thème souhaitez-vous utiliser?',
  'v3.decktogpt.default': 'Par défaut',
  'v3.decktogpt.unlock_fully_branded':
    'Débloquez des thèmes entièrement personnalisés avec notre package Entreprise. Mettez en avant votre identité 100% corporate !',
  'v3.decktogpt.everything_included': 'Fonctionnalités exclusives d’Enterprise',
  'v3.decktogpt.included_1': 'Slides 100% personnalisés à votre marque',
  'v3.decktogpt.included_2': 'Configuration AI pour les slides de marque',
  'v3.decktogpt.included_3': 'Mises en page de slides personnalisées',
  'v3.decktogpt.included_4': 'Importation de documents existants',
  'v3.decktogpt.included_5': 'Templates corporate personnalisés',
  'v3.decktogpt.book_a_demo': 'Réserver une démo',
  'v3.decktogpt.show_theme_previews': 'Afficher les aperçus des thèmes',
  'v3.decktogpt.hide_theme_previews': 'Masquer les aperçus des thèmes',
  'v3.decktogpt.theme_preview_tooltip':
    'Ces slides d’exemple prévisualisent votre présentation avec chaque thème. Ils ne sont pas définitifs et servent à guider votre choix.',
  'v3.decktogpt.default_tooltip':
    'Découvrez des thèmes professionnels conçus par Decktopus.',
  'v3.decktogpt.brand_tooltip':
    'Utilisez votre thème personnalisé pour une touche unique.',
  'v3.decktogpt.generating_outline': 'Génération du plan',
  'v3.decktogpt.searching_the_web': 'Recherche sur le web',
  'v3.decktogpt.refine_your_presentation': 'Affinez votre présentation',
  'v3.decktogpt.sample_prompt_1': 'Ajouter une diapositive',
  'v3.decktogpt.sample_prompt_2': 'Modifier la Diapositive 2',
  'v3.decktogpt.sample_prompt_3': 'Raccourcir le plan',
  'v3.decktogpt.sample_prompt_4': 'Régénérer le titre de la Diapositive 2',
  'v3.decktogpt.make_concise': 'Rendre concis',
  'v3.decktogpt.expand': 'Développer',
  'v3.decktogpt.theme_selection': 'Sélection du Thème',
  'v3.decktogpt.leave_page_prompt':
    'Vous êtes sur le point de quitter DecktoGPT',
  'v3.decktogpt.best_choice': 'Meilleur Choix',
  'v3.decktogpt.change_layout': 'Changer la disposition',
  'v3.decktogpt.change_design': 'Modifier le Design',
  'v3.decktogpt.choose_a_theme': 'Choisir un thème',
  'v3.decktogpt.choose_a_theme_desc':
    'Qui correspond à votre concept de présentation',
  'v3.decktogpt.choose_a_layout': 'Choisir une disposition',
  'v3.decktogpt.apply_theme': 'Appliquer le thème',
  'v3.decktogpt.apply_layout': 'Appliquer la disposition',
  'v3.decktogpt.pick_a_theme':
    'Choisissez un thème qui correspond le mieux à votre contenu et crée un look cohérent et professionnel',
  'v3.decktogpt.choose_a_layout_desc':
    'Choisissez une disposition pour organiser efficacement le contenu de votre diapositive',
  'v3.decktogpt.suggested_layouts': 'Dispositions suggérées',
  'v3.decktogpt.suggested_layouts_desc':
    'Ces mises en page sont suggérées pour votre contenu spécial de plan.',
  'v3.decktogpt.layouts_designed_for_you':
    'Dispositions conçues pour votre contenu spécial',
  'v3.decktogpt.others': 'Autres',
  'v3.decktogpt.need_help': "Besoin d'aide ?",
  'v3.decktogpt.cover_slide': 'Diapositive de Couverture',
  'v3.decktogpt.cover_slide_desc_1':
    'Une diapositive de couverture dynamique sera créée en fonction de votre contenu.',
  'v3.decktogpt.cover_slide_desc_2':
    'Vous pouvez modifier le titre et choisir un autre modèle pour l’adapter à vos besoins.',
  'v3.decktogpt.cover_slide_desc_3':
    "Si vous choisissez un modèle différent des modèles de couverture, l'esquisse sera automatiquement recréée.",
  'v3.decktogpt.btn.generating_outline': 'Génération du plan',
  'v3.decktogpt.btn.creating_content': 'Création de contenu',
  'v3.decktogpt.btn.selecting_layouts': 'Sélection des mises en page',
  'v3.decktogpt.thinking': 'Réflexion',
  'v3.decktogpt.payment_failed':
    'Paiement échoué. Veuillez réessayer plus tard.',
  'v3.decktogpt.timeout_error': 'Un problème est survenu.',
  'v3.decktogpt.timeout_click_here': 'Cliquez ici pour réessayer.',
  'v3.decktogpt.fill_missing_fields': 'Veuillez remplir les champs manquants.',
  'v3.decktogpt.ensure_layout_selected':
    'Assurez-vous que toutes les diapositives ont une mise en page sélectionnée avant de continuer.',
  // V3 related development texts ends
  'v3.theme_description.aqua':
    'Les couleurs pastel légères offrent une sensation de clarté et de tranquillité.',
  'v3.theme_description.rhythm':
    'Un style audacieux et rythmé avec des motifs structurés en briques et des contrastes riches.',
  'v3.theme_description.glint':
    'Un mélange frais de vert et de noir, équilibrant nature et élégance.',
  'v3.theme_description.obsidian':
    'Des tons noirs élégants dégagent confiance et minimalisme raffiné.',
  'v3.theme_description.orbit':
    'Une géométrie pastel douce pour un look moderne et équilibré.',
  'v3.theme_description.nexus':
    'Des bleus profonds et des dégradés modernes apportent une touche futuriste.',
  'v3.theme_description.flow':
    'Des vagues douces et fluides évoquent le mouvement et la créativité sans effort.',
  'v3.theme_description.spark':
    'Ludique et énergique avec des accents néon et des griffonnages.',
  'v3.theme_description.lusso':
    'Un noir et or audacieux, irradiant luxe et sophistication.',
  'v3.theme_description.default':
    "L'identité de la marque rencontre un design fluide : des thèmes qui incarnent l'essence de chaque marque.",
  // V3 Themes description ends
}
