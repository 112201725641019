import { dataAttrType } from '@/types'

export interface ILoginForm {
  className?: string
  dataAttr?: dataAttrType
  onGoogleAuth?: () => void
}

export enum ILoginType {
  JWT = 'jwt',
  GOOGLE = 'google',
}
