import { IApiEnums } from '@/i18n/types'
import {
  AICreditSpendReasonNames,
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
  UserLanguages,
} from '@/types/api/enums'

export const apiEnums: IApiEnums = {
  [`enum.planNames.${PlanNames.LITE}`]: 'lite',
  [`enum.planNames.${PlanNames.PRO}`]: 'pro',
  [`enum.planNames.${PlanNames.BUSINESS}`]: 'business',
  [`enum.planNames.${PlanNames.ENTERPRISE}`]: 'enterprise',
  [`enum.planPeriods.${PlanPeriods.MONTHLY}`]: 'monatlich',
  [`enum.planPeriods.${PlanPeriods.ANNUAL}`]: 'jährlich',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_DECK}`]:
    'AI Deck erstellen',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_PDF_DECK}`]:
    'PDF Deck erstellen',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_ICON}`]:
    'Symbolvorschlag',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_IMAGE}`]:
    'Visueller Vorschlag',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_LIST_ITEM}`]:
    'Listenvorschlag',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_TIPS}`]:
    'Tippvorschlag',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_QUESTIONS_ANSWERS}`]:
    'Erstellen Sie eine Frage und eine Antwort',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SINGLE_SLIDE_NOTES}`]:
    'Erstellen einer einzelnen Foliennotiz',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_MULTIPLE_SLIDE_NOTES}`]:
    'Erstellen mehrerer Foliennotizen',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.AI_TEXT_EDIT}`]:
    'AI Textbearbeitung',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.TRANSLATE_DECK}`]:
    'Deck übersetzen',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SLIDE}`]:
    'Folie generieren',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED}`]:
    'Zahlungsaktion erforderlich',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_PENDING}`]:
    'Zahlung wartet',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_FAILED}`]:
    'Abgesagt',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_SUCCESS}`]:
    'Aktiv',
  [`enum.language.${UserLanguages.EN_US}`]: 'Englisch',
  [`enum.language.${UserLanguages.TR_TR}`]: 'Türkisch',
}
