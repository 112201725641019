import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en'
import tr from './locales/tr'
import de from './locales/de'
import pt from './locales/pt'
import es from './locales/es'
import fr from './locales/fr'
import { capitalizeFirstLetter } from '@/services/stringServices'
import { AVAILABLE_LANGUAGES } from './locales/consts'

const lang = localStorage.getItem('currentLanguage')

const fallbackLng = 'en'

i18n.use(initReactI18next).init({
  fallbackLng: fallbackLng,
  lng: lang || fallbackLng,
  resources: {
    en: {
      translations: en,
    },
    tr: {
      translations: tr,
    },
    de: {
      translations: de,
    },
    pt: {
      translations: pt,
    },
    es: {
      translations: es,
    },
    fr: {
      translations: fr,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.services?.formatter?.add('lowercase', (value) => {
  return value.toLowerCase()
})

i18n.services?.formatter?.add('capitalFirstLetter', (value) => {
  return capitalizeFirstLetter(value)
})

i18n.languages = AVAILABLE_LANGUAGES

export default i18n
