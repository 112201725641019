import { PlanNames } from '@/types/api/enums'
type PlanName = 'lite' | 'pro' | 'business'
export const getPlanName = (name?: PlanNames | string): PlanName => {
  switch (name) {
    case PlanNames.LITE:
      return 'lite'
    case PlanNames.PRO:
      return 'pro'
    default:
      return 'business'
  }
}
