import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assetResponseSchema } from '@/types/api/responseObjects'

export interface IDeckV3PersistSlice {
  assets: assetResponseSchema[]
  useV3: boolean
}

const initialState: IDeckV3PersistSlice = {
  assets: [],
  useV3: true,
}

export const deckV3PersistSlice = createSlice({
  name: 'deckV3',
  initialState,
  reducers: {
    setAssets: (
      state,
      action: PayloadAction<IDeckV3PersistSlice['assets']>,
    ) => {
      state.assets = action.payload
    },
    addAsset: (
      state,
      action: PayloadAction<IDeckV3PersistSlice['assets'][0]>,
    ) => {
      state.assets = state.assets
        ? [...state.assets, action.payload]
        : [action.payload]
    },
    setUseV3: (state, action: PayloadAction<boolean>) => {
      state.useV3 = action.payload
    },
  },
})

export const { setAssets, addAsset, setUseV3 } = deckV3PersistSlice.actions

export default deckV3PersistSlice.reducer
