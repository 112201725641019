import {
  getDeckDetailedResponse,
  getSlideDataResponse,
} from '@/types/api/responseObjects'
import { LoaderState, StrictLoaderState } from '../types'
import {
  ThemeColorMapSchema,
  putUpdateDeckDataBody,
} from '@/types/api/requestObjects'
import { IDeckQA, IDeckTips } from '@/components/edit-ai-assistant'

export type ISaveQueue = StrictLoaderState<
  NonNullable<putUpdateDeckDataBody['deckData']['slides']>
>

export enum EDIT_ACTION_TYPE {
  COMPONENT_CREATE = 'component_create',
  COMPONENT_UPDATE = 'component_update',
  COMPONENT_DELETE = 'component_delete',
}

export const EDIT_ACTION_COUNTER: Record<EDIT_ACTION_TYPE, EDIT_ACTION_TYPE> = {
  [EDIT_ACTION_TYPE.COMPONENT_CREATE]: EDIT_ACTION_TYPE.COMPONENT_DELETE,
  [EDIT_ACTION_TYPE.COMPONENT_UPDATE]: EDIT_ACTION_TYPE.COMPONENT_UPDATE,
  [EDIT_ACTION_TYPE.COMPONENT_DELETE]: EDIT_ACTION_TYPE.COMPONENT_CREATE,
}

export interface IHistoryItem {
  oldValue: NonNullable<putUpdateDeckDataBody['deckData']['slides']>[0]
  newValue: NonNullable<putUpdateDeckDataBody['deckData']['slides']>[0]
  action: EDIT_ACTION_TYPE
}
export interface IEditInitialState {
  activeDeck: LoaderState<getDeckDetailedResponse['data'] | null>
  activeSlideData: LoaderState<getSlideDataResponse['data']['slideData'][]>
  activeSlideID?: number
  activeSlideDataID?: number
  toBeSaved: ISaveQueue
  instantSave: ISaveQueue
  savingStack: NonNullable<putUpdateDeckDataBody['deckData']['slides']>
  customDomainId: number | null
  slideNotes: string
  speakerNotes: string
  tips: IDeckTips[]
  qa: IDeckQA[]
  clickedButton: string
  history: IHistoryItem[]
  undoHistory: {
    past: IHistoryItem[]
    future: IHistoryItem[]
  }
  cachedColorMaps: {
    id: string
    mapping: ThemeColorMapSchema
  }[]
}

export enum COMPONENT_ALIGN {
  LEFT = 'left',
  VERTICAL = 'vertical',
  RIGHT = 'right',
  TOP = 'top',
  HORIZONTAL = 'horizontal',
  BOTTOM = 'bottom',
}

export enum COMPONENT_DIST {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
