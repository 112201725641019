import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SAVE_STATE } from '../types'
import { CANVAS_PANEL, ICanvasState, IComponentFixerItem } from './types'
import { ComponentSchema } from '@/types/api/responseObjects'

const initialState: ICanvasState = {
  panel: CANVAS_PANEL.ELEMENTS,
  selectedComponents: [],
  componentsDragging: false,
  saveState: SAVE_STATE.SAVED,
  addSlideModal: {
    isOpen: false,
    targetSlide: undefined,
    isDuplicate: false,
  },
  allowDeleteShortcut: true,
  clickCreateList: [],
  componentClipboard: undefined,
  isNextSlideBlocked: false,
  slideForms: [],
  componentFixInProgress: false,
  componentFixer: [],
}

export const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setPanel: (state, action: PayloadAction<CANVAS_PANEL>) => {
      state.panel = action.payload
    },
    setSelectedComponents: (
      state,
      action: PayloadAction<ICanvasState['selectedComponents'] | undefined>,
    ) => {
      const hasSelectionChanged =
        state.selectedComponents
          .map(({ id, tempId }) => `${id}|${tempId}`)
          .sort()
          .toString() !==
        action?.payload
          ?.map(({ id, tempId }) => `${id}|${tempId}`)
          .sort()
          .toString()

      if (!action.payload || hasSelectionChanged) {
        state.allowDeleteShortcut = true
      }

      if (hasSelectionChanged) {
        state.selectedComponents = action.payload || []
      }
    },
    toggleSelectedComponents: (
      state,
      action: PayloadAction<ICanvasState['selectedComponents'][0]>,
    ) => {
      if (action.payload) {
        if (
          state.selectedComponents.find(({ id, tempId }) =>
            action.payload.id
              ? id === action.payload.id
              : tempId === action.payload.tempId,
          )
        ) {
          state.selectedComponents = state.selectedComponents.filter(
            ({ id, tempId }) =>
              !(id === action.payload.id && tempId === action.payload.tempId),
          )
        } else {
          state.selectedComponents.push(action.payload)
        }
      }
    },
    setDragging: (
      state,
      action: PayloadAction<ICanvasState['componentsDragging'] | undefined>,
    ) => {
      state.componentsDragging = !!action.payload
    },
    setSaveState: (state, action: PayloadAction<SAVE_STATE>) => {
      // if state is NOT_SAVED and it's trying to set it to SAVED that means
      // there is new change while SAVING. Prevent directly set to saved without saving state
      if (
        !(
          state.saveState === SAVE_STATE.NOT_SAVED &&
          action.payload === SAVE_STATE.SAVED
        )
      ) {
        state.saveState = action.payload
      }
    },
    setAddSlideModal: (
      state,
      action: PayloadAction<ICanvasState['addSlideModal']>,
    ) => {
      const isOpen = action.payload.isOpen
      state.addSlideModal = {
        isOpen,
        isDuplicate: isOpen ? action.payload.isDuplicate : false,
        targetSlide: isOpen ? action.payload.targetSlide : undefined,
      }
    },
    clearCanvas: (state) => {
      state.panel = initialState.panel
      state.selectedComponents = initialState.selectedComponents
      state.saveState = initialState.saveState
      state.addSlideModal = initialState.addSlideModal
      state.allowDeleteShortcut = initialState.allowDeleteShortcut
      state.clickCreateList = initialState.clickCreateList
    },
    setAllowDeleteShortcut: (state, action: PayloadAction<boolean>) => {
      state.allowDeleteShortcut = action.payload
    },
    addClickCreateList: (state, action: PayloadAction<string>) => {
      state.clickCreateList.push(action.payload)
    },
    removeClickCreateList: (state, action: PayloadAction<string[]>) => {
      const newState: ICanvasState['clickCreateList'] = []
      const filteredList = state.clickCreateList.map((item) =>
        !item || action.payload.includes(item) ? null : item,
      )

      let start = false
      filteredList.reverse().forEach((item) => {
        if (item !== null) {
          start = true
        }

        if (start) {
          newState.unshift(item)
        }
      })

      state.clickCreateList = newState
    },
    cleanClickCreateList: (state) => {
      state.clickCreateList = initialState.clickCreateList
    },
    setComponentClipboard: (state, action: PayloadAction<ComponentSchema>) => {
      state.componentClipboard = action.payload
    },
    setNextSlideBlock: (state, action: PayloadAction<boolean>) => {
      state.isNextSlideBlocked = action.payload
    },
    initSlideFormData: (
      state,
      action: PayloadAction<{
        id: string
        state: boolean
        questions: string[]
      }>,
    ) => {
      if (!state.slideForms.find(({ id }) => id === action.payload.id)) {
        state.slideForms.push({
          id: action.payload.id,
          state: action.payload.state,
          data: action.payload.questions.reduce(
            (a: Record<string, null>, c) => {
              return {
                ...a,
                [c]: null,
              }
            },
            {},
          ),
        })
      }
    },
    setSlideFormData: (
      state,
      action: PayloadAction<{
        formId: string
        questionId: string
        answer: string | number | string[]
      }>,
    ) => {
      state.slideForms.find(({ id }) => id === action.payload.formId)!.data[
        action.payload.questionId
      ] = action.payload.answer
    },
    setComponentFixState: (state, action: PayloadAction<boolean>) => {
      state.componentFixInProgress = action.payload
    },
    addComponentFixQueue: (
      state,
      action: PayloadAction<IComponentFixerItem>,
    ) => {
      if (
        state.componentFixer.find(
          ({ data }) => data.id === action.payload.data.id,
        )
      ) {
        return
      }

      state.componentFixer.push(action.payload)
    },
    removeFirstItemFromComponentFixQueue: (state) => {
      state.componentFixer.shift()
    },
  },
})

export const {
  setPanel,
  setSelectedComponents,
  toggleSelectedComponents,
  setDragging,
  setSaveState,
  setAddSlideModal,
  clearCanvas,
  setAllowDeleteShortcut,
  addClickCreateList,
  removeClickCreateList,
  cleanClickCreateList,
  setComponentClipboard,
  setNextSlideBlock,
  setSlideFormData,
  initSlideFormData,
  setComponentFixState,
  addComponentFixQueue,
  removeFirstItemFromComponentFixQueue,
} = canvasSlice.actions

export default canvasSlice.reducer
