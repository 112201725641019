import { configureStore } from '@reduxjs/toolkit'
import foldersReducer from './folders/foldersSlice'
import planReducer from './plan/planSlice'
import userReducer from './user/userSlice'
import orgReducer from './org/orgSlice'
import themeReducer from './theme/themeSlice'
import decksReducer from './decks/decksSlice'
import deckV3Reducer from './deck-v3/deckV3Slice'
import deckV3PersistReducer from './deck-v3/deckV3PersistSlice'
import editReducer from './edit/editSlice'
import canvasReducer from './canvas/canvasSlice'
import aiFlowReducer from './ai-flow/aiFlowSlice'
import workspaceReducer from './workspace/workspaceSlice'
import layoutsReducer from './layouts/layoutsSlice'
import cacheReducer from './cache/cacheSlice'
import recentColorsReducer from './recent-colors/recentColorsSlice'
import adminReducer from './admin/adminSlice'
import deckV3ThemeReducer from './deck-v3/deckV3ThemeSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import generalV3Reducer from './generalV3/generalV3Slice'

const persistedWorkspace = persistReducer(
  {
    key: 'workspace',
    version: 1,
    storage,
  },
  workspaceReducer,
)

const persistedDeckV3 = persistReducer(
  {
    key: 'deckV3Persist',
    version: 1,
    storage,
  },
  deckV3PersistReducer,
)

const persistedRecentColors = persistReducer(
  {
    key: 'recent-colors',
    version: 1,
    storage,
  },
  recentColorsReducer,
)

export const store = configureStore({
  reducer: {
    folders: foldersReducer,
    plan: planReducer,
    user: userReducer,
    org: orgReducer,
    theme: themeReducer,
    decks: decksReducer,
    edit: editReducer,
    canvas: canvasReducer,
    aiFlow: aiFlowReducer,
    workspace: persistedWorkspace,
    layouts: layoutsReducer,
    cache: cacheReducer,
    recentColors: persistedRecentColors,
    admin: adminReducer,
    deckV3: deckV3Reducer,
    persistedDeckV3,
    generalV3: generalV3Reducer,
    deckV3Theme: deckV3ThemeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
