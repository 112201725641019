import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  deckWithMetaSchema,
  getDeckExportsResponse,
  getDecksResponse,
  getPivotDecksResponse,
} from '@/types/api/responseObjects'
import { LoaderState } from '../types'
import {
  deckIdParams,
  deckIdUserIdParams,
  getDecksQuery,
  postDeckMoveBody,
  putDeckUpdateBody,
  putUpdateDeckCollaboratorPermissionBody,
  putUpdateDeckShareBody,
} from '@/types/api/requestObjects'
import {
  DeckStates,
  DecksSortByFields,
  SortDirections,
  ViewModes,
} from '@/types/api/enums'

interface IWaitingDeckState {
  id: number
  name: string
  state: DeckStates
}
interface IInitialState {
  totalCount?: getDecksResponse['data']['totalCount']
  list: LoaderState<getDecksResponse['data']['decksWithMeta']>
  pivotData: getPivotDecksResponse['data']['pivotDecks']
  pivotLength: number
  decksQuery: getDecksQuery
  activePage: number
  selectedExportTab: number
  exportedFileData: getDeckExportsResponse['data']['exports']
  waitingDecksStates: IWaitingDeckState[]
  hasActiveMigration: boolean
  shouldClearSearch: boolean
}

const initialState: IInitialState = {
  totalCount: undefined,
  list: {
    data: [],
    isLoading: false,
  },
  pivotData: [],
  pivotLength: 0,
  decksQuery: {
    offset: 0,
    limit: 10,
    sortBy: DecksSortByFields.CREATED_AT,
    sortDirection: SortDirections.DESC,
    sharedWithMe: false,
    viewMode: ViewModes.PRIVATE,
  },
  activePage: 1,
  selectedExportTab: 0,
  exportedFileData: [],
  waitingDecksStates: [],
  hasActiveMigration: false,
  shouldClearSearch: false,
}

export const decksSlice = createSlice({
  name: 'decks',
  initialState,
  reducers: {
    setShouldClearSearch: (state, action) => {
      state.shouldClearSearch = action.payload
    },
    setActiveMigration: (state, action) => {
      state.hasActiveMigration = action.payload
    },
    setDecks: (
      state,
      action: PayloadAction<LoaderState<getDecksResponse['data']>>,
    ) => {
      if (action.payload.data) {
        state.list.data = action.payload.data.decksWithMeta
        state.totalCount = action.payload.data.totalCount
      } else {
        state.list.isLoading = !!action.payload.isLoading
      }
    },
    setPivotDecks: (
      state,
      action: PayloadAction<getPivotDecksResponse['data']['pivotDecks']>,
    ) => {
      state.pivotData = action.payload
    },
    setPivotLength: (state, action: PayloadAction<number>) => {
      state.pivotLength = action.payload
    },
    setSingleDeck: (
      state,
      action: PayloadAction<
        LoaderState<getDecksResponse['data']['decksWithMeta'][0]>
      >,
    ) => {
      const newDeck = action.payload.data
      if (newDeck) {
        state.list.data = state.list.data?.map((item) => {
          if (item.deck.id === newDeck.deck.id) {
            return newDeck
          } else {
            return item
          }
        })
      } else {
        state.list.isLoading = !!action.payload.isLoading
      }
    },
    setUpdateTime: (state, action: PayloadAction<number>) => {
      if (state.list.data)
        state.list.data[action.payload].deck.updatedAt = new Date()
    },
    setExportData: (
      state,
      action: PayloadAction<getDeckExportsResponse['data']['exports']>,
    ) => {
      state.exportedFileData = action.payload
    },
    setDeckName: (
      state,
      action: PayloadAction<deckIdParams & putDeckUpdateBody>,
    ) => {
      const deckToUpdate = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deckToUpdate) {
        deckToUpdate.deck.name = action.payload.name!
      }
    },
    setDeckOrgPermission: (
      state,
      action: PayloadAction<deckIdParams & putDeckUpdateBody>,
    ) => {
      const deckToUpdate = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deckToUpdate) {
        const orgDeck = deckToUpdate.deck.organizationDecks.find(
          (og) => og.organizationId === deckToUpdate.deck.organizationId,
        )

        if (orgDeck) {
          orgDeck.permission = action.payload.deckPermission!
        }
      }
    },
    setCollabratorDelete: (
      state,
      action: PayloadAction<deckIdUserIdParams>,
    ) => {
      const deckToUpdate = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deckToUpdate) {
        deckToUpdate.deck.userDecks = deckToUpdate.deck.userDecks.filter(
          (ud) => ud.user.id !== action.payload.userId,
        )
      }
    },
    setCollabratorPermissionUpdate: (
      state,
      action: PayloadAction<
        deckIdUserIdParams & putUpdateDeckCollaboratorPermissionBody
      >,
    ) => {
      const deckToUpdate = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deckToUpdate) {
        deckToUpdate.deck.userDecks = deckToUpdate.deck.userDecks.map((ud) => {
          if (ud.user.id === action.payload.userId) {
            return {
              ...ud,
              permission: action.payload.newPermission,
            }
          }

          return ud
        })
      }
    },
    setDeleteDeck: (state, action: PayloadAction<deckIdParams>) => {
      state.list.data = state.list.data?.filter((deck) => {
        return deck.deck.id !== action.payload.deckId
      })
      if (state.totalCount) {
        state.totalCount = state.totalCount - 1
      }
    },
    setDuplicateDeck: (state, action: PayloadAction<deckWithMetaSchema>) => {
      const decks = state.list.data
      if (decks) {
        state.list.data = [action.payload, ...decks]
      }
      if (state.totalCount) {
        state.totalCount = state.totalCount + 1
      }
    },
    setMoveDeck: (
      state,
      action: PayloadAction<
        postDeckMoveBody & deckIdParams & { folderName?: string }
      >,
    ) => {
      const deckToMove = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deckToMove) {
        if (action.payload.folderId) {
          deckToMove.deck.folderDeck = {
            folder: {
              id: action.payload.folderId,
              name: action.payload.folderName!,
            },
          }
        } else {
          deckToMove.deck.folderDeck = null
        }
        state.list.data = state.list.data?.filter((deck) => {
          return deck.deck.id !== deckToMove.deck.id
        })
        if (state.totalCount) {
          state.totalCount = state.totalCount - 1
        }
      }
    },
    setDecksQuery: (state, action: PayloadAction<Partial<getDecksQuery>>) => {
      if (state.decksQuery === action.payload) return
      state.decksQuery = { ...state.decksQuery, ...action.payload }
    },
    setActivePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload
    },
    setSelectedExportTab: (state, action: PayloadAction<number>) => {
      state.selectedExportTab = action.payload
    },
    setNewDeckFromNextPage: (
      state,
      action: PayloadAction<LoaderState<getDecksResponse['data']>>,
    ) => {
      const decks = state.list.data
      const newDeck = action.payload.data?.decksWithMeta
      if (decks && newDeck) {
        state.list.data = [...decks, ...newDeck]
      } else {
        state.list.isLoading = !!action.payload.isLoading
      }
    },
    setDeckShareStatus: (
      state,
      action: PayloadAction<{ deckId: number; params: putUpdateDeckShareBody }>,
    ) => {
      const deck = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deck && deck.deck.deckShare)
        deck.deck.deckShare.isActive = !!action.payload.params.isActive
    },
    setDeckShare: (
      state,
      action: PayloadAction<{
        deckId: number
        params: putUpdateDeckShareBody
        domain: string
      }>,
    ) => {
      const deck = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deck && deck.deck.deckShare)
        if (action.payload.domain !== import.meta.env.VITE_SHARE_URL_BASE) {
          deck.deck.deckShare.customDomainSlug = {
            id: 0,
            slug: action.payload.params.slug!,
            customDomain: {
              id: action.payload.params.customDomainId!,
              domain: action.payload.domain,
              isVerified: true,
            },
          }
        } else {
          deck.deck.deckShare.customDomainSlug = null
        }
    },
    setDeckSharesCustomDomainsNull: (
      state,
      action: PayloadAction<{ customDomainId: number }>,
    ) => {
      state.list.data?.forEach((deck) => {
        if (
          deck.deck.deckShare?.customDomainSlug?.customDomain?.id ===
          action.payload.customDomainId
        ) {
          deck.deck.deckShare.customDomainSlug = null
        }
      })
    },
    setWaitingDecksStates: (
      state,
      action: PayloadAction<IWaitingDeckState>,
    ) => {
      const itemIndex = state.waitingDecksStates.findIndex(
        (item) => item.id === action.payload.id,
      )
      if (itemIndex === -1) {
        state.waitingDecksStates = [...state.waitingDecksStates, action.payload]
      } else {
        if (
          action.payload.state !== DeckStates.IN_QUEUE &&
          action.payload.state !== DeckStates.PROCESSING
        ) {
          state.waitingDecksStates = state.waitingDecksStates.filter(
            (item) => item.id !== action.payload.id,
          )
        } else {
          state.waitingDecksStates[itemIndex] = action.payload
        }
      }
    },
    setPivotDeckPublishStatus: (
      state,
      action: PayloadAction<{ deckId: number; isHidden: boolean }>,
    ) => {
      const deckToUpdate = state.list.data?.find(
        (deck) => deck.deck.id === action.payload.deckId,
      )
      if (deckToUpdate) {
        deckToUpdate.deck.pivotDeck = {
          id: action.payload.deckId, // put deckId here as pivotDeckId temporarily
          isHidden: action.payload.isHidden,
        }
      }
    },
    setDeckQueryToInitial: (state) => {
      state.decksQuery = initialState.decksQuery
    },
  },
})

export const {
  setActiveMigration,
  setShouldClearSearch,
  setDecks,
  setPivotDecks,
  setPivotLength,
  setSingleDeck,
  setWaitingDecksStates,
  setUpdateTime,
  setExportData,
  setDeckName,
  setDeckOrgPermission,
  setDeleteDeck,
  setDuplicateDeck,
  setMoveDeck,
  setDecksQuery,
  setDeckQueryToInitial,
  setActivePage,
  setSelectedExportTab,
  setNewDeckFromNextPage,
  setCollabratorDelete,
  setCollabratorPermissionUpdate,
  setDeckShareStatus,
  setDeckShare,
  setDeckSharesCustomDomainsNull,
  setPivotDeckPublishStatus,
} = decksSlice.actions

export default decksSlice.reducer
