import { upperCase, capitalCase } from 'case-anything'
import { getPlanName } from '@/components/settings-panel/components/plans/helpers'
import { PlanNames, PlanPeriods } from '@/types/api/enums'
import urlParser from 'url-parse'

export { upperCase, capitalCase }

export const objectKeyFormatter = (key: string) => {
  if (key.includes('-') || !isNaN(parseInt(key[1]))) {
    return `['${key.slice(1)}']`
  }

  return key
}

export const firstLetters = (text: string): string => {
  const words = text.split(' ')
  return `${words?.[0]?.[0]}${words?.[1]?.[0] || ''}`.toUpperCase()
}

export const capitalizeFirstLetter = (text: string): string =>
  text?.length ? text[0].toUpperCase() + text.slice(1) : text

export const clearHtmlTags = (data: string[]): string[] => {
  return data
    .map((line) => {
      const span = document.createElement('span')
      span.innerHTML = line
      return span.textContent || ''
    })
    .filter((v) => v)
}

export const getTextContent = (text: string): string => {
  const span = document.createElement('span')
  span.innerHTML = text
  return span.textContent || ''
}

export const isWrappedWithPTags = (str: string): boolean => {
  // Define the regex pattern to match <p> tags with any attributes
  const pattern: RegExp = /^<p\b[^>]*>(.*?)<\/p>$/i

  // Test the string against the pattern
  return pattern.test(str)
}

export const isWrappedWithPTagsNew = (str: string): boolean => {
  // Define the regex pattern to match <p> tags with any attributes
  const pattern: RegExp = /^(<([a-z][^\s>]*)\b[^>]*>)(.*?)(<\/\2>$)/i

  // Test the string against the pattern
  return pattern.test(str)
}

export const moveMinusToFront = (amount: string) => {
  // Check if the string contains the minus symbol and it's not already at the front
  if (amount.includes('-') && amount[0] !== '-') {
    // Remove the minus symbol from its current position
    amount = amount.replace('-', '')
    // Add the minus symbol to the front of the string
    return '-' + amount
  }
  // If there's no minus symbol or it's already at the front, return the string as is
  return amount
}

export const ensureTwoDecimalPlaces = (value: string): string => {
  // Check if the string contains a decimal point
  if (value.includes('.')) {
    const parts = value.split('.')
    // If there's only one digit after the decimal point, append a '0'
    if (parts[1].length === 1) {
      return `${value}0`
    }
  }
  // If there's no decimal point or already two digits after it, return the original value
  return value
}

export const buildPlanType = (
  plan?: PlanNames,
  period?: PlanPeriods,
): string => {
  return `${getPlanName(plan)}-${
    period === PlanPeriods.MONTHLY ? 'monthly' : 'annual'
  }`
}

export const decodeLandingPrompt = (prompt: string): string => {
  try {
    const encodedPrompt = atob(prompt)
    const decodedPrompt = decodeURIComponent(encodedPrompt)
    return JSON.parse(decodedPrompt).prompt
  } catch (error) {
    console.error(error)
    return ''
  }
}

export { urlParser }
