import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeckV3Document,
  GetDeckV3Document,
  GetDeckV3Styles,
  IDeckV3SlideNote,
} from '@/types/api/responseObjects'
import { SAVE_STATE } from '../types'

export interface IDeckV3Slice {
  documentData?: GetDeckV3Document
  saveNow: boolean
  deletedSlides: string[]
  saveState: SAVE_STATE
  styles: GetDeckV3Styles
  storeLoading: boolean
  mediaUploading: boolean
}

const initialState: IDeckV3Slice = {
  documentData: undefined,
  saveNow: false,
  deletedSlides: [],
  saveState: SAVE_STATE.SAVED,
  styles: [],
  storeLoading: false,
  mediaUploading: false,
}

export const deckV3Slice = createSlice({
  name: 'deckV3',
  initialState,
  reducers: {
    setDocumentData: (
      state,
      action: PayloadAction<GetDeckV3Document | undefined>,
    ) => {
      state.documentData = action.payload
    },
    setDocument: (state, action: PayloadAction<DeckV3Document>) => {
      state.documentData = {
        extraData: {
          ...(state.documentData?.extraData ?? {
            id: 1,
            slideNotes: [],
          }),
        },
        document: action.payload,
      }
    },
    setSlideNote: (state, action: PayloadAction<IDeckV3SlideNote>) => {
      const slideNote = state.documentData?.extraData.slideNotes.find(
        (item) => item.internalId === action.payload.internalId,
      )
      if (slideNote) {
        slideNote.note = action.payload.note
      } else {
        state.documentData?.extraData.slideNotes.push(action.payload)
      }
    },
    setSaveNow: (state, action: PayloadAction<boolean>) => {
      state.saveNow = action.payload
    },

    setDeletedSlides: (state, action: PayloadAction<string[]>) => {
      state.deletedSlides = action.payload
    },
    setV3SaveState: (state, action: PayloadAction<SAVE_STATE>) => {
      state.saveState = action.payload
    },
    setDeckV3Styles: (state, action: PayloadAction<GetDeckV3Styles>) => {
      state.styles = action.payload
    },
    setDocumentExtraData: (
      state,
      action: PayloadAction<
        Omit<GetDeckV3Document['extraData'], 'id' | 'slideNotes'>
      >,
    ) => {
      if (!state.documentData) {
        return
      }
      const { styleId, styleVersion, themeId } = action.payload
      state.documentData = {
        ...state.documentData,
        extraData: {
          ...state.documentData.extraData,
          ...(styleId && { styleId }),
          ...(styleVersion && { styleVersion }),
          ...(themeId && { themeId }),
        },
      }
    },
    setStoreLoading: (state, action: PayloadAction<boolean>) => {
      state.storeLoading = action.payload
    },
    setMediaUploading: (state, action: PayloadAction<boolean>) => {
      state.mediaUploading = action.payload
    },
  },
})

export const {
  setDocument,
  setSaveNow,
  setDeletedSlides,
  setV3SaveState,
  setDocumentData,
  setSlideNote,
  setDeckV3Styles,
  setDocumentExtraData,
  setStoreLoading,
  setMediaUploading,
} = deckV3Slice.actions

export default deckV3Slice.reducer
