export const planFeatures = {
  // 'plan_features.lite.monthly.title': 'Crédits AI 150 / mois',
  // 'plan_features.lite.monthly.1': 'Export PDF / PPT',
  // 'plan_features.lite.monthly.2': 'Lien de partage',
  // 'plan_features.lite.monthly.3': 'Diapositives et notes du présentateur',
  // 'plan_features.lite.monthly.4':
  //   "Génération de conseils et d'assurance qualité",
  // 'plan_features.lite.annual.title': 'Crédits AI 150 / mois',
  // 'plan_features.lite.annual.1': 'Export PDF / PPT',
  // 'plan_features.lite.annual.2': 'Lien de partage',
  // 'plan_features.lite.annual.3':
  //   'Notes sur les diapositives et les intervenants',
  // 'plan_features.lite.annual.4': "Conseils et génération d'assurance qualité",
  'plan_features.pro.monthly.title': 'Crédits AI 750 / mois',
  // 'plan_features.pro.monthly.1': 'Toutes les fonctionnalités AI LITE +',
  'plan_features.pro.monthly.1': 'Export PDF / PPT',
  'plan_features.pro.monthly.2': 'Lien de partage',
  'plan_features.pro.monthly.3': 'Diapositives et notes du présentateur',
  'plan_features.pro.monthly.4':
    "Génération de conseils et d'assurance qualité",
  'plan_features.pro.monthly.5': 'Analyse des présentations',
  'plan_features.pro.monthly.6':
    'Notifications par e-mail des réponses aux formulaires',
  'plan_features.pro.annual.title': 'Crédits AI 750 / mois',
  // 'plan_features.pro.annual.1': 'Toutes les fonctionnalités AI LITE +',
  'plan_features.pro.annual.1': 'Export PDF / PPT',
  'plan_features.pro.annual.2': 'Lien de partage',
  'plan_features.pro.annual.3':
    'Notes sur les diapositives et les intervenants',
  'plan_features.pro.annual.4': "Conseils et génération d'assurance qualité",
  'plan_features.pro.annual.5': 'Analyse des présentations',
  'plan_features.pro.annual.6':
    'Notifications par e-mail de réponse au formulaire',
  'plan_features.business.monthly.title': 'Crédits AI 1000 / mois',
  'plan_features.business.monthly.1': 'Toutes les fonctionnalités AI PRO +',
  'plan_features.business.monthly.2': 'Fonctionnalités de la marque',
  'plan_features.business.monthly.3': 'Réponse avancée au formulaire',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Connexion de domaine personnalisée',
  'plan_features.business.monthly.6': 'Équipe et organisation',
  'plan_features.business.annual.title': 'Crédits AI 1000 / mois',
  'plan_features.business.annual.1': 'Toutes les fonctionnalités AI Pro +',
  'plan_features.business.annual.2': 'Caractéristiques de la marque',
  'plan_features.business.annual.3': 'Réponse avancée au formulaire',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Connexion à un domaine personnalisé',
  'plan_features.business.annual.6': 'Équipes et organisation',
}
