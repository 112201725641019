import { ErrorDefinitions } from '@/types/api/ErrorDefinitions'

type IErrorDefinitions = {
  [key in ErrorDefinitions]?: string
}

export const errors: IErrorDefinitions = {
  [ErrorDefinitions.VALIDATION_EMAIL_MALFORMED]:
    'El correo electrónico no es válido',
  [ErrorDefinitions.VALIDATION_PASSWORD_LENGTH]:
    'La contraseña debe tener al menos 10 caracteres',
  [ErrorDefinitions.AICREDITREASONDEFAULTVALUE_NOT_FOUND]:
    'No se encontró el valor predeterminado de la razón del crédito de IA',
  [ErrorDefinitions.API_ERROR]: 'Error de API',
  [ErrorDefinitions.DATE_ERROR]: 'Formato de fecha inválido',
  [ErrorDefinitions.INCORRECT_PROVIDER]:
    'Por favor, use el inicio de sesión de Google para esta cuenta',
  [ErrorDefinitions.KEY_NOT_FOUND]: 'Clave no encontrada',
  [ErrorDefinitions.NO_ORG_ACCESS]: 'Sin acceso a la organización',
  [ErrorDefinitions.NO_TEAM_ACCESS]: 'Sin acceso al equipo',
  [ErrorDefinitions.PLAN_NOT_FOUND]: 'Plan no encontrado',
  [ErrorDefinitions.ACTIVE_PAYMENT_IN_PROGRESS]: 'Pago activo en progreso',
  [ErrorDefinitions.PLAN_IS_NOT_AVAILABLE]: 'El plan no está disponible',
  [ErrorDefinitions.PLAN_PRODUCT_NOT_FOUND]: 'Producto del plan no encontrado',
  [ErrorDefinitions.PRICE_NOT_FOUND]: 'Precio no encontrado',
  [ErrorDefinitions.USER_NOT_FOUND]: 'Usuario no encontrado',
  [ErrorDefinitions.USER_PLAN_NOT_FOUND]: 'Plan de usuario no encontrado',
  [ErrorDefinitions.USER_EXIST]: 'La dirección de correo electrónico ya existe',
  [ErrorDefinitions.WRONG_PASSWORD]: 'Contraseña incorrecta',
  [ErrorDefinitions.USER_NOT_VERIFIED]: 'Usuario no verificado',
  [ErrorDefinitions.REFERRED_USER_EXIST]: 'El usuario referido ya existe',
  [ErrorDefinitions.CAN_NOT_PURCHASE]: 'No se puede comprar',
  [ErrorDefinitions.CAN_NOT_UPGRADE]: 'No se puede actualizar',
  [ErrorDefinitions.SUBSCRIPTION_NOT_FOUND]: 'Suscripción no encontrada',
  [ErrorDefinitions.SUBSCRIPTION_ITEM_NOT_FOUND]:
    'Elemento de suscripción no encontrado',
  [ErrorDefinitions.SUBSCRIPTION_CHANGE_NOT_FOUND]:
    'Cambio de suscripción no encontrado',
  [ErrorDefinitions.SUBSCRIPTION_IS_NOT_ACTIVE]:
    'La suscripción no está activa',
  [ErrorDefinitions.UNKNOWN_EVENT_TYPE]: 'Tipo de evento desconocido',
  [ErrorDefinitions.TEAM_NOT_FOUND]: 'Equipo no encontrado',
  [ErrorDefinitions.ORG_NOT_FOUND]: 'Organización no encontrada',
  [ErrorDefinitions.ACCESS_NOT_FOUND]: 'Acceso no encontrado',
  [ErrorDefinitions.USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION]:
    'El usuario ya existe en esta organización',
  [ErrorDefinitions.USERS_ALREADY_EXISTS_IN_THIS_TEAM]:
    'Los usuarios ya existen en este equipo',
  [ErrorDefinitions.INVITATION_ALREADY_EXISTS]: 'La invitación ya existe',
  [ErrorDefinitions.INVITATION_NOT_FOUND]: 'Invitación no encontrada',
  [ErrorDefinitions.ORGANIZATION_NOT_FOUND]: 'Organización no encontrada',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_DOWNGRADE]:
    'Reducción del tamaño de asientos de la organización',
  [ErrorDefinitions.AI_CREDIT_REASON_NOT_DEFINED]:
    'Razón de crédito de IA no definida',
  [ErrorDefinitions.INSUFFICIENT_AI_CREDIT]: 'Crédito de IA insuficiente',
  [ErrorDefinitions.WEBHOOK_EXISTS]: 'El webhook ya existe',
  [ErrorDefinitions.WEBHOOK_NOT_EXISTS]: 'El webhook no existe',
  [ErrorDefinitions.WEBHOOK_NO_CHANGE]: 'Sin cambios en el webhook',
  [ErrorDefinitions.PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND]:
    'Solicitud de restablecimiento de contraseña expirada o no encontrada',
  [ErrorDefinitions.TEAM_IS_NOT_IN_THIS_ORGANIZATION]:
    'El equipo no está en esta organización',
  [ErrorDefinitions.USERS_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Usuarios no encontrados en esta organización',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_TEAM]:
    'Usuario no encontrado en este equipo',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Usuario no encontrado en esta organización',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_USER]:
    'No se puede actualizar el propio usuario',
  [ErrorDefinitions.MISSING_QUESTION_TYPES]: 'Faltan tipos de preguntas',
  [ErrorDefinitions.USER_ALREADY_CLASSIFIED]: 'Usuario ya clasificado',
  [ErrorDefinitions.WRONG_VERIFICATION_CODE]:
    'El código ingresado es incorrecto. Por favor, revise su correo electrónico.',
  [ErrorDefinitions.USER_ALREADY_VERIFIED]: 'Usuario ya verificado',
  [ErrorDefinitions.TARGET_USER_IS_OWNER]:
    'El usuario objetivo es el propietario',
  [ErrorDefinitions.CANNOT_REMOVE_SELF_USER]:
    'No se puede eliminar el propio usuario',
  [ErrorDefinitions.CANNOT_REMOVE_ADMIN_USER]:
    'No se puede eliminar el usuario administrador',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_REACHED]:
    'Se alcanzó el límite de asientos de la organización',
  [ErrorDefinitions.THEME_NOT_FOUND]: 'Tema no encontrado',
  [ErrorDefinitions.SLIDELAYOUT_EXIST]: 'El diseño de diapositiva ya existe',
  [ErrorDefinitions.SLIDELAYOUT_NOT_FOUND]:
    'Diseño de diapositiva no encontrado',
  [ErrorDefinitions.THEMECOLOR_NOT_FOUND]: 'Color del tema no encontrado',
  [ErrorDefinitions.THEMEFONT_NOT_FOUND]: 'Fuente del tema no encontrada',
  [ErrorDefinitions.COMPONENT_NOT_FOUND]: 'Componente no encontrado',
  [ErrorDefinitions.STRIPE_CUSTOMER_NOT_FOUND]:
    'Cliente de Stripe no encontrado',
  [ErrorDefinitions.ASSET_NOT_FOUND]: 'Activo no encontrado',
  [ErrorDefinitions.FOLDER_NOT_FOUND]: 'Carpeta no encontrada',
  [ErrorDefinitions.DECK_NOT_FOUND]: 'Presentación no encontrada',
  [ErrorDefinitions.DECK_ALREADY_SHARED]: 'La presentación ya está compartida',
  [ErrorDefinitions.DECKSHARE_NOT_FOUND]:
    'Compartir presentación no encontrado',
  [ErrorDefinitions.CUSTOMDOMAIN_NOT_FOUND]:
    'Dominio personalizado no encontrado',
  [ErrorDefinitions.SHORTCODE_ALREADY_EXISTS]: 'El código corto ya existe',
  [ErrorDefinitions.CUSTOMDOMAIN_ALREADY_EXISTS]:
    'El dominio personalizado ya existe',
  [ErrorDefinitions.CUSTOMDOMAINSLUG_ALREADY_EXISTS]:
    'El slug del dominio personalizado ya existe',
  [ErrorDefinitions.SLIDEDATA_NOT_FOUND]: 'Datos de diapositiva no encontrados',
  [ErrorDefinitions.DECKDATA_NOT_FOUND]: 'Datos de presentación no encontrados',
  [ErrorDefinitions.OPENAI_MAXRETRY_REACHED]:
    'Se alcanzó el límite máximo de reintentos de OpenAI',
  [ErrorDefinitions.THEMECATEGORY_NOT_FOUND]: 'Categoría de tema no encontrada',
  [ErrorDefinitions.MINROLE_ERROR]: 'Error de autorización de rol mínimo',
  [ErrorDefinitions.SLIDE_NOT_FOUND]: 'Diapositiva no encontrada',
  [ErrorDefinitions.INVALID_OFFSET]: 'Desplazamiento inválido',
  [ErrorDefinitions.FORM_QUESTION_NOT_FOUND]:
    'Pregunta de formulario no encontrada',
  [ErrorDefinitions.UNKNOWN_FORMQUESTION_TYPE]:
    'Tipo de pregunta de formulario desconocido',
  [ErrorDefinitions.MODERATION_ERROR]: 'Error de moderación',
  [ErrorDefinitions.DECKPROMPT_NOT_FOUND]:
    'Indicación de presentación no encontrada',
  [ErrorDefinitions.DECKSTATE_IS_NOT_FINAL]:
    'El estado de la presentación no es final',
  [ErrorDefinitions.THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY]:
    'El color del tema y la fuente del tema deben estar en la misma categoría',
  [ErrorDefinitions.CONTENT_TOO_LONG]: 'El contenido es demasiado largo',
  [ErrorDefinitions.DECK_EXPORT_IN_PROGRESS]:
    'Exportación de presentación en progreso',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_PERMISSION]:
    'No se pueden actualizar los propios permisos',
  [ErrorDefinitions.CANNOT_UPDATE_OWNERS_PERMISSION]:
    'No se pueden actualizar los permisos de los propietarios de la organización',
  [ErrorDefinitions.USER_DECK_NOT_FOUND]:
    'Presentación de usuario no encontrada',
  [ErrorDefinitions.PASSWORD_RESET_REQUIRED]:
    'Se requiere restablecer la contraseña, por favor cambie su contraseña',
  [ErrorDefinitions.ALREADY_REFERRED]: 'Este usuario ya ha sido referido',
  [ErrorDefinitions.CAPTCHA_NOT_CORRECT]: 'No se pudo validar el Captcha',
  [ErrorDefinitions.CANCEL_PROMO_ALREADY_USED]:
    'La promoción ya ha sido utilizada',
  [ErrorDefinitions.SUBSCRIPTION_ID_NOT_FOUND]:
    'ID de suscripción no encontrado',
  [ErrorDefinitions.MFA_INVALID_TOKEN]:
    'El token no es válido o ha caducado. Inténtalo de nuevo.',
}
