import { IApiEnums } from '@/i18n/types'
import {
  AICreditSpendReasonNames,
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
  UserLanguages,
} from '@/types/api/enums'

export const apiEnums: IApiEnums = {
  [`enum.planNames.${PlanNames.LITE}`]: 'lite',
  [`enum.planNames.${PlanNames.PRO}`]: 'pro',
  [`enum.planNames.${PlanNames.BUSINESS}`]: 'business',
  [`enum.planNames.${PlanNames.ENTERPRISE}`]: 'enterprise',
  [`enum.planPeriods.${PlanPeriods.MONTHLY}`]: 'monthly',
  [`enum.planPeriods.${PlanPeriods.ANNUAL}`]: 'annual',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_DECK}`]:
    'Create AI Deck',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_PDF_DECK}`]:
    'Create PDF Deck',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_ICON}`]:
    'Icon suggestion',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_IMAGE}`]:
    'Image suggestion',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_LIST_ITEM}`]:
    'List item suggestion',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_TIPS}`]:
    'Create tips',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_QUESTIONS_ANSWERS}`]:
    'Generate Q&A',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SINGLE_SLIDE_NOTES}`]:
    'Create single slide notes',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_MULTIPLE_SLIDE_NOTES}`]:
    'Create multiple slide notes',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.AI_TEXT_EDIT}`]:
    'AI Text Edit',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.TRANSLATE_DECK}`]:
    'Translate Deck',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SLIDE}`]:
    'Generate slide',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED}`]:
    'Payment Action Required',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_PENDING}`]:
    'Payment Pending',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_FAILED}`]:
    'Canceled',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_SUCCESS}`]:
    'Active',
  [`enum.language.${UserLanguages.EN_US}`]: 'English',
  [`enum.language.${UserLanguages.TR_TR}`]: 'Turkish',
}
