import { valueOf } from '@/types'

export enum COLOR {
  primary_110 = '#3F4ECC',
  primary_100 = '#4f61ff',
  primary_90 = '#6171ff',
  primary_80 = '#7281ff', // cornflower blue
  primary_70 = '#8390ff', // malibu
  primary_60 = '#95a0ff', // melrose
  primary_50 = '#a7b0ff', // melrose
  primary_40 = '#bac0ff', // melrose
  primary_30 = '#cad0ff', // periwinkle
  primary_20 = '#dcdfff', // fog
  primary_10 = '#eeefff', // titan white
  secondary_100 = '#23307e', // bay of mary
  secondary_90 = '#3a458b', // chambray
  secondary_80 = '#4f5998', // kashmir blue
  secondary_70 = '#656ea5', // waikawa gray
  secondary_60 = '#7b83b2', // wild blue yonker
  secondary_50 = '#9298bf', // blue bell
  secondary_40 = '#a7accb', // logan
  secondary_30 = '#bdc1d8', // blue haze
  secondary_20 = '#d3d6e5', // mischka
  secondary_10 = '#e9eaf3', // whisper
  purple_100 = '#b157fe', // heliotrope
  purple_90 = '#b968fe', // heliotrope
  purple_80 = '#c17afe', // heliotrope
  purple_70 = '#c889fe', // heliotrope
  purple_60 = '#d09afe', // mauve
  purple_50 = '#d8abff', // mauve
  purple_40 = '#dfbdff', // mauve
  purple_30 = '#e8cdff', // fog
  purple_20 = '#efddff', // blue chalk
  purple_10 = '#f7efff', // magnolia
  pink_100 = '#f450f9', // heliotrope
  pink_90 = '#f562fa', // heliotrope
  pink_80 = '#f673fb', // heliotrope
  pink_70 = '#f784fb', // heliotrope
  pink_60 = '#f896fb', // mauve
  pink_50 = '#faa7fc', // mauve
  pink_40 = '#fbb9fd', // mauve
  pink_30 = '#fdcbfd', // pink lace
  pink_20 = '#fddcfe', // white pointer
  pink_10 = '#feedfe', // remy
  red_100 = '#ff4444', // coral red
  red_90 = '#FF5757',
  red_20 = '#FFDADA',
  green_100 = '#7AD468',
  dark_100 = '#272734', // charade
  dark_90 = '#3d3d48', // tuna
  dark_80 = '#52525d', // scarpa flow
  dark_70 = '#686871', // mid gray
  dark_60 = '#7d7d84', // jumbo
  dark_50 = '#93939b', // manatee
  dark_40 = '#a9a9ae', // spun pearl
  dark_30 = '#bebec2', // french gray
  dark_20 = '#d4d4d6', // iron
  dark_10 = '#e9e9eb', // athens gray
  dark2_100 = '#37384a', // tuna
  dark2_90 = '#4b4c5c', // trout
  dark2_80 = '#5f606e', // mid gray
  dark2_70 = '#737480', // storm gray
  dark2_60 = '#878892', // waterloo
  dark2_50 = '#9b9ca3', // manatee
  dark2_40 = '#afafb8', // spun pearl
  dark2_30 = '#c3c3c9', // french gray
  dark2_20 = '#d7d7db', // mischka
  dark2_10 = '#ebebed', // athens gray
  black_100 = '#000000', // black
  black_90 = '#1a1a1a', // cod gray
  black_80 = '#333333', // mine shaft
  black_70 = '#4c4c4c', // tundora
  black_60 = '#666666', // dove gray
  black_50 = '#808080', // gray
  black_40 = '#999999', // dusty gray
  black_30 = '#b3b3b3', // nobel
  black_20 = '#cccccc', // silver
  black_10 = '#e5e5e5', // mercury
  white_100 = '#ffffff',
  yellow_100 = '#FFD700',

  background_100 = '#EFF2F8',
  background_90 = '#EDEFFF',
  background_80 = '#F8F9FC',
  textColor_100 = '#242424',
  textColor_2 = '#9AA0AB',

  overlay_card = '#363C5C',
  overlay_card_2 = '#171B31',

  overlay_modal = '#212121',

  avatar_color_2 = '#D84747',
  avatar_color_3 = '#CE00C6',
  avatar_color_4 = '#46A044',
  avatar_color_5 = '#717171',
  avatar_color_6 = '#273E78',
  avatar_color_7 = '#FF9900',

  outline_100 = '#E5E5E8',
  outline_90 = '#BDBFC5',

  data_1 = '#68B7DD',
  data_2 = '#6694DD',
  data_3 = '#6770DC',
  data_4 = '#8067DC',
  data_5 = '#A467DD',
  data_6 = '#C867DC',
  data_7 = '#DC67CE',
  data_8 = '#DC67AB',
  data_9 = '#DC6788',
  data_10 = '#DB6A67',
  data_11 = '#DC8C67',
  data_12 = '#DCAF66',
  data_13 = '#DDD366',
  data_14 = '#C2DD67',
  data_15 = '#9FDD67',
  data_16 = '#7DDC67',
  data_17 = '#67DD75',
  data_18 = '#67DD98',
  data_19 = '#67DDBB',
  data_20 = '#66DBDD',

  transparent_1 = 'rgba(33, 33, 33, 0.80)',
}

export type ColorSet<T> = valueOf<T, COLOR>
