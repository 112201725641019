import { IApiEnums } from '@/i18n/types'
import {
  AICreditSpendReasonNames,
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
  UserLanguages,
} from '@/types/api/enums'

export const apiEnums: IApiEnums = {
  [`enum.planNames.${PlanNames.LITE}`]: 'lite',
  [`enum.planNames.${PlanNames.PRO}`]: 'pro',
  [`enum.planNames.${PlanNames.BUSINESS}`]: 'business',
  [`enum.planNames.${PlanNames.ENTERPRISE}`]: 'enterprise',
  [`enum.planPeriods.${PlanPeriods.MONTHLY}`]: 'aylık',
  [`enum.planPeriods.${PlanPeriods.ANNUAL}`]: 'yıllık',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_DECK}`]:
    'AI Deck oluşturma',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_PDF_DECK}`]:
    'PDF Deck oluşturma',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_ICON}`]:
    'İkon önerisi',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_IMAGE}`]:
    'Görsel önerisi',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_LIST_ITEM}`]:
    'Liste önerisi',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_TIPS}`]:
    'İpucu önerisi',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_QUESTIONS_ANSWERS}`]:
    'Soru&Cevap oluşturma',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SINGLE_SLIDE_NOTES}`]:
    'Tek slide notu oluşturma',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_MULTIPLE_SLIDE_NOTES}`]:
    'Çoklu slide notu oluşturma',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.AI_TEXT_EDIT}`]:
    'AI Metin Düzenleme',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.TRANSLATE_DECK}`]:
    'Deck çevirisi',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SLIDE}`]:
    'Slayt oluştur',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED}`]:
    'Ödeme Aksiyon Gerekli',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_PENDING}`]:
    'Ödeme Bekliyor',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_FAILED}`]:
    'Iptal Edildi',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_SUCCESS}`]:
    'Aktif',
  [`enum.language.${UserLanguages.EN_US}`]: 'İngilizce',
  [`enum.language.${UserLanguages.TR_TR}`]: 'Türkçe',
}
