import { useEffect } from 'react'
import { APP_CONFIG } from '@/config'
import Gleap from 'gleap'
import { useLocation } from 'react-router'

export const useInitGleap = () => {
  const location = useLocation()

  useEffect(() => {
    const isDisabled = APP_CONFIG.chatWidget.disabled.some((route) =>
      location.pathname.includes(route),
    )

    Gleap.showFeedbackButton(!isDisabled)
  }, [location.pathname])

  useEffect(() => {
    Gleap.initialize(import.meta.env.VITE_GLEAP_API_KEY || '')
  }, [])
}
