import { COMPONENT_ALIGN } from '../types'
import { APP_CONFIG } from '@/config'
import { ComponentSchema } from '@/types/api/responseObjects'

export const alignComponents = (
  components: {
    index: number
    component: ComponentSchema
  }[],
  align?: COMPONENT_ALIGN,
) => {
  if (!align) {
    return components
  }

  const fullWidth = APP_CONFIG.canvasMasterWidth
  const fullHeight = APP_CONFIG.canvasMasterWidth / APP_CONFIG.canvasRatio
  const toCanvas = components.length <= 1
  const pivotComponent = {
    x: components[0].component.positions.x || 0,
    y: components[0].component.positions.y || 0,
    width: components[0].component.positions.width || 0,
    height: components[0].component.positions.height || 0,
    scale: components[0].component.positions.scale,
    zIndex: components[0].component.positions.scale,
  }
  const leftMostX = components.reduce(
    (a, c) => {
      return Math.min(c.component.positions.x, a)
    },
    toCanvas ? 0 : pivotComponent.x,
  )
  const verticalCenter = toCanvas
    ? fullWidth / 2
    : pivotComponent.x + pivotComponent.width / 2
  const rightMostX = components.reduce(
    (a, c) => {
      return Math.max(
        c.component.positions.x + (c.component.positions.width || 0),
        a,
      )
    },
    toCanvas ? fullWidth : pivotComponent.x,
  )
  const topMostY = components.reduce(
    (a, c) => {
      return Math.min(c.component.positions.y, a)
    },
    toCanvas ? 0 : pivotComponent.y,
  )
  const horizontalCenter = toCanvas
    ? fullHeight / 2
    : pivotComponent.y + pivotComponent.height / 2
  const bottomMostY = components.reduce(
    (a, c) => {
      return Math.max(
        c.component.positions.y + (c.component.positions.height || 0),
        a,
      )
    },
    toCanvas ? fullHeight : pivotComponent.y,
  )
  switch (align) {
    case COMPONENT_ALIGN.LEFT:
      return components.map(({ index, component }) => ({
        index,
        component: {
          ...component,
          positions: {
            ...component.positions,
            x: leftMostX,
          },
        },
      }))
    case COMPONENT_ALIGN.VERTICAL:
      return components.map(({ index, component }) => ({
        index,
        component: {
          ...component,
          positions: {
            ...component.positions,
            x: verticalCenter - (component.positions.width || 0) / 2,
          },
        },
      }))
    case COMPONENT_ALIGN.RIGHT:
      return components.map(({ index, component }) => ({
        index,
        component: {
          ...component,
          positions: {
            ...component.positions,
            x: rightMostX - (component.positions.width || 0),
          },
        },
      }))
    case COMPONENT_ALIGN.TOP:
      return components.map(({ index, component }) => ({
        index,
        component: {
          ...component,
          positions: {
            ...component.positions,
            y: topMostY,
          },
        },
      }))
    case COMPONENT_ALIGN.HORIZONTAL:
      return components.map(({ index, component }) => ({
        index,
        component: {
          ...component,
          positions: {
            ...component.positions,
            y: horizontalCenter - (component.positions.height || 0) / 2,
          },
        },
      }))
    case COMPONENT_ALIGN.BOTTOM:
      return components.map(({ index, component }) => ({
        index,
        component: {
          ...component,
          positions: {
            ...component.positions,
            y: bottomMostY - (component.positions.height || 0),
          },
        },
      }))
    default:
      return components
  }
}
