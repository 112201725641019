import React, { PropsWithChildren } from 'react'
import { PostHogProvider } from 'posthog-js/react'

export const PostHogProviderWrapper = ({ children }: PropsWithChildren) => {
  if (window.location.href.includes('/export')) {
    return children
  }

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
      options={{
        api_host: 'https://analytics.decktopus.com',
        ui_host: 'https://us.posthog.com',
      }}
    >
      {children}
    </PostHogProvider>
  )
}
