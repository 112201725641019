export const planFeatures = {
  // 'plan_features.lite.monthly.title': 'Créditos de IA 150/mês',
  // 'plan_features.lite.monthly.1': 'Exportação PDF/PPT',
  // 'plan_features.lite.monthly.2': 'Partilhando Link',
  // 'plan_features.lite.monthly.3': 'Diapositivos e notas do orador',
  // 'plan_features.lite.monthly.4': 'Dicas e geração de controlo de qualidade',
  // 'plan_features.lite.annual.title': 'Créditos de IA 150/mês',
  // 'plan_features.lite.annual.1': 'Exportação PDF/PPT',
  // 'plan_features.lite.annual.2': 'Link de partilha',
  // 'plan_features.lite.annual.3': 'Diapositivos e notas do orador',
  // 'plan_features.lite.annual.4': 'Dicas e geração de QA',
  'plan_features.pro.monthly.title': 'Créditos de IA 750/mês',
  // 'plan_features.pro.monthly.1': 'Todas as características do LITE AI +',
  'plan_features.pro.monthly.1': 'Exportação PDF/PPT',
  'plan_features.pro.monthly.2': 'Partilhando Link',
  'plan_features.pro.monthly.3': 'Diapositivos e notas do orador',
  'plan_features.pro.monthly.4': 'Dicas e geração de controlo de qualidade',
  'plan_features.pro.monthly.5': 'Análise de apresentação',
  'plan_features.pro.monthly.6':
    'Notificações por e-mail de resposta de formulário',
  'plan_features.pro.annual.title': 'Créditos de IA 750/mês',
  // 'plan_features.pro.annual.1': 'Todas as características do LITE AI +',
  'plan_features.pro.annual.1': 'Exportação PDF/PPT',
  'plan_features.pro.annual.2': 'Link de partilha',
  'plan_features.pro.annual.3': 'Diapositivos e notas do orador',
  'plan_features.pro.annual.4': 'Dicas e geração de QA',
  'plan_features.pro.annual.5': 'Análise de apresentação',
  'plan_features.pro.annual.6':
    'Notificações por e-mail de resposta de formulário',
  'plan_features.business.monthly.title': 'Créditos de IA 1000/mês',
  'plan_features.business.monthly.1': 'Todas as funcionalidades do PRO AI+',
  'plan_features.business.monthly.2': 'Características da marca',
  'plan_features.business.monthly.3': 'Advanced form response',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Ligação de domínio personalizada',
  'plan_features.business.monthly.6': 'Equipa e organização',
  'plan_features.business.annual.title': 'Créditos de IA 1000/mês',
  'plan_features.business.annual.1': 'Todas as características do Pro AI+',
  'plan_features.business.annual.2': 'Características da marca',
  'plan_features.business.annual.3': 'Advanced form response',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Ligação de domínio personalizada',
  'plan_features.business.annual.6': 'Equipas e organização',
}
