import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFoldersResponse } from '@/types/api/responseObjects'

const initialState: {
  list: getFoldersResponse['data']['folders']
  activeFolderId: number
  deckCountInActiveFolder: number
} = {
  list: [],
  activeFolderId: -1,
  deckCountInActiveFolder: 0,
}

export const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setFolders: (
      state,
      action: PayloadAction<getFoldersResponse['data']['folders']>,
    ) => {
      state.list = action.payload
    },
    setActiveFolderId: (state, action: PayloadAction<number>) => {
      state.activeFolderId = action.payload
    },
    setDeckCountInFolder: (state, action: PayloadAction<number>) => {
      state.deckCountInActiveFolder = action.payload
    },
  },
})

export const { setFolders, setActiveFolderId, setDeckCountInFolder } =
  foldersSlice.actions

export default foldersSlice.reducer
