import { v4 as uuidv4 } from 'uuid'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeckV3Document,
  GetDeckV3Styles,
  GetDeckV3ThemeDetails,
  GetDeckV3ThemeDocument,
  GetDeckV3Themes,
} from '@/types/api/responseObjects'
import { SAVE_STATE } from '../types'

export interface IDeckV3ThemeSlice {
  baseTheme?: GetDeckV3ThemeDetails
  allThemeDetails?: GetDeckV3ThemeDetails[]
  themeDocumentData?: GetDeckV3ThemeDocument
  saveNow: boolean
  deletedLayouts: string[]
  saveState: SAVE_STATE
  themes: GetDeckV3Themes
  styles: GetDeckV3Styles
}

const initialState: IDeckV3ThemeSlice = {
  baseTheme: undefined,
  themeDocumentData: undefined,
  saveNow: false,
  deletedLayouts: [],
  saveState: SAVE_STATE.SAVED,
  themes: [],
  styles: [],
}

export const deckV3ThemeSlice = createSlice({
  name: 'deckV3Theme',
  initialState,
  reducers: {
    setBaseTheme: (state, action: PayloadAction<GetDeckV3ThemeDetails>) => {
      state.baseTheme = action.payload
    },
    setThemeDocumentData: (
      state,
      action: PayloadAction<GetDeckV3ThemeDocument>,
    ) => {
      state.themeDocumentData = action.payload
    },
    setThemeDocument: (state, action: PayloadAction<DeckV3Document>) => {
      state.themeDocumentData = {
        extraData: {
          ...(state.themeDocumentData?.extraData ?? {
            id: uuidv4(),
            name: 'New Layout',
          }),
        },
        document: action.payload,
      }
    },
    setThemeSaveNow: (state, action: PayloadAction<boolean>) => {
      state.saveNow = action.payload
    },
    setDeletedLayouts: (state, action: PayloadAction<string[]>) => {
      state.deletedLayouts = action.payload
    },
    setThemeV3SaveState: (state, action: PayloadAction<SAVE_STATE>) => {
      state.saveState = action.payload
    },
    setThemes: (state, action: PayloadAction<GetDeckV3Themes>) => {
      state.themes = action.payload
    },
    setThemeStyles: (state, action: PayloadAction<GetDeckV3Styles>) => {
      state.styles = action.payload
    },
    setAllThemeDetails: (
      state,
      action: PayloadAction<GetDeckV3ThemeDetails[]>,
    ) => {
      state.allThemeDetails = action?.payload
    },
  },
})

export const {
  setBaseTheme,
  setThemeDocumentData,
  setThemeDocument,
  setThemeSaveNow,
  setDeletedLayouts,
  setThemeV3SaveState,
  setThemes,
  setThemeStyles,
  setAllThemeDetails,
} = deckV3ThemeSlice.actions

export default deckV3ThemeSlice.reducer
