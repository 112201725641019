import { IEditInitialState } from '../types'
import { ComponentSchema } from '@/types/api/responseObjects'
import { getSlideDataIndex } from './getSlideDataIndex'
import { getComponentIndex } from './getComponentIndex'

export const getComponent = (
  state: IEditInitialState,
  id?: number,
  tempId?: string | null,
): ComponentSchema | undefined => {
  const slideIndex = getSlideDataIndex(state)
  const componentIndex = getComponentIndex(state, id, tempId)

  if (!state.activeSlideData?.data?.[slideIndex] || componentIndex === -1) {
    return undefined
  }

  return state.activeSlideData.data[slideIndex].slideDataComponents[
    componentIndex
  ].component
}
